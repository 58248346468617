import { Banner, BannerSize } from "@q4/nimbus-ui";
import React from "react";
import { DTCCBannerClassName, DTCCBannerProps } from "./DTCCBanner.definition";
import "./DTCCBanner.scss";

const DTCCBanner = (props: DTCCBannerProps): JSX.Element => {
  const { title = "DTCC", details = "" } = props;
  return (
    <Banner
      className={DTCCBannerClassName.Base}
      badgeIcon=""
      title={title}
      size={BannerSize.Thin}
      details={<div>{details}</div>}
    />
  );
};

export default DTCCBanner;
