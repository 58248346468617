import type { BannerSize, BaseComponentProps } from "@q4/nimbus-ui";
import { EntityMetadata } from "q4-platform-common/src/models/entity/entity";

export interface EntityBannerProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  loading: boolean;
  rows?: number;
  entityMetadata: EntityMetadata;
  custodians?: Array<EntityMetadata>;
  size?: BannerSize;
  onEditEntity?: () => void;
  setShowSectorModal?: (show: boolean) => void;
}

export enum EntityBannerClassName {
  Base = "entity-banner",
  ManagerInfo = "entity-banner__manager-info",
}
