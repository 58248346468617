import type { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import { Integer } from "aws-sdk/clients/dynamodb";

export enum DevLoaderClassName {
  Base = "dev-loader",
  Visible = "visible",
  Invisible = "invisible",
}

export interface DevLoaderProps extends BaseComponentWithChildrenProps {
  interval?: Integer;
  disableText?: boolean;
  noMargin?: boolean;
}
