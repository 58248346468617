import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface DeleteCustomTransactionsModalProps extends BaseComponentProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

export enum DeleteCustomTransactionsModalClassName {
  Base = "delete-custom-transactions-modal",
}
