import { CellClassParams } from "ag-grid-community";

// Private - formatter methods
const bold13F = (params: CellClassParams, field: string): Record<string, unknown> => ({
  ...(params.data[field] === "13f" ? { "font-weight": "bold" } : {}),
});

const assignColorToChangeNumber = (params: CellClassParams, field: string): Record<string, unknown> => {
  const colors = { black: "#000000", green: "#0DA084", red: "#EC6A4C" };
  const { data } = params;
  const style = {
    color: colors.black,
  };
  if (data[field] > 0) {
    style.color = colors.green;
  } else if (data[field] < 0) {
    style.color = colors.red;
  }
  return style;
};

// Public - formatter methods
export const boldCellStyleFormatter = (params: CellClassParams): Record<string, unknown> => ({
  ...bold13F(params, "SOURCE"),
});

export const colorToChangeCellStyleFormatter = (params: CellClassParams, field: string): Record<string, unknown> => ({
  ...assignColorToChangeNumber(params, field),
});

export const colorAndBoldCellStyleFormatter = (params: CellClassParams, field: string): Record<string, unknown> => ({
  ...assignColorToChangeNumber(params, field),
  ...bold13F(params, "SOURCE"),
});
