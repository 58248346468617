import React from "react";
import "./ProgressBar.component.scss";
import { ProgressBarClassNames, ProgressBarProps } from "./ProgressBar.definition";

const ProgressBar = (props: ProgressBarProps): JSX.Element => {
  const { percentComplete, theme = "rain" } = props;
  const clamp = (number: number, min: number, max: number) => Math.max(min, Math.min(number, max));
  const widthStyle = { width: `${clamp(percentComplete, 0, 100)}%` };
  return (
    <div className={`${ProgressBarClassNames.Base} ${ProgressBarClassNames.Base}--${theme}`}>
      <div className={`${ProgressBarClassNames.Progress} ${ProgressBarClassNames.Loading}`} style={widthStyle}></div>
    </div>
  );
};

export default ProgressBar;
