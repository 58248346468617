import type { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import React from "react";

export type NavigationProviderProps = BaseComponentWithChildrenProps;

export interface SearchRefHooks {
  [key: string]: (searchRef: React.MutableRefObject<URLSearchParams>) => void;
}

export interface NavigationContextState {
  searchRef?: React.MutableRefObject<URLSearchParams>;
  searchRefHooks?: SearchRefHooks;
  handleNavigate?: (params: URLSearchParams) => void;
  goto?: (pathname?: string | null, search?: URLSearchParams | null, state?: unknown | null, newTab?: boolean) => void;
  getSearchRef?: () => React.MutableRefObject<URLSearchParams>;
  setSearchRefHooks?: (newSearchHooks: SearchRefHooks) => void;
}

export enum NavigationContextUrlSearchParams {
  Q4_ENTITY_ID = "Q4_ENTITY_ID",
  Q4_CUSTODIAN_ID = "Q4_CUSTODIAN_ID",
  Q4_FUND_ID = "Q4_FUND_ID",
}
