import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface DragAndDropProps extends BaseComponentProps {
  children: JSX.Element;
  dropMessage?: string;
  handleDragIn?: (event?: DragEvent) => void;
  handleDragOut?: (event?: DragEvent) => void;
  handleDrag?: (event?: DragEvent) => void;
  handleDrop: (fileList?: FileList, event?: DragEvent) => void;
}

export enum DragAndDropClassName {
  Base = "drag-and-drop",
  Overlay = "drag-and-drop__overlay",
  OverlayMessage = "drag-and-drop__overlay-message",
}
