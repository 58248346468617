import type { BaseComponentProps } from "@q4/nimbus-ui";
import { ColDef } from "ag-grid-community";

export interface DateCellProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  value: string;
  colDef: ColDef;
  data: Record<string, unknown>;
}

export enum DateCellClassName {
  Base = "date-cell",
}
