import "./dtcc.view.scss";
import {
  DatePicker,
  DatePickerTheme,
  Grid,
  GridColumn,
  Layout,
  LayoutPadding,
  LayoutTheme,
  Select,
  SelectTheme,
} from "@q4/nimbus-ui";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment, { Moment } from "moment";
import { CustodianWeekly, CustodianVariance } from "q4-platform-common/src/models/custodian/custodian";
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { DayModifiers, Modifier } from "react-day-picker";
import { FeatureFlag } from "../../config";
import { DTCCContext, VIEW_MODES } from "../../contexts";
import CheckmarkButton from "../root/components/Button/CheckmarkButton/CheckmarkButton";
import CloseButton from "../root/components/Button/CloseButton/CloseButton";
import GridToolbar from "../root/components/GridToolbar/GridToolbar";
import FileUploadModal from "../root/components/Modal/FileUploadModal/FileUploadModal";
import SurveillanceGrid from "../root/components/SurveillanceGrid/SurveillanceGrid";
import {
  SurveillanceGridSummaryDef,
  defaultSurveillanceGridSummaryDef,
} from "../root/components/SurveillanceGrid/SurveillanceGrid.definition";
import DevLoader from "../root/components/devLoader/DevLoader";
import DTCCBanner from "./components/DTCCBanner/DTCCBanner";
import {
  DefaultColDef,
  DTCCustodiansDefs,
  DTCCTwoDayVarianceReportDefs,
  getStockPriceActivityDefs,
} from "./data/AgGridDefs";
import { DTCCClassName } from "./dtcc.definition";

const DEF_DAYS = 5;
const MAX_DAYS = 16;
const DAYS_OPTIONS = Array.from(Array(MAX_DAYS + 1).keys());

const DTCCView = (): JSX.Element => {
  const {
    latestDate,
    baseDate,
    varianceDate,
    overviewDays,
    dtccViewMode,
    DTCCustodians,
    DTCCustodiansOversized,
    DTCCustodiansLoading,
    DTCCustodiansVariance,
    DTCCustodiansVarianceLoading,
    DTCCStockPriceActivityData,
    DTCCStockPriceActivityDisplayData,
    DTCCStockPriceActivityDataLoading,
    setDtccViewMode,
    putDTCC,
    setBaseDate,
    setVarianceDate,
    setViewMounted,
    setOverviewDays,
  } = useContext(DTCCContext);
  const features = useFlags();
  const dtccBannerTitleList = ["Overview", "Two Day Variance"];
  const [dtccBannerTitle, setDtccBannerTitle] = useState(dtccBannerTitleList[0]);
  const [baseDateDisabled, setBaseDateDisabled] = useState(true);
  const [varianceDateDisabled, setVarianceDateDisabled] = useState(true);
  const [checkmarkDisabled, setCheckmarkDisabled] = useState(true);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [currentlyLoading, setCurrentlyLoading] = useState(true);
  const topCustodiansRef = useRef<AgGridReact>(null);
  const twoDayVarianceGridRef = useRef<AgGridReact>(null);
  const dtccStockDataGridRef = useRef<AgGridReact>(null);

  useEffect(() => {
    setViewMounted(true);
    return () => {
      setViewMounted(false);
    };
  }, [setViewMounted]);

  // TODO: This needs refactoring to use the context directly for each view (Ask James about this).
  useEffect(() => {
    if (
      latestDate &&
      baseDate &&
      baseDate <= latestDate &&
      !DTCCustodiansLoading &&
      !DTCCustodiansVarianceLoading &&
      !DTCCStockPriceActivityDataLoading
    ) {
      setCurrentlyLoading(false);
      setBaseDateDisabled(false);
      setVarianceDateDisabled(false);
      setCheckmarkDisabled(false);
    } else {
      setCurrentlyLoading(true);
      setBaseDateDisabled(true);
      setVarianceDateDisabled(true);
      setCheckmarkDisabled(true);
    }
  }, [
    DTCCustodiansLoading,
    DTCCustodiansVarianceLoading,
    DTCCStockPriceActivityDataLoading,
    baseDate,
    latestDate,
    setCurrentlyLoading,
  ]);

  const getBaseDateDisabledDays = (date: Date): boolean => {
    const today = latestDate.toDate();
    const dateTruncated = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const todayTruncated = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    return dateTruncated > todayTruncated;
  };
  const getVarianceDateDisabledDays = (date: Date): boolean => {
    const today = latestDate.toDate();
    const baseDateTruncated = baseDate
      ? new Date(baseDate.year(), baseDate.month(), baseDate.date())
      : new Date(today.getFullYear(), today.getMonth(), today.getDate());
    return date > baseDateTruncated;
  };
  const renderDatePicker = (
    value: Moment,
    onChange: (newValue: Moment, modifiers?: DayModifiers) => void,
    disabled: boolean,
    getDisabledDays: Modifier
  ) => (
    <DatePicker
      className={DTCCClassName.DatePicker}
      theme={DatePickerTheme.Rain}
      onChange={onChange}
      value={value}
      disabled={disabled}
      disabledDays={getDisabledDays}
    />
  );
  const baseDateOnChange = (e: Moment) => {
    setBaseDate(e);
    setVarianceDate(moment(e).subtract(1, "week"));
  };
  const varianceDateOnChange = (e: Moment) => {
    setVarianceDate(e);
  };
  const checkmarkOnClick = () => {
    setDtccBannerTitle(dtccBannerTitleList[1]);
    setBaseDateDisabled(true);
    setVarianceDateDisabled(true);
    setDtccViewMode(VIEW_MODES.VARIANCE);
  };
  const closeOnClick = () => {
    setDtccBannerTitle(dtccBannerTitleList[0]);
    setDtccViewMode(VIEW_MODES.BASE);
    baseDateOnChange(baseDate);
  };

  const renderDaysCountFilter = () => {
    if (dtccViewMode === VIEW_MODES.BASE) {
      return (
        <div className={`${DTCCClassName.Base} ${DTCCClassName.OverViewGridToolBarDays}`}>
          <Select
            theme={SelectTheme.Steel}
            placeholder={DEF_DAYS}
            onChange={(e: number) => {
              setOverviewDays(e);
            }}
            value={overviewDays}
            options={DAYS_OPTIONS}
            disabled={DTCCustodiansLoading}
          />
          <span>days</span>
        </div>
      );
    }
  };

  const renderGridToolbarLeftContent = () => {
    return (
      <div>
        <div className={DTCCClassName.GridToolbarTitle}>{dtccBannerTitle}</div>
        {renderDaysCountFilter()}
        {renderDatePicker(baseDate, baseDateOnChange, baseDateDisabled, getBaseDateDisabledDays)}
        {renderDatePicker(varianceDate, varianceDateOnChange, varianceDateDisabled, getVarianceDateDisabledDays)}
        <CheckmarkButton disabled={checkmarkDisabled} onClick={checkmarkOnClick} />
        <CloseButton disabled={dtccViewMode == VIEW_MODES.BASE} onClick={closeOnClick} />
      </div>
    );
  };

  const renderSurveillanceGrid = (
    gridRef: React.RefObject<AgGridReact>,
    columnDefs: Array<ColDef>,
    data: Array<CustodianWeekly> | Array<CustodianVariance> | Array<Record<string, unknown>>,
    id: string,
    summaryDef: SurveillanceGridSummaryDef = null
  ) => {
    return (
      <SurveillanceGrid
        id={id}
        gridRef={gridRef}
        DefaultColDef={DefaultColDef}
        ColumnDefs={columnDefs}
        data={data}
        overlayNoRowsTemplate="No Data"
        summaryDef={summaryDef}
      />
    );
  };
  const renderTopPerformersGrid = () => {
    return (
      <GridColumn width="1-of-1">
        <GridToolbar
          leftContent={renderGridToolbarLeftContent()}
          leftContentWidth="2-of-3"
          rightContent={null}
          gridRef={topCustodiansRef}
          showDownloadDataButton={true}
          showUploadDataButton={features[FeatureFlag.DTCCShowUploadDataButton]}
          uploadData={() => setShowFileUploadModal(true)}
          disabled={currentlyLoading}
        />
        <Layout theme={LayoutTheme.PaleGrey} direction="column">
          {renderSurveillanceGrid(
            topCustodiansRef,
            DTCCustodiansDefs(DTCCustodians, DTCCustodiansOversized),
            DTCCustodians,
            DTCCClassName.TopPerformersGrid,
            { ...defaultSurveillanceGridSummaryDef, showSummary: true }
          )}
        </Layout>
      </GridColumn>
    );
  };
  const renderTwoDayVarianceGrid = () => (
    <>
      <GridColumn width="1-of-1">
        <GridToolbar
          leftContent={renderGridToolbarLeftContent()}
          rightContent={null}
          gridRef={twoDayVarianceGridRef}
          showDownloadDataButton={true}
          showUploadDataButton={features[FeatureFlag.DTCCShowUploadDataButton]}
          uploadData={() => setShowFileUploadModal(true)}
          disabled={currentlyLoading}
        />
        <Layout theme={LayoutTheme.PaleGrey} direction="column">
          {renderSurveillanceGrid(
            twoDayVarianceGridRef,
            DTCCTwoDayVarianceReportDefs,
            DTCCustodiansVariance,
            DTCCClassName.TwoDayVarianceGrid,
            { ...defaultSurveillanceGridSummaryDef, showSummary: true }
          )}
        </Layout>
      </GridColumn>
    </>
  );
  const renderDTCCStockDataGrid = () => {
    return (
      <GridColumn width="1-of-1">
        <GridToolbar
          leftContent={
            <div>
              <div className={DTCCClassName.GridToolbarTitle}>{"Stock Data (16 Trading Days)"}</div>
            </div>
          }
          rightContent={null}
          showDownloadDataButton={true}
          gridRef={dtccStockDataGridRef}
          disabled={currentlyLoading}
        />
        <Layout theme={LayoutTheme.PaleGrey} direction="column">
          {renderSurveillanceGrid(
            dtccStockDataGridRef,
            getStockPriceActivityDefs(DTCCStockPriceActivityData),
            DTCCStockPriceActivityDisplayData,
            DTCCClassName.DTCCStockDataGrid
          )}
        </Layout>
      </GridColumn>
    );
  };
  const renderFileUploadModal = () => (
    <FileUploadModal
      title={"Upload DTCC"}
      fileLabel={"DTCC"}
      showModal={showFileUploadModal}
      setShowModal={setShowFileUploadModal}
      confirmCallback={putDTCC}
    />
  );
  const renderAllModals = () => {
    return <>{renderFileUploadModal()}</>;
  };
  return (
    <Layout
      className={`${DTCCClassName.Base} ${features[FeatureFlag.DTCCStockData] ? DTCCClassName.DTCCStockDataFeature : ""}`}
      padding={LayoutPadding.None}
      flex={false}
    >
      <DTCCBanner title={"DTCC"} />
      {currentlyLoading ? (
        <DevLoader />
      ) : (
        <>
          {features[FeatureFlag.DTCCStockData] && DTCCStockPriceActivityData && DTCCStockPriceActivityData.length > 0 && (
            <Grid className={`${DTCCClassName.Grid} ${DTCCClassName.TopGrid}`}>{renderDTCCStockDataGrid()}</Grid>
          )}
          <Grid className={`${DTCCClassName.Grid} ${DTCCClassName.BottomGrid}`}>
            {dtccViewMode == VIEW_MODES.BASE ? renderTopPerformersGrid() : renderTwoDayVarianceGrid()}
          </Grid>
          {renderAllModals()}
        </>
      )}
    </Layout>
  );
};

export default memo(DTCCView);
