import { Button, ButtonTheme } from "@q4/nimbus-ui";
import React from "react";
import { CheckmarkButtonClassName, CheckmarkButtonProps } from "./CheckmarkButton.definition";
import "./CheckmarkButton.scss";

const CheckmarkButton = (props: CheckmarkButtonProps): JSX.Element => {
  const { disabled, onClick } = props;
  return (
    <Button
      className={CheckmarkButtonClassName.Base}
      icon={"q4i-checkmark-4pt"}
      theme={ButtonTheme.Rain}
      label={""}
      disabled={disabled}
      onClick={() => {
        (document.activeElement as HTMLElement).blur();
        onClick();
      }}
    />
  );
};

export default CheckmarkButton;
