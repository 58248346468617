import "./SearchInput.component.scss";
import {
  Search,
  SearchTheme,
  SelectionList,
  SelectionListOption,
  Scrollbars,
  ScrollbarsTheme,
  Spinner,
  Popover,
  useVisibility,
  Origin,
  getClassName,
} from "@q4/nimbus-ui";
import { Search as SearchType } from "q4-platform-common/src/models/search/search";
import React, { useContext, useMemo, useState } from "react";
import { NavigateOptions } from "react-router-dom";
import { RoutePath } from "../../../../configurations/navigation.configuration";
import { NavigationContext, NavigationContextUrlSearchParams, TickerContext } from "../../../../contexts";
import SearchService from "../../../../services/search/SearchService";
import { SearchInputProps, SearchInputClassNames, SearchInputIdModel } from "./SearchInput.definition";

const SearchInput = (props: SearchInputProps): JSX.Element => {
  const { id, popover = true, selectionCallback, defaultEntityType = "ALL" } = props;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Array<SearchType>>([]);
  const searchService = useMemo(() => new SearchService(), []);
  const options = useMemo(getSearchOptions, [searchResults]); // eslint-disable-line react-hooks/exhaustive-deps
  const { ticker } = useContext(TickerContext);
  const { goto, getSearchRef } = useContext(NavigationContext);
  const [searchResultsVisible, handleSearchResultsShow, handleSearchResultsHide] = useVisibility();
  const idModel = useMemo(() => new SearchInputIdModel(id), [id]);

  const handleSearchQueryRequest = async () => {
    if (searchResults.length === 0 && !noResults) {
      setSearchLoading(true);
      handleSearchResultsShow();

      // Resolve search entity type, default ALL
      let searchEntityType = defaultEntityType;
      let i = searchTerm.indexOf("custodian: ");
      let searchTermToSearch = searchTerm;
      if (i >= 0) {
        searchEntityType = "CUSTODIAN";
        searchTermToSearch = searchTerm.substring(11);
      }
      i = searchTerm.indexOf("fund: ");
      if (i >= 0) {
        searchEntityType = "FUND";
        searchTermToSearch = searchTerm.substring(6);
      }
      i = searchTerm.indexOf("manager: ");
      if (i >= 0) {
        searchEntityType = "MANAGER";
        searchTermToSearch = searchTerm.substring(9);
      }

      const results = await searchService.search(searchTermToSearch, ticker.Q4_SEC_ID, searchEntityType);
      setSearchResults(results.data ? results.data : []);
      setNoResults(results.data ? false : true);
      setSearchLoading(false);
    }
  };

  function getSearchOptions(): SelectionListOption[] {
    if (searchResults.length === 0) {
      return [
        {
          key: "1",
          id: "1",
          value: "",
          title: "",
          description: <>No Results</>,
        },
      ];
    }
    return searchResults.map((result) => {
      setNoResults(false);
      return {
        key: result.Q4_ENTITY_ID,
        id: result.Q4_ENTITY_ID,
        value: "",
        title: result.NAME,
        description: (
          <>
            {result.COUNTRY ? `${result.COUNTRY} |` : ""} {result.TYPE}
            {result.HOLDINGS ? `| ${result.HOLDINGS} Shares` : ""}
          </>
        ),
      };
    });
  }

  function handleSelect(option: SelectionListOption) {
    handleSearchResultsHide();
    if (searchLoading) {
      return;
    }
    const searchEntity = searchResults.find((e) => e.Q4_ENTITY_ID == option.id);
    if (searchEntity) {
      if (selectionCallback) {
        return selectionCallback(searchEntity);
      } else if (searchEntity.TYPE === "Institution") {
        getSearchRef().current.set(NavigationContextUrlSearchParams.Q4_ENTITY_ID, searchEntity.Q4_ENTITY_ID); // !Move to context only in future
        goto(RoutePath.Manager, null, { state: searchEntity } as NavigateOptions, true);
      } else if (searchEntity.TYPE === "Custodian") {
        getSearchRef().current.set(NavigationContextUrlSearchParams.Q4_CUSTODIAN_ID, searchEntity.Q4_ENTITY_ID); // !Move to context only in future
        goto(RoutePath.Custodian, null, { state: searchEntity } as NavigateOptions, true);
      } else if (searchEntity.TYPE === "Fund") {
        getSearchRef().current.set(NavigationContextUrlSearchParams.Q4_FUND_ID, searchEntity.Q4_ENTITY_ID); // !Move to context only in future
        goto(RoutePath.Fund, null, { state: searchEntity } as NavigateOptions, true);
      } else {
        goto("/");
      }
      setSearchTerm("");
      setSearchResults([]);
      setNoResults(false);
    } else {
      console.error("Unknown entity type in search!?");
    }
  }

  const renderResultList = () => {
    const selectionOptions = searchLoading
      ? [
          {
            key: "1",
            id: "1",
            title: "",
            value: "",
            description: <Spinner></Spinner>,
          },
        ]
      : options;
    return (
      <Scrollbars theme={ScrollbarsTheme.Dark} autoHide>
        <SelectionList
          className={getClassName(SearchInputClassNames.List, [
            { condition: searchLoading, trueClassName: SearchInputClassNames.ListWithLoadingModifier },
          ])}
          onSelect={handleSelect}
          options={selectionOptions}
        />
      </Scrollbars>
    );
  };

  const renderPopoverResults = () => {
    return (
      <Popover
        id={idModel.popover.id}
        anchorTargetElementId={idModel.search.id}
        visible={searchResultsVisible}
        onCloseRequest={handleSearchResultsHide}
        targetOrigin={Origin.BottomLeft}
        popoverOrigin={Origin.TopLeft}
        focusOnProps={{ enabled: false }}
      >
        <div
          className={getClassName(SearchInputClassNames.List, [
            { condition: searchLoading, trueClassName: SearchInputClassNames.ListWithLoadingModifier },
            { condition: popover, trueClassName: SearchInputClassNames.ListWithPopoverModifier },
          ])}
        >
          {renderResultList()}
        </div>
      </Popover>
    );
  };

  function renderResults(): JSX.Element {
    if (popover) {
      return renderPopoverResults();
    }
    return renderResultList();
  }

  return (
    <div
      className={getClassName(SearchInputClassNames.Base, [
        { condition: popover, trueClassName: SearchInputClassNames.BasePopoverModifier },
      ])}
    >
      <Search
        id={idModel.search.id}
        value={searchTerm}
        disabled={!ticker?.Q4_SEC_ID}
        onInputChange={(e) => {
          setSearchTerm(e);
          setSearchResults([]);
          setNoResults(false);
        }}
        theme={SearchTheme.Ink}
        onClear={() => {
          setSearchResults([]);
          setNoResults(false);
        }}
        buffer={1000}
        onQueryRequest={handleSearchQueryRequest}
      />
      {renderResults()}
    </div>
  );
};

export default SearchInput;
