import { IdModelBase, TextIdModel } from "@q4/nimbus-ui";
import type { BaseComponentWithChildrenProps, ErrorLogContextState } from "@q4/nimbus-ui";
import { FilingJobListId } from "./components/job/filingJob.definition";

export interface FilingProps extends BaseComponentWithChildrenProps {
  errorLog?: ErrorLogContextState;
}

export enum FilingClassName {
  Base = "filing",
  Title = "filing__title",
  JobContainer = "filing__job-container",
  Banner = "filing__banner",
  Toolbar = "filing__toolbar",
  ToolbarGrid = "filing__toolbar-grid",
  NoDataMessage = "filing__no-data-message",
}

export class FilingIdModel extends IdModelBase {
  title: TextIdModel;
  jobList: FilingJobListId;

  constructor(id: string) {
    super(id);
    this.title = new TextIdModel(`${this.id}Title`);
    this.jobList = new FilingJobListId(this.id);
  }
}

export const ViewId = "Filing";
export const ViewIdModel = new FilingIdModel(ViewId);
