import "./root.view.scss";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Device,
  ErrorLogContext,
  isNullOrWhiteSpace,
  LoadingScreen,
  NavigationRoute,
  ToastContainer,
  useDeviceConfig,
  useResponsiveClassName,
  useViewport,
} from "@q4/nimbus-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { EmailClaim, OrganizationClaim } from "q4-platform-common";
import React, { ComponentType, memo, useCallback, useContext, useEffect, useMemo } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import logo from "../../assets/q4WhiteLogo.png";
import config, { FeatureFlag } from "../../config";
import { NavigationRoutes, RoutePath } from "../../configurations/navigation.configuration";
import { TickerContext } from "../../contexts";
import { LSAuthenticatedContext } from "../../contexts/LSAuthenticated";
import ClientTrackingService from "../../services/clientTracking/clientTracking.service";
import Banklist from "../banklist/banklist.view";
import Custodian from "../custodian/custodian.view";
import DTCC from "../dtcc/dtcc.view";
import Filing from "../filing/filing.view";
import Fund from "../fund/fund.view";
import Home from "../home/home.view";
import Manager from "../manager/manager.view";
import MutualFund from "../mutual-fund/mutual-fund.view";
import Report from "../report/report.view";
import Navigation from "./components/Navigation/Navigation";
import Fallback from "./components/fallback/fallback.component";
import GlobalMessage from "./components/globalMessage/globalMessage.component";
import Header from "./components/header/header.component";
import PrivateComponent from "./components/privateComponent/privateComponent";
import { RootClassName, ViewIdModel } from "./root.definition";

const Root = (): JSX.Element => {
  const location = useLocation();
  const features = useFlags();
  const errorLog = useContext(ErrorLogContext);
  const { setLSAuthenticated } = useContext(LSAuthenticatedContext);
  const { ticker } = useContext(TickerContext);
  const clientTrackingService = useMemo(() => new ClientTrackingService(), []);
  const { user, isAuthenticated: authenticated, isLoading: loading, error, getAccessTokenSilently } = useAuth0();
  const { device } = useDeviceConfig();
  const { height } = useViewport();
  const baseClassName = useResponsiveClassName(RootClassName.Base);
  const baseStyle = useMemo(() => (device !== Device.Desktop ? { height } : null), [device, height]);

  const getAndStoreToken = useCallback(async (): Promise<void> => {
    if (!authenticated) {
      return;
    }
    const token = await getAccessTokenSilently();
    if (!isNullOrWhiteSpace(token)) {
      localStorage.setItem(config.auth0.storageKey, token);
      setLSAuthenticated(true);
    }
  }, [authenticated, getAccessTokenSilently, setLSAuthenticated]);

  useEffect(() => {
    const page = Object.entries(RoutePath).find(([, value]) => value === location.pathname)?.[0] || "";
    document.title = `${ticker?.SYMBOL}: ${page}`;
  });

  useEffect(() => {
    const email = user?.[EmailClaim];
    const organization = user?.[OrganizationClaim];
    if (isNullOrWhiteSpace(email) || isNullOrWhiteSpace(organization)) {
      return;
    }
    clientTrackingService.init(email, organization);
  }, [clientTrackingService, user]);

  useEffect(() => {
    errorLog?.init && errorLog.init("setupuser@q4inc.com");
  }, [errorLog]);

  useEffect(() => {
    getAndStoreToken();
  }, [getAndStoreToken]);

  if (loading) {
    return <LoadingScreen id={ViewIdModel.loading} />;
  }

  if (error) {
    return <Fallback />;
  }

  const getLaunchDarklyNavigationRoutes = (): NavigationRoute[] =>
    NavigationRoutes.slice().filter((navigationRoute: NavigationRoute) => {
      const id = navigationRoute.id.toLowerCase();
      return features[id] === undefined || features[id];
    });

  const getLaunchDarklyPrivateComponent = (view: string, path: string, component: ComponentType) => {
    const id = view.toLowerCase();
    if (features[id] === undefined || features[id]) {
      return <Route path={path} element={<PrivateComponent element={component} />} />;
    }
    return <></>;
  };

  const renderNavigation = () =>
    authenticated ? (
      <Navigation
        className={RootClassName.Navigation}
        id={ViewIdModel.navigation.id}
        logo={<img alt="Q4 Inc." src={logo} />}
        routes={getLaunchDarklyNavigationRoutes()}
        location={location}
        match={undefined}
        history={undefined}
      />
    ) : (
      false
    );

  const renderHeader = () => (authenticated ? <Header id={ViewIdModel.header.id} /> : false);

  const renderRoutes = () => (
    <div className={RootClassName.View}>
      <Routes>
        {getLaunchDarklyPrivateComponent(FeatureFlag.BanklistView, RoutePath.Banklist, Banklist)}
        <Route path={RoutePath.Custodian} element={<PrivateComponent element={Custodian} />} />
        <Route path={RoutePath.Manager} element={<PrivateComponent element={Manager} />} />
        <Route path={RoutePath.Fund} element={<PrivateComponent element={Fund} />} />
        {getLaunchDarklyPrivateComponent(FeatureFlag.DTCCView, RoutePath.DTCC, DTCC)}
        <Route path={RoutePath.Filing} element={<PrivateComponent element={Filing} />} />
        <Route path={RoutePath.Home} element={<PrivateComponent element={Home} />} />
        {getLaunchDarklyPrivateComponent(FeatureFlag.MutualFundView, RoutePath.MutualFund, MutualFund)}
        <Route path={RoutePath.Report} element={<PrivateComponent element={Report} />} />
        <Route path="*" element={<PrivateComponent element={Home} />} />
      </Routes>
    </div>
  );

  return (
    <>
      <div className={baseClassName} id={ViewIdModel.id} style={baseStyle}>
        {renderNavigation()}
        <div className={RootClassName.Inner}>
          {renderHeader()}
          {renderRoutes()}
        </div>
      </div>
      <ToastContainer newestOnTop position="bottom-left" />
      <GlobalMessage id={ViewIdModel.globalMessage.id} />
    </>
  );
};

export default memo(Root);
