import React from "react";
import type { FilingLegendProps } from "../FilingLegend/FilingLegend.definition";
import { FilingLegendClassName } from "./FilingLegend.definition";
import "./FilingLegend.scss";

const FilingLegend = (props: FilingLegendProps): JSX.Element => {
  const { label = "", type = "" } = props;
  return <div className={`${FilingLegendClassName.Base} ${type}`}>{label}</div>;
};

export default FilingLegend;
