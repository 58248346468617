import "./custodian.view.scss";
import { Grid, GridColumn, Layout, LayoutPadding, LayoutTheme } from "@q4/nimbus-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { FeatureFlag } from "../../config";
import { CustodianContext } from "../../contexts";
import { getIsFeatureEnabled } from "../../utils/feature/feature.utils";
import AddButton from "../root/components/Button/AddButton/AddButton";
import EntityBanner from "../root/components/EntityBanner/EntityBanner";
import GridToolbar from "../root/components/GridToolbar/GridToolbar";
import MapXToYModal from "../root/components/Modal/MapXToYModal/MapXToYModal";
import SurveillanceGrid from "../root/components/SurveillanceGrid/SurveillanceGrid";
import DevLoader from "../root/components/devLoader/DevLoader";
import { CustodianClassName } from "./custodian.definition";
import {
  DefaultColDef,
  CustodianTransactionsColumnDef,
  CustodianManagersColumnDef,
  CustodianMutualFundsColumnDef,
} from "./data/AgGridDefs";

const Custodian = (): JSX.Element => {
  const features = useFlags();
  const [mapManagerToCustodians, setMapManagerToCustodiansModal] = useState(false);
  const [mapMutualFundToCustodians, setMapMutualFundToCustodiansModal] = useState(false);
  const transactionsGridRef = useRef(null);
  const custodianManagerGridRef = useRef(null);
  const mutualFundsGridRef = useRef(null);
  const {
    custodianMetadata,
    custodianMetadataLoading,
    custodianTransactions,
    custodianTransactionsLoading,
    custodianManagers,
    custodianManagersLoading,
    custodianMutualFunds,
    custodianMutualFundsLoading,
    setViewMounted,
    putCustodianMapping,
  } = useContext(CustodianContext);

  useEffect(() => {
    setViewMounted(true);
    return () => {
      setViewMounted(false);
    };
  }, [setViewMounted]);

  const currentlyLoading =
    custodianTransactionsLoading || custodianManagersLoading || custodianMetadataLoading || custodianMutualFundsLoading;

  const renderAddManagerButton = () => (
    <AddButton
      disabled={!getIsFeatureEnabled(features[FeatureFlag.MapManagerToCustodians])}
      onClick={() => {
        setMapManagerToCustodiansModal(true);
      }}
    />
  );

  const renderMapManagerToCustodians = () => {
    return (
      <MapXToYModal
        title="Map Manager to Custodians"
        showModal={mapManagerToCustodians}
        setShowModal={setMapManagerToCustodiansModal}
        searchEntityType="MANAGER"
        confirmCallback={putCustodianMapping}
      />
    );
  };

  const renderAddMutualFundButton = () => (
    <AddButton
      disabled={false}
      onClick={() => {
        setMapMutualFundToCustodiansModal(true);
      }}
    />
  );

  const renderMapMutualFundToCustodians = () => {
    return (
      <MapXToYModal
        title="Map Mutual Fund to Custodians"
        showModal={mapMutualFundToCustodians}
        setShowModal={setMapMutualFundToCustodiansModal}
        searchEntityType="FUND"
        confirmCallback={putCustodianMapping}
      />
    );
  };
  const renderAllModals = () => {
    return (
      <>
        {renderMapManagerToCustodians()}
        {renderMapMutualFundToCustodians()}
      </>
    );
  };
  return (
    <Layout className={CustodianClassName.Base} padding={LayoutPadding.None} flex={false}>
      <EntityBanner loading={currentlyLoading} entityMetadata={custodianMetadata} custodians={[]} />
      {currentlyLoading ? (
        <DevLoader />
      ) : (
        <Grid className={CustodianClassName.Grid}>
          <GridColumn width="1-of-3">
            <GridToolbar
              leftContent="Transactions"
              rightContent={null}
              gridRef={transactionsGridRef}
              showDownloadDataButton={true}
            />
            <Layout theme={LayoutTheme.PaleGrey} direction="column">
              <SurveillanceGrid
                id={"custodian-transactions-surveillance-grid"}
                gridRef={transactionsGridRef}
                DefaultColDef={DefaultColDef}
                ColumnDefs={CustodianTransactionsColumnDef}
                data={custodianTransactions}
                overlayNoRowsTemplate={"No Data Available"}
                disableLoaderText={true}
              />
            </Layout>
          </GridColumn>
          <GridColumn width="2-of-3">
            <Grid className={CustodianClassName.Grid}>
              <GridColumn width="1-of-1">
                <GridToolbar
                  leftContent="Managers"
                  rightContent={renderAddManagerButton()}
                  gridRef={custodianManagerGridRef}
                  showDownloadDataButton={true}
                />
                <Layout theme={LayoutTheme.PaleGrey} direction="column">
                  <SurveillanceGrid
                    id={"custodian-fund-managers-surveillance-grid"}
                    className={CustodianClassName.HalfHeight}
                    gridRef={custodianManagerGridRef}
                    DefaultColDef={DefaultColDef}
                    ColumnDefs={CustodianManagersColumnDef}
                    data={custodianManagers}
                    overlayNoRowsTemplate={"No Data Available"}
                    disableLoaderText={true}
                  />
                </Layout>
              </GridColumn>
              <GridColumn width="1-of-1">
                <GridToolbar
                  leftContent="Mutual Funds"
                  rightContent={renderAddMutualFundButton()}
                  gridRef={mutualFundsGridRef}
                  showDownloadDataButton={true}
                />
                <Layout theme={LayoutTheme.PaleGrey} direction="column">
                  <SurveillanceGrid
                    id={"custodian-mutual-funds-surveillance-grid"}
                    className={CustodianClassName.HalfHeight}
                    gridRef={mutualFundsGridRef}
                    DefaultColDef={DefaultColDef}
                    ColumnDefs={CustodianMutualFundsColumnDef}
                    data={custodianMutualFunds}
                    overlayNoRowsTemplate={"No Data Available"}
                    disableLoaderText={true}
                  />
                </Layout>
              </GridColumn>
            </Grid>
          </GridColumn>
        </Grid>
      )}
      {renderAllModals()}
    </Layout>
  );
};

export default memo(Custodian);
