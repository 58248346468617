import { isEmpty, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import initPendo from "../../views/root/initPendo";

declare const pendo: { initialize: (props: { visitor: { id: string }; account: { id: string } }) => void };

export default class ClientTrackingService {
  initialized = false;

  init = (email: string, organization: string): void => {
    if (this.initialized) return;

    if (isNullOrWhiteSpace(email)) {
      console.log("Pendo not initialised as email isn't present");
      return;
    }

    if (isNullOrWhiteSpace(organization)) {
      console.log("Pendo not initialised as company isn't present");
      return;
    }

    initPendo();
    if (isEmpty(pendo)) {
      console.log("Failed to load Pendo");
      return;
    }

    pendo.initialize({
      visitor: {
        id: email,
      },
      account: {
        id: organization,
      },
    });
    console.log(`Pendo initialised with username: ${email} and company ${organization}`);
    this.initialized = true;
  };
}
