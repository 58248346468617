import { NotificationService } from "@q4/nimbus-ui";
import moment, { Moment } from "moment";
import { BanklistVar, TwoDayVarianceManager } from "q4-platform-common/src/models/banklist/banklist";
import { Search } from "q4-platform-common/src/models/search/search";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import BanklistService from "../../services/banklist/BanklistService";
import { TickerContext } from "../ticker";
import { BanklistContextState, BanklistProviderProps, BanklistRef, BaseVarDate } from "./banklist.definition";

export const BanklistContext = createContext<Partial<BanklistContextState>>({});

export const BanklistProvider = (props: BanklistProviderProps): JSX.Element => {
  const [viewMounted, setViewMounted] = useState<boolean>(false);
  const [showMapBanklistToManagerModal, setShowMapBanklistToManagerModal] = useState<boolean>(false);
  const [twoDayVarianceManager, setTwoDayVarianceManager] = useState<TwoDayVarianceManager>(null);
  const [banklistId, setBanklistId] = useState<number>(4);
  const banklistIdRef = useRef(banklistId);
  const { ticker, setTickerSelectorDisabled } = useContext(TickerContext);
  const tickerRef = useRef(null);
  const notificationService = useRef(new NotificationService());
  const banklistService = useMemo(() => new BanklistService(), []);
  const [banklist, setBanklist] = useState<BanklistRef>(null);
  const [banklistMetadataLoading, setBanklistMetadataLoading] = useState<boolean>(true);
  const [banklistVariance, setBanklistVariance] = useState<Array<BanklistVar>>(null);
  const [validDates, setValidDates] = useState<Array<Moment>>([]);
  const [baseVarDate, setBaseVarDate] = useState<BaseVarDate>({ base: moment(), variance: moment() });
  const baseDateRef = useRef(null);
  const varianceDateRef = useRef(null);

  useEffect(() => {
    if (window.location.pathname === "/banklist") {
      setTickerSelectorDisabled(!ticker || banklistMetadataLoading);
    }
  }, [ticker, banklist, banklistMetadataLoading, setTickerSelectorDisabled]);

  const fetchDates = useCallback(
    async (secId) => {
      setBanklistMetadataLoading(true);
      const latestDatesRaw = await banklistService.getBanklistDates(banklistId, secId);
      if (latestDatesRaw.data && latestDatesRaw.data.length > 0) {
        const dates = latestDatesRaw.data.map((date) => moment(date.DATE));
        dates.sort((a, b) => a.valueOf() - b.valueOf());
        setValidDates(dates);
        if (dates.length == 1) {
          setBaseVarDate({ base: dates[dates.length - 1], variance: dates[dates.length - 1] });
          notificationService.current.warn("Only a single day of Banklist data was found, no variance possible");
        } else {
          setBaseVarDate({ base: dates[dates.length - 1], variance: dates[dates.length - 2] });
        }
      } else {
        notificationService.current.warn("No Banklist data available for this Ticker");
        console.warn("No data available for this Ticker");
        setValidDates([]);
        setBanklistVariance([]);
      }
      setBanklistMetadataLoading(false);
    },
    [banklistId, banklistService]
  );

  const fetchBanklistVar = useCallback(async () => {
    setBanklistMetadataLoading(true);
    setBanklistVariance(null);
    const q4StockId = ticker.Q4_SEC_ID;
    const result = await banklistService.getBanklistVariance(banklistId, q4StockId, baseVarDate.variance, baseVarDate.base);
    if (result.success && result.data && result.data.length > 0) {
      setBanklistVariance(result.data);
    } else {
      setBanklistVariance([]);
      result.success
        ? notificationService.current.warn("No Banlist Variance was found")
        : notificationService.current.error(`Banklist Variance failed ${result.message}`);
      console.warn(result.message ? result.message : "No Banklist Variance Data returned", result);
    }
    setBanklistMetadataLoading(false);
  }, [banklistId, banklistService, baseVarDate, ticker]);

  /**
   * Put a Bank List into the db
   */
  const putBanklist = useCallback(
    async (banklistId: number, file: string) => {
      setBanklistMetadataLoading(true);
      const res = await banklistService.putBanklist(banklistId, file);
      if (res.success) {
        // Due to how quick it is to get the data we simply refetch the data
        notificationService.current.info("Banklist Uploaded");
        setBanklistMetadataLoading(false);
        return true;
      } else {
        notificationService.current.error(`Banklist Upload Error: ${res.message}`);
        setBanklistMetadataLoading(false);
        return false;
      }
    },
    [banklistService]
  );

  const putBanklistMapping = useCallback(
    async (search: Search) => {
      setBanklistMetadataLoading(true);
      const res = await banklistService.putBanklistMapping(
        banklistId,
        search.Q4_ENTITY_ID,
        twoDayVarianceManager.BANKLIST_MANAGER
      );
      if (res.success) {
        await fetchDates(ticker.Q4_SEC_ID);
        // Due to how quick it is to get the data we simply refetch the data
        notificationService.current.info("Banklist Mapping Added");
        setBanklistMetadataLoading(false);
        return true;
      } else {
        notificationService.current.error("Banklist Mapping Failed");
        setBanklistMetadataLoading(false);
        return false;
      }
    },
    [banklistId, banklistService, twoDayVarianceManager, ticker, fetchDates]
  );

  /**
   * Execute all data fetches for current contextual view when upstream contexts change
   */
  useEffect(() => {
    if (
      viewMounted &&
      ticker &&
      ticker.Q4_SEC_ID &&
      (baseDateRef.current != baseVarDate.base ||
        varianceDateRef.current != baseVarDate.variance ||
        tickerRef.current != ticker.Q4_SEC_ID ||
        banklistIdRef.current != banklistId)
    ) {
      baseDateRef.current = baseVarDate.base;
      varianceDateRef.current = baseVarDate.variance;
      if (banklistIdRef.current != banklistId || tickerRef.current != ticker.Q4_SEC_ID) {
        tickerRef.current = ticker.Q4_SEC_ID;
        banklistIdRef.current = banklistId;
        fetchDates(ticker.Q4_SEC_ID);
      } else {
        fetchBanklistVar();
      }
    }
  }, [baseDateRef, viewMounted, ticker, tickerRef, banklistId, fetchDates, fetchBanklistVar, baseVarDate]);

  const value = {
    banklist,
    banklistId,
    banklistMetadataLoading,
    banklistVariance,
    validDates,
    baseVarDate,
    showMapBanklistToManagerModal,
    twoDayVarianceManager,
    setBanklist,
    setBanklistId,
    setViewMounted,
    putBanklistMapping,
    putBanklist,
    setBaseVarDate,
    setShowMapBanklistToManagerModal,
    setTwoDayVarianceManager,
  };
  return <BanklistContext.Provider value={value}>{props.children}</BanklistContext.Provider>;
};
