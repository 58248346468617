import { StockPrice, StockVolume, StockShortVolume, StockSummary } from "q4-platform-common/src/models/stock/stock";
import { ApiResponse } from "../api/api.definition";
import ApiService from "../api/api.service";

export default class StockDataService {
  private readonly apiService: ApiService;
  private readonly ApiPath = "/stock";
  constructor() {
    this.apiService = new ApiService();
  }
  async getStockVolume(q4TickerId: string, startDate: string, endDate: string): Promise<ApiResponse<Array<StockVolume>>> {
    const response = await this.apiService.get<Array<StockVolume>>(
      `${this.ApiPath}/volume?q4TickerId=${q4TickerId}&startDate=${startDate}&endDate=${endDate}`
    );
    return new ApiResponse({ ...response });
  }
  async getStockPrice(q4TickerId: string, startDate: string, endDate: string): Promise<ApiResponse<Array<StockPrice>>> {
    const response = await this.apiService.get<Array<StockPrice>>(
      `${this.ApiPath}/price?q4TickerId=${q4TickerId}&startDate=${startDate}&endDate=${endDate}`
    );
    return new ApiResponse({ ...response });
  }
  async getStockShortVolume(
    q4TickerId: string,
    startDate: string,
    endDate: string
  ): Promise<ApiResponse<Array<StockShortVolume>>> {
    const response = await this.apiService.get<Array<StockShortVolume>>(
      `${this.ApiPath}/short_volume?q4TickerId=${q4TickerId}&startDate=${startDate}&endDate=${endDate}`
    );
    return new ApiResponse({ ...response });
  }
  async getStockSummary(
    q4TickerId: string,
    baseDate: string,
    days: number,
    sort = "DESC"
  ): Promise<ApiResponse<Array<StockSummary>>> {
    const response = await this.apiService.get<Array<StockSummary>>(
      `${this.ApiPath}/summary?q4TickerId=${q4TickerId}&baseDate=${baseDate}&days=${days}&sort=${sort}`
    );
    return new ApiResponse({ ...response });
  }
}
