import { ButtonTheme, Modal } from "@q4/nimbus-ui";
import React from "react";
import { AreYouSureModalClassName, AreYouSureModalProps } from "./AreYouSureModal.definition";
import "./AreYouSureModal.scss";

const AreYouSureModal = (props: AreYouSureModalProps): JSX.Element => {
  const {
    children,
    customClassName = "",
    title = "Are You Sure?",
    subtitle = "This action cannot be reversed.",
    cancelButtonLabel = "Cancel",
    confirmButtonLabel = "Confirm",
    showModal,
    setShowModal,
    callback,
  } = props;
  const onClickCancel = () => {
    setShowModal(false);
  };
  const onClickAdd = () => {
    setShowModal(false);
    callback();
  };
  const getModalFooterActions = () => [
    { label: cancelButtonLabel, theme: ButtonTheme.DarkSlate, onClick: onClickCancel },
    { label: confirmButtonLabel, theme: ButtonTheme.Citrus, onClick: onClickAdd },
  ];
  return (
    <Modal
      className={customClassName ? `${AreYouSureModalClassName.Base} ${customClassName}` : AreYouSureModalClassName.Base}
      visible={showModal}
      title={title}
      subtitle={subtitle}
      onCloseRequest={() => setShowModal(false)}
      footerActions={getModalFooterActions()}
    >
      {children}
    </Modal>
  );
};

export default AreYouSureModal;
