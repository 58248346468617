import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface DTCCBannerProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  title?: string;
  details?: string;
}

export enum DTCCBannerClassName {
  Base = "dtcc-banner",
}
