import { Textbox } from "@q4/nimbus-ui";
import React, { useEffect, useState } from "react";
import { AddFundsFormClassName, AddFundsFormProps, AddFundsFormField } from "./AddFundsForm.definition";
import "./AddFundsForm.scss";

const AddFundsForm = (props: AddFundsFormProps): JSX.Element => {
  const { showModal } = props;
  const [custodianId, setCustodianId] = useState("");
  const [fundName, setFundName] = useState("");
  const [shares, setShares] = useState("");
  const valid = true;
  useEffect(() => {
    if (!showModal) {
      setCustodianId("");
      setFundName("");
      setShares("");
    }
  }, [showModal]);
  const fields = [
    {
      label: "Custodian ID",
      placeholder: "####",
      error: "Custodian ID is required",
      value: custodianId,
      onChange: setCustodianId,
    },
    {
      label: "Fund Name",
      placeholder: "Enter fund name",
      error: "Fund Name is required",
      value: fundName,
      onChange: setFundName,
    },
    {
      label: "Shares",
      placeholder: "[count] shares",
      error: "Shares is required",
      value: shares,
      onChange: setShares,
    },
  ];
  return (
    <form className={AddFundsFormClassName.Base} onSubmit={(e) => e.preventDefault()} autoComplete="off">
      {fields.map((field: AddFundsFormField, fieldIndex: number) => (
        <label className={AddFundsFormClassName.Input} key={fieldIndex}>
          <span className={AddFundsFormClassName.Label}>{field.label}</span>
          <Textbox
            className={AddFundsFormClassName.Textbox}
            placeholder={field.placeholder}
            value={field.value}
            onChange={field.onChange}
          />
          {!valid && (
            <span className={AddFundsFormClassName.Error} aria-live="polite">
              {field.error}
            </span>
          )}
        </label>
      ))}
    </form>
  );
};

export default AddFundsForm;
