import { boldCellStyleFormatter, colorAndBoldCellStyleFormatter } from "../../../utils/ag-grid/ag-grid.cellstyle-formatters";
import {
  isFirstColumn,
  getDateColumnDef,
  getNumericColumnDef,
  getTextColumnDef,
} from "../../../utils/ag-grid/ag-grid.column-defs";

export const DefaultColDef = {
  headerCheckboxSelection: isFirstColumn,
  checkboxSelection: isFirstColumn,
  sortingOrder: ["desc", "asc"],
};

export const TransactionsColumnDefs = [
  { ...getDateColumnDef("DATE", "DATE", null, boldCellStyleFormatter) },
  { ...getNumericColumnDef("SHARES", "SHARES", null, boldCellStyleFormatter) },
  { ...getNumericColumnDef("CHANGE", "CHANGE", null, colorAndBoldCellStyleFormatter) },
  { ...getNumericColumnDef("QTR CHANGE", "QTR_CHANGE", null, colorAndBoldCellStyleFormatter) },
  { ...getNumericColumnDef("PRICE ($)", "PRICE", null, boldCellStyleFormatter) },
  { ...getTextColumnDef("SOURCE", "SOURCE", null, boldCellStyleFormatter) },
  { ...getTextColumnDef("NOTE", "NOTE", null, boldCellStyleFormatter) },
];
