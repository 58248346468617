import { useContext, useEffect } from "react";
import { TickerContext } from "../../../contexts";

export const useFetchData = (loading: boolean, fetchDataCallback: () => void): void => {
  const { ticker, q4TickerIdRef } = useContext(TickerContext);
  useEffect(() => {
    if (ticker) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (q4TickerIdRef as any).current = ticker.Q4_TICKER_ID;
    }
  }, [q4TickerIdRef, ticker]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const prevQ4TickerId = (q4TickerIdRef as any).current;
  useEffect(() => {
    if (ticker && ticker.Q4_TICKER_ID) {
      if (!loading && ticker.Q4_TICKER_ID !== prevQ4TickerId) {
        fetchDataCallback();
      }
    }
  }, [ticker, prevQ4TickerId, loading, fetchDataCallback]);
};

export default useFetchData;
