import type { BaseComponentWithChildrenProps, ErrorLogContextState } from "@q4/nimbus-ui";
import { Shareholder } from "q4-platform-common/src/models/shareholder/shareholder";

export interface ManagerProps extends BaseComponentWithChildrenProps {
  errorLog?: ErrorLogContextState;
}

export interface ManagerLocationState {
  state: Shareholder;
}

export enum ManagerClassName {
  Base = "manager",
  Grid = "manager__grid",
  Modal = "manager__modal",
  NoDataMessage = "manager__no-data-message",
  Transactions = "manager__transactions",
  Funds = "manager__funds",
}
