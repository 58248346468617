import type { BaseComponentWithChildrenProps, ErrorLogContextState } from "@q4/nimbus-ui";
import { RouteProps } from "react-router-dom";

export interface BanklistProps extends BaseComponentWithChildrenProps, RouteProps {
  errorLog?: ErrorLogContextState;
}

export enum BanklistClassName {
  Base = "banklist",
  Grid = "banklist__grid",
  Modal = "banklist__modal",
  DatePicker = "banklist__date-picker",
  GridToolbarTitle = "banklist__grid-toolbar-title",
}
