import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { ExternalLinkProps } from "./ExternalLink.definition";

import "./ExternalLink.scss";

const ExternalLink = (props: ExternalLinkProps): JSX.Element => {
  return (
    <a className="externalLink" href={props.url} target="_blank">
      {props.label}
      <FaArrowUpRightFromSquare />
    </a>
  );
};

export default ExternalLink;
