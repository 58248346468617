import { GetTicker } from "q4-platform-common/src/models/ticker/ticker";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { LSAuthenticatedContext, NavigationContext, RefetchContext } from "..";
import { Ticker } from "../../../../../src/api/models/ticker";
import { REFETCH_SCOPE } from "../../contexts/refetch/refetch.definition";
import TickerService from "../../services/ticker/TickerService";
import { TickerContextState, TickerProviderProps } from "./ticker.definition";

export const TickerContext = createContext<Partial<TickerContextState>>({});
export const Q4_STOCK_ID = "Q4_STOCK_ID";

export const TickerProvider = (props: TickerProviderProps): JSX.Element => {
  const { lsAuthenticated } = useContext(LSAuthenticatedContext);
  const { getSearchRef, goto } = useContext(NavigationContext);
  const [ticker, setTicker] = useState<GetTicker>(null);
  const [tickers, setTickers] = useState<Array<GetTicker>>([]);
  const [tickerSelectorDisabled, setTickerSelectorDisabled] = useState(true);
  const tickerService = useMemo(() => new TickerService(), []);
  const { addRefetchHook } = useContext(RefetchContext);
  const q4TickerIdRef = useRef(null);

  const setTickerFromUrlSearchParameters = useCallback(
    (data: Array<Ticker>): Ticker => {
      const q4TickerId = getSearchRef().current?.get(Q4_STOCK_ID);
      if (q4TickerId) {
        const foundTicker = data.find((t: Ticker) => t.Q4_SEC_ID === q4TickerId);
        if (foundTicker) {
          setTicker(foundTicker);
          return foundTicker;
        }
      }
      const clone = data;
      setTicker(clone[0]);
      return clone[0];
    },
    [getSearchRef]
  );

  const fetchTickers = useCallback(async () => {
    const result = await tickerService.getTickers();
    if (result.success && result.data) {
      setTickers(result.data);
      getSearchRef().current.set(Q4_STOCK_ID, setTickerFromUrlSearchParameters(result.data).Q4_SEC_ID);
      goto();
    } else {
      console.warn("There was an error retrieving the tickers: ", result.message);
    }
  }, [goto, getSearchRef, setTickerFromUrlSearchParameters, tickerService]);

  addRefetchHook(REFETCH_SCOPE.TICKERS, "ticker.fetchTickers", fetchTickers);

  useEffect(() => {
    if (!ticker && lsAuthenticated) {
      fetchTickers();
    }
  }, [fetchTickers, lsAuthenticated, ticker]);

  useEffect(() => {
    const q4TickerId = getSearchRef().current?.get(Q4_STOCK_ID);
    if (ticker && (!q4TickerId || (q4TickerId && ticker.Q4_SEC_ID !== q4TickerId))) {
      getSearchRef().current.set(Q4_STOCK_ID, ticker.Q4_SEC_ID);
      goto();
    }
  }, [ticker, goto, getSearchRef]);

  const value = {
    ticker,
    tickers,
    q4TickerIdRef,
    tickerSelectorDisabled,
    setTicker,
    setTickerSelectorDisabled,
  };
  return <TickerContext.Provider value={value}>{props.children}</TickerContext.Provider>;
};
