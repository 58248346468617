import type { BaseComponentProps } from "@q4/nimbus-ui";
import { Dispatch, SetStateAction } from "react";

export interface AddFundsFormProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  showModal: boolean;
}

export interface AddFundsFormField {
  label: string;
  placeholder: string;
  error: string;
  value: string;
  onChange: Dispatch<SetStateAction<string>>;
}

export enum AddFundsFormClassName {
  Base = "add-funds-form",
  Input = "add-funds-form__input",
  Label = "add-funds-form__label",
  Textbox = "add-funds-form__textbox",
  Error = "add-funds-form__error",
}
