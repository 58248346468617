import { withAuthenticationRequired } from "@auth0/auth0-react";
import { LoadingScreen } from "@q4/nimbus-ui";
import React from "react";
import type { PrivateRouteProps } from "./privateComponent.definition";

const PrivateComponent = (props: PrivateRouteProps): React.ReactElement => {
  const { element, errorLog } = props;
  if (typeof errorLog?.setContext === "function") {
    errorLog.setContext();
  }
  const RedirectionComponent = () => <LoadingScreen />;
  const Component = withAuthenticationRequired(element, {
    onRedirecting: RedirectionComponent,
  });
  return <Component />;
};

export default PrivateComponent;
