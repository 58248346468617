import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface EditManagerModalProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  showModal: boolean;
  editMode?: boolean;
  q4StockId: string;
  q4EntityId?: string;
  setShowModal: (showModal: boolean) => void;
}

export enum EditManagerModalClassName {
  Base = "edit-manager-modal",
}
