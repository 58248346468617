import { NotificationService } from "@q4/nimbus-ui";
import moment from "moment";
import { FilingData, SetFilingData } from "q4-platform-common/src/models/filingData/filingData";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { RefetchContext, REFETCH_SCOPE, TickerContext } from "..";
import FilingService from "../../services/filing/FilingService";
import ManagerService from "../../services/manager/ManagerService";
import { FilingContextState, FilingProviderProps } from "./filing.definition";

export const FilingContext = createContext<Partial<FilingContextState>>({});

export const FilingProvider = (props: FilingProviderProps): JSX.Element => {
  const notificationService = useRef(new NotificationService());
  const { ticker, setTickerSelectorDisabled } = useContext(TickerContext);
  const { refetch } = useContext(RefetchContext);
  const [filingData, setFilingData] = useState<Array<FilingData>>([]); // Master list
  const [displayData, setDisplayData] = useState<Array<FilingData>>([]); // Display list
  const [filingDataLoading, setFilingDataLoading] = useState(false);
  const [includeIgnored, setIncludeIgnored] = useState(false);
  const [selectedFilings, setSelectedFilings] = useState([]);
  const [filingGridBusy, setFilingGridBusy] = useState(false);
  const [showDeleteFilingModal, setShowDeleteFilingModal] = useState(false);

  const filingService = useMemo(() => new FilingService(), []);
  const managerService = useMemo(() => new ManagerService(), []);

  useEffect(() => {
    if (window.location.pathname === "/filing") {
      setTickerSelectorDisabled(!ticker || filingDataLoading || filingGridBusy);
    }
  }, [ticker, filingDataLoading, filingGridBusy, setTickerSelectorDisabled]);

  useEffect(() => {
    let newData = filingData.filter((d: FilingData) => !["accept", "reconcile", "delete"].includes(d.ACTION_STATE));
    if (!includeIgnored) {
      newData = newData.filter((d: FilingData) => d.ACTION_STATE !== "ignore");
    }
    setDisplayData(newData);
  }, [filingData, includeIgnored]);

  const fetchFilingData = async () => {
    if (ticker) {
      setFilingDataLoading(true);
      const q4SecurityId = ticker.Q4_SEC_ID;
      const result = await filingService.getFilingData(q4SecurityId);
      setFilingDataLoading(false);
      if (result.success && result.data) {
        setFilingData(result.data);
      } else {
        setFilingData([]);
        notificationService.current.info("There was no filing data available for this particular ticker.");
        console.warn(result.message);
      }
    } else {
      console.warn("There were no tickers to pull the q4 security ID from.");
    }
  };

  const getFilingPromise = (actionState: string, data: Array<SetFilingData>): Promise<[SetFilingData]> => {
    const filings = data.map((filing: SetFilingData) => {
      return {
        Q4_STOCK_ID: filing.Q4_STOCK_ID,
        Q4_ENTITY_ID: filing.Q4_ENTITY_ID,
        FILING_TYPE: filing.FILING_TYPE,
        FILING_DATE: filing.FILING_DATE,
        ACTION_STATE: actionState,
      } as SetFilingData;
    });

    return new Promise((resolve, reject) => {
      filingService
        .putFilingData(filings)
        .then((result) => {
          if (result.success) {
            resolve(result.data);
          } else {
            notificationService.current.error(
              `${actionState} error: `,
              result.message as unknown as Record<string, unknown>
            );
            reject(`There was an error calling ${actionState}`);
          }
        })
        .catch((error) => {
          reject(`Error getting results for ${actionState}: ${error}`);
        });
    });
  };

  const putFilingData = async (actionState: string, data: SetFilingData) => {
    setFilingDataLoading(true);
    if (actionState === "delete") {
      // Show modal

      // Perform a real delete
      if (selectedFilings.length === 1) {
        // Use multiple custom position deletion via manager API to delete custom positions if a single manager is selected
        const deleteResponse = await managerService.deleteTransactionData(
          [
            {
              DATE: moment(selectedFilings[0].data.FILING_DATE).add(1, "days").toDate(),
              QUANTITY: 0,
            },
            {
              DATE: moment().toDate(),
              QUANTITY: 0,
            },
          ],
          selectedFilings[0].data.Q4_STOCK_ID,
          selectedFilings[0].data.Q4_ENTITY_ID
        );

        if (deleteResponse.success) {
          notificationService.current.success(`Successfully deleted filing for ${selectedFilings[0].data.NAME}`);
          await refetch(REFETCH_SCOPE.MANAGER_TRANSACTIONS);
        } else {
          notificationService.current.error(
            `Error deleting filing for ${selectedFilings[0].data.NAME}: ${deleteResponse.message}`
          );
        }
      } else {
        // If multiple managers are selected call new API endpoint to delete multiple manager records for a date range
        console.log("Delete multiple positions for multiple managers");
        const bulkDeleteResponse = await managerService.batchDeleteTransaction(
          selectedFilings[0].data.FILING_DATE,
          moment(selectedFilings[0].data.FILING_DATE).add(45, "day"),
          selectedFilings[0].data.Q4_STOCK_ID,
          selectedFilings.map((filing) => filing.data.Q4_ENTITY_ID)
        );

        if (bulkDeleteResponse.success) {
          notificationService.current.success("Successfully deleted filings");
        } else {
          notificationService.current.error(`Error deleting filings: ${bulkDeleteResponse.message}`);
        }
      }

      actionState = "accept";
    }

    notificationService.current.info("Processing filings");
    setFilingGridBusy(true);
    let promise: Promise<[SetFilingData]> = null;

    if (data) {
      promise = getFilingPromise(actionState, [data]);
    } else {
      promise = getFilingPromise(
        actionState,
        selectedFilings.map((filing) => filing.data)
      );
    }

    let clone = filingData.slice();
    promise
      .then((nodes) => {
        nodes.forEach((node) => {
          clone = clone.map((filing) => {
            if (node.Q4_ENTITY_ID === filing.Q4_ENTITY_ID) {
              filing.ACTION_STATE = node.ACTION_STATE;
            }
            return filing;
          });
        });
      })
      .catch((error) => {
        notificationService.current.error(error.message);
      })
      .finally(() => {
        notificationService.current.info("Filing(s) complete");
        setFilingData(clone);
        setFilingGridBusy(false);
        setFilingDataLoading(false);
      });
  };

  const value = {
    filingGridBusy,
    selectedFilings,
    displayData,
    filingData,
    filingDataLoading,
    includeIgnored,
    putFilingData,
    setSelectedFilings,
    setFilingData,
    setIncludeIgnored,
    fetchFilingData,
    showDeleteFilingModal,
    setShowDeleteFilingModal,
  };
  return <FilingContext.Provider value={value}>{props.children}</FilingContext.Provider>;
};
