import config from "../../config";
import { ApiResponse, ResponseCode } from "./api.definition";
import ApiService from "./api.service";

export const REQUEST_CANCELED = "Request Canceled";
export const REQUEST_TIMEOUT = "Request Timeout";

export const timeout = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default class PollingApiService extends ApiService {
  private cancelRequest = false;
  private maxWait;
  private wait;

  constructor(maxWait?: number, wait?: number) {
    super();
    this.maxWait = maxWait || config.pollingTimeout;
    this.wait = wait || config.pollingInterval;
  }

  public cancelInFlightRequest(): void {
    this.cancelRequest = true;
  }

  /**
   * Generic wrapper to implement polling of the API using the ApiService
   * @param fn A function that makes a call to the ApiService using the same <TResponse>
   * @returns The result of the ApiService once it reaches the polling limit or returns a response that is not 202
   */
  private async pollingWrapper<TResponse>(fn: () => Promise<ApiResponse<TResponse>>): Promise<ApiResponse<TResponse>> {
    this.cancelRequest = false;
    const pollingStartTime = Date.now();

    // Poll while we have not reached timeout or canceled the request
    while (Date.now() - pollingStartTime < this.maxWait && !this.cancelRequest) {
      const pollStartTime = performance.now();
      const response = await fn();
      const pollEndTime = performance.now() - pollStartTime;
      if (response.status == ResponseCode.Accepted) {
        // Polling
        const estimatedDelay = this.wait - pollEndTime;
        await timeout(estimatedDelay > 0 ? estimatedDelay : 0);
        continue;
      } else if (response.status == ResponseCode.Ok || response.status == ResponseCode.OkNoContent) {
        // We got a result
        return response;
      } else {
        // Something went wrong
        this.cancelRequest = true;
        throw Error(response.message);
      }
    }

    // Handle a cancel or timeout error correctly
    if (this.cancelRequest) {
      this.cancelRequest = false;
      throw Error(REQUEST_CANCELED);
    } else {
      throw Error(REQUEST_TIMEOUT);
    }
  }

  /**
   * Wrapper to do polling GET
   * @param relativeUrl - The URL to execute the get from including url params
   * @returns The result of the API or an error if polling times out
   */
  public async get<TResponse>(relativeUrl: string): Promise<ApiResponse<TResponse>> {
    return this.pollingWrapper<TResponse>(async () => super.get<TResponse>(relativeUrl));
  }

  /**
   * Wrapper to do polling POST
   * @param relativeUrl - The URL to execute the post from including url params
   * @param data - The body to send in the post
   * @returns The result of the API or an error if the polling times out
   */
  public async post<TBody, TResponse = TBody>(relativeUrl: string, data: TBody): Promise<ApiResponse<TResponse>> {
    return this.pollingWrapper<TResponse>(async () => super.post<TBody, TResponse>(relativeUrl, data));
  }
}
