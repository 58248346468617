import { IdModelBase, isNullOrWhiteSpace, MessageIdModel, NavigationIdModel } from "@q4/nimbus-ui";
import { HeaderIdModel } from "./components/header/header.definition";

export interface PrivateRouteProps {
  element: React.ComponentType;
}

export enum RootClassName {
  Base = "q4-platform",
  Navigation = "q4-platform_navigation",
  Inner = "q4-platform_inner",
  View = "q4-platform_view",
}

export class RootIdModel extends IdModelBase {
  loading: string;
  navigation: NavigationIdModel;
  header: HeaderIdModel;
  globalMessage: MessageIdModel;

  constructor(id: string) {
    super(id);

    if (isNullOrWhiteSpace(this.id)) {
      this.navigation = new NavigationIdModel(null);
      this.header = new HeaderIdModel(null);
      this.globalMessage = new MessageIdModel(null);

      return;
    }

    this.loading = `${this.id}LoadingScreen`;
    this.navigation = new NavigationIdModel(`${this.id}Navigation`);
    this.header = new HeaderIdModel(`${this.id}Header`);
    this.globalMessage = new MessageIdModel(`${this.id}GlobalMessage`);
  }
}

export const ViewId = "Q4Platform";
export const ViewIdModel = new RootIdModel(ViewId);
