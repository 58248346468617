import type { BaseComponentWithChildrenProps, ErrorLogContextState } from "@q4/nimbus-ui";
import { RouteProps } from "react-router-dom";

export interface MutualFundProps extends BaseComponentWithChildrenProps, RouteProps {
  errorLog?: ErrorLogContextState;
}

export enum MutualFundClassName {
  Base = "mutual-fund",
  Grid = "mutual-fund__grid",
}

export enum MutualFundDataTestId {
  MinCustodianDifference = "min-custodian-difference",
  MinSuggestionDifference = "min-suggestion-difference",
  MutualFundGridToolbar = "mutual-fund-grid-toolbar",
  MutualFundSurveillanceGrid = "mutual-fund-surveillance-grid",
  MutualFundIncludeReviewed = "mutual-fund-include-reviewed",
}
