import { AutomationListId, ButtonIdModel, CardIdModel, IdModelBase } from "@q4/nimbus-ui";
import type { Key } from "react";
import { ReportJob } from "../../data/jobs/jobs.definition";

export type ReportJobProps = ReportJob;

export enum ReportJobClassName {
  Base = "filing-job",
  BaseWidthDisabledModifier = "filing-job--disabled",
  Icon = "filing-job_icon",
}

export class ReportJobIdModel extends IdModelBase {
  card: CardIdModel;
  go: ButtonIdModel;

  constructor(id: string, index?: Key, postfix?: string) {
    super(id, index, postfix);

    this.card = new CardIdModel(`${this.id}Card`);
    this.go = new ButtonIdModel(`${this.id}GoButton`);
  }
}

export class ReportJobListId extends AutomationListId<ReportJobIdModel> {
  constructor(parentId: string, prefix = "", postfix = "Job") {
    super(parentId, prefix, ReportJobIdModel, postfix);
  }
}
