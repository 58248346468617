import { ButtonIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface ProfileMenuProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
}

export enum ProfileMenuClassName {
  Base = "profile-menu",
  Button = "profile-menu__button",
  Popover = "profile-menu__popover",
}

export class ProfileMenuIdModel extends IdModelBase {
  profile: ButtonIdModel;
  logOut: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) {
      this.profile = new ButtonIdModel(null);
      this.logOut = new ButtonIdModel(null);

      return;
    }

    this.profile = new ButtonIdModel(`${this.id}ProfileButton`);
    this.logOut = new ButtonIdModel(`${this.id}LogOutButton`);
  }
}
