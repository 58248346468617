import { Checkbox } from "@q4/nimbus-ui";
import React from "react";
import { BooleanCellClassName, BooleanCellProps } from "./BooleanCell.definition";
import "./BooleanCell.scss";

export interface Params<T> {
  params: T;
}

const BooleanCell = (props: BooleanCellProps): React.ReactFragment => {
  const { value, onChange, data } = props;
  const localUUID = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return value != undefined || value != null ? (
    <Checkbox
      id={`SurveillanceGridBooleanCell-${localUUID}`}
      className={BooleanCellClassName.Base}
      checked={value}
      onChange={function (checked: boolean): void {
        onChange(checked, data);
      }}
    />
  ) : (
    false
  );
};

export default BooleanCell;
