import { Fund } from "q4-platform-common/src/models/fund/fund";
import { Shareholder } from "q4-platform-common/src/models/shareholder/shareholder";
import { RoutePath } from "../../../configurations/navigation.configuration";
import { NavigationContextUrlSearchParams } from "../../../contexts";
import { colorToChangeCellStyleFormatter } from "../../../utils/ag-grid/ag-grid.cellstyle-formatters";
import {
  getCurrencyColumnDef,
  getDateColumnDef,
  getNameColumnDef,
  getNumericColumnDef,
} from "../../../utils/ag-grid/ag-grid.column-defs";
import { NameCellClassName } from "../../root/components/Cell/NameCell/NameCell.definition";

const cellClassParamsFormatter = (): string => NameCellClassName.Base;

export { DefaultColDef } from "../../../utils/ag-grid/ag-grid.column-defs";

export const CustodianTransactionsColumnDef = [
  { ...getDateColumnDef("DATE", "DATE"), sort: "desc" },
  { ...getNumericColumnDef("QUANTITY", "QUANTITY") },
  { ...getNumericColumnDef("CHANGE", "CHANGE", null, colorToChangeCellStyleFormatter) },
  { ...getNumericColumnDef("QTD CHANGE", "QTD_CHANGE", null, colorToChangeCellStyleFormatter) },
];

export const CustodianManagersColumnDef = [
  {
    ...getNameColumnDef(
      "NAME",
      "NAME",
      {
        path: RoutePath.Manager,
        urlSearchParamsFormatter: (search: React.MutableRefObject<URLSearchParams>, data: Shareholder) =>
          search.current.set(NavigationContextUrlSearchParams.Q4_ENTITY_ID, data.Q4_ENTITY_ID),
      },
      null,
      cellClassParamsFormatter
    ),
  },
  { ...getDateColumnDef("DATE", "DATE") },
  { ...getNumericColumnDef("CURRENT POSITION", "CURRENT_POSITION"), sort: "desc" },
  { ...getNumericColumnDef("QTD CHANGE", "QTD_CHG", null, colorToChangeCellStyleFormatter) },
  { ...getNumericColumnDef("PREV QTR CHANGE", "PREV_QTR_CHG", null, colorToChangeCellStyleFormatter) },
  { ...getCurrencyColumnDef("AVERAGE PRICE", "AVERAGE_PRICE") },
];

export const CustodianMutualFundsColumnDef = [
  {
    ...getNameColumnDef(
      "NAME",
      "NAME",
      {
        path: RoutePath.Fund,
        urlSearchParamsFormatter: (search: React.MutableRefObject<URLSearchParams>, data: Fund) =>
          search.current.set(
            NavigationContextUrlSearchParams.Q4_FUND_ID,
            data.Q4_FUND_ID ? data.Q4_FUND_ID : data.Q4_ENTITY_ID
          ),
      },
      null,
      cellClassParamsFormatter
    ),
  },
  { ...getDateColumnDef("DATE", "DATE") },
  { ...getNumericColumnDef("CURRENT POSITION", "CURRENT_POSITION"), sort: "desc" },
  { ...getNumericColumnDef("QTD CHANGE", "QTD_CHG", null, colorToChangeCellStyleFormatter) },
  { ...getNumericColumnDef("PREV QTR CHANGE", "PREV_QTR_CHG", null, colorToChangeCellStyleFormatter) },
  { ...getCurrencyColumnDef("AVERAGE PRICE", "AVERAGE_PRICE") },
];
