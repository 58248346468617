import type { BaseComponentProps } from "@q4/nimbus-ui";
import { Search } from "q4-platform-common/src/models/search/search";

export interface MapXToYModalProps extends BaseComponentProps {
  title?: string;
  subtitle?: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  confirmCallback: (value: Search) => void;
  searchEntityType: string;
}

export enum MapXToYModalClassName {
  Base = "map-x-to-y-modal",
}
