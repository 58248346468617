import { Button, ButtonTheme } from "@q4/nimbus-ui";
import React from "react";
import { FilterButtonClassName, FilterButtonProps } from "./FilterButton.definition";
import "./FilterButton.scss";

const FilterButton = (props: FilterButtonProps): JSX.Element => {
  const { disabled, onClick } = props;
  return (
    <Button
      className={FilterButtonClassName.Base}
      icon={"q4i-filter"}
      theme={ButtonTheme.Rain}
      label={"FILTERS"}
      disabled={disabled}
      onClick={() => {
        (document.activeElement as HTMLElement).blur();
        onClick();
      }}
    />
  );
};

export default FilterButton;
