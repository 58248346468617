import { Button, ButtonTheme } from "@q4/nimbus-ui";
import React from "react";
import { DownloadButtonClassName, DownloadButtonProps } from "./DownloadButton.definition";
import "./DownloadButton.scss";

const DownloadButton = (props: DownloadButtonProps): JSX.Element => {
  const { disabled, onClick } = props;
  return (
    <Button
      className={DownloadButtonClassName.Base}
      icon={"q4i-download-4pt"}
      theme={ButtonTheme.Rain}
      label={""}
      disabled={disabled}
      onClick={() => {
        (document.activeElement as HTMLElement).blur();
        onClick();
      }}
    />
  );
};

export default DownloadButton;
