import React, { createContext, useCallback, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, NavigateOptions, useNavigate } from "react-router-dom";
import { NavigationContextState, NavigationProviderProps, SearchRefHooks } from "./navigation.definition";

export const NavigationContext = createContext<Partial<NavigationContextState>>({});
const BAD_PARAMS = ["code", "state"];

export const NavigationProvider = (props: NavigationProviderProps): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchRefHooks, setSearchRefHooks] = useState<SearchRefHooks>({});
  const [cookie, setCookie] = useCookies(["pathname", "url_search_params"]);
  const pathRef = useRef(cookie.pathname ? cookie.pathname : location.pathname);
  const searchRef = useRef<URLSearchParams>(
    new URLSearchParams(window.location.search ? window.location.search : cookie.url_search_params)
  );

  /**
   * The Goto function is a wrapper for navigation to aid in maintaining context for URL Search Params
   */
  const goto = useCallback(
    (
      pathname?: string | null,
      search?: URLSearchParams | null,
      navigateOptions?: NavigateOptions | null,
      newTab = false
    ) => {
      // Reconcile Local References with the World
      if (pathname) {
        pathRef.current = pathname;
      } else if (pathRef.current) {
        if (window.location.pathname != pathRef.current) {
          pathRef.current = window.location.pathname; // Handle if nav occured outside of this context
        }
        pathname = pathRef.current;
      }
      if (search && search.has) {
        searchRef.current = search;
      } else {
        search = searchRef.current;
      }

      // Cleanup Login Params
      BAD_PARAMS.forEach((param) => {
        if (searchRef.current.has(param)) {
          searchRef.current.delete(param);
        }
      });

      setCookie("pathname", pathname);
      setCookie("url_search_params", search.toString());
      console.debug("GOTO - pathname: ", pathname);
      console.debug("GOTO - search: ", search.toString());
      console.debug("GOTO - navigateOptions: ", navigateOptions);

      // Update Hooked Contexts
      for (const key in searchRefHooks) {
        searchRefHooks[key](searchRef);
      }

      const queryString = search.toString();

      if (newTab) {
        const path = queryString.length > 0 ? `${pathname}?${queryString}` : pathname;
        window.open(path, "_blank");
      } else {
        navigate(
          {
            pathname,
            search: queryString,
          },
          {
            replace: false,
            ...navigateOptions,
          }
        );
      }
    },
    [setCookie, searchRefHooks, navigate]
  );

  const getSearchRef = (): React.MutableRefObject<URLSearchParams> => {
    if (!searchRef.current) {
      searchRef.current = new URLSearchParams(window.location.search ? window.location.search : cookie.url_search_params);
    } else if (searchRef.current.get("code") || searchRef.current.get("state")) {
      searchRef.current = new URLSearchParams(cookie.url_search_params);
    }
    return searchRef;
  };

  const value = { searchRef, goto, getSearchRef, searchRefHooks, setSearchRefHooks };
  return <NavigationContext.Provider value={value}>{props.children}</NavigationContext.Provider>;
};
