import { Moment } from "moment";
import { ReportConfig } from "q4-platform-common/src/models/report/report";
import { apiDateFormatter } from "../../utils/date/dateUtils";
import { ApiResponse } from "../api/api.definition";
import PollingApiService from "../api/pollingApi.service";

export default class ReportService {
  private readonly apiService: PollingApiService;
  private readonly ApiPath = "/report";
  constructor(maxWait?: number, wait?: number) {
    this.apiService = new PollingApiService(maxWait, wait);
  }

  cancelReportGeneration(): void {
    this.apiService.cancelInFlightRequest();
  }

  async generateReport(q4StockId: string, reportType: string, reportDate: Moment): Promise<ApiResponse<[]>> {
    const formattedReportDate = apiDateFormatter(reportDate);
    const apiPath = `${this.ApiPath}?secId=${q4StockId}&reportType=${reportType}&reportDate=${formattedReportDate}`;
    const response = await this.apiService.get<[]>(apiPath);
    return new ApiResponse({ ...response });
  }

  async generateDetailedReport(config: ReportConfig): Promise<ApiResponse<[]>> {
    const apiPath = `${this.ApiPath}`;
    const response = await this.apiService.post<ReportConfig, []>(apiPath, config);
    return new ApiResponse({ ...response });
  }
}
