import { Chip, ChipTheme, DatePicker, DatePickerTheme } from "@q4/nimbus-ui";
import moment, { Moment } from "moment";
import React, { useRef } from "react";
import { ChipDatePickerClassName, ChipDatePickerProps } from "./ChipDatePicker.definition";
import "./ChipDatePicker.scss";

const ChipDatePicker = (props: ChipDatePickerProps): JSX.Element => {
  const {
    id,
    datePickerDisabled = false,
    chipValue = "",
    chipData = {},
    datePickerValue = null,
    disabledDays,
    datePickerOnChange,
    chipOnRemove,
  } = props;

  const dateRef = useRef<Moment>(datePickerValue);

  const renderDatePicker = () => (
    <DatePicker
      className={ChipDatePickerClassName.DatePicker}
      theme={DatePickerTheme.Rain}
      disabled={datePickerDisabled}
      {...(disabledDays && { disabledDays })}
      onChange={(e) => {
        const newDate = moment(e); // Prevent nonsense inside DatePicker
        // If we need to support other granularities we will need to add a prop for "day"
        if (!dateRef.current.isSame(newDate, "day")) {
          dateRef.current = newDate;
          if (datePickerOnChange) {
            datePickerOnChange(newDate);
          }
        }
      }}
      value={datePickerValue}
    />
  );
  const renderChip = () => (
    <Chip
      id={id}
      className={ChipDatePickerClassName.Base}
      theme={ChipTheme.Rain}
      value={chipValue}
      data={chipData}
      label={renderDatePicker()}
      locked={datePickerDisabled}
      {...{
        onRemove: (value: string, data: unknown) => chipOnRemove(value, data),
      }}
    />
  );
  return renderChip();
};

export default ChipDatePicker;
