import { Banner, BannerSize } from "@q4/nimbus-ui";
import React from "react";
import { ReportBannerClassName } from "./ReportBanner.definition";
import "./ReportBanner.scss";

const ReportBanner = (): JSX.Element => {
  const lastUpdated = "Welcome to Cyclops dashboard";
  const title = "Report";
  return (
    <Banner className={ReportBannerClassName.Base} badgeIcon="" title={title} size={BannerSize.Thin} details={lastUpdated} />
  );
};

export default ReportBanner;
