import { Button, Grid, GridColumn, NotificationService } from "@q4/nimbus-ui";
import _ from "lodash";
import moment, { Moment } from "moment";
import React, { useRef } from "react";
import ChipDatePicker from "../ChipDatePicker/ChipDatePicker";
import { ChipDatePickerListClassName, ChipDatePickerListProps } from "./ChipDatePickerList.definition";
import "./ChipDatePickerList.scss";

const ChipDatePickerList = (props: ChipDatePickerListProps): JSX.Element => {
  const { disabled = false, minDates = 1, maxDates = 10, datesPerRow = 5, maxDate = moment(), dates, setDates } = props;
  const notificationService = useRef(new NotificationService());

  /**
   * Adds a date to the list, ignores the action if the new date is higher than maxDate
   */
  const addDate = () => {
    if (dates.length <= maxDates) {
      const newOptions = dates.slice();
      newOptions.push(maxDate);
      setDates(newOptions);
    } else {
      notificationService.current.warn(`Must not exceed ${maxDates} date(s)`);
    }
  };

  /**
   * Removes a date from the list, ignores the action if the list will be smaller than minDates
   */
  const removeDate = (index: number) => {
    if (dates.length > minDates) {
      const newOptions = dates.slice();
      newOptions.splice(index, 1);
      setDates(newOptions);
    } else {
      notificationService.current.warn(`Must have at least ${minDates} date(s)`);
    }
  };

  /**
   * Updates a date from the list, ignores the action if the new date is higher than maxDate
   */
  const updateDate = (e: Moment, index: number) => {
    const newOptions = dates.slice();
    newOptions[index] = e;
    if (newOptions[index] > maxDate) {
      notificationService.current.warn(`Can't set date larger than ${maxDate.toString()}`);
    } else {
      setDates(newOptions);
    }
  };

  const renderChipDatePicker = (_value: unknown, index: number) => (
    <GridColumn key={`${ChipDatePickerListClassName.GridColumn}-${index}`} width={`1-of-${datesPerRow}`}>
      <ChipDatePicker
        className={ChipDatePickerListClassName.ChipDatePicker}
        datePickerValue={dates[index] ? dates[index] : null}
        chipData={dates[index].format("YYYY-MM-DD")}
        chipOnRemove={() => removeDate(index)}
        datePickerOnChange={(e: Moment) => updateDate(moment(e), index)}
        datePickerDisabled={disabled}
        disabledDays={(e: Date) => moment(e) > moment(maxDate)}
      />
    </GridColumn>
  );

  const renderPlusButton = () => <Button label={"+"} disabled={disabled} onClick={addDate} />;

  const matrix = _.chunk(dates, datesPerRow);
  const showSecondRow = dates.length > datesPerRow;
  const showPlusButton = dates.length !== maxDates;
  return (
    <Grid className={ChipDatePickerListClassName.Base} key={`${ChipDatePickerListClassName.Base}-${dates.toString()}`}>
      <GridColumn width="1-of-1">
        <Grid>{matrix[0] && matrix[0].map((value: unknown, index: number) => renderChipDatePicker(value, index))}</Grid>
      </GridColumn>
      {showSecondRow && (
        <GridColumn width="1-of-1">
          <Grid>
            {matrix[1] && matrix[1].map((value: unknown, index: number) => renderChipDatePicker(value, index + datesPerRow))}
          </Grid>
        </GridColumn>
      )}
      {showPlusButton && <div>{renderPlusButton()}</div>}
    </Grid>
  );
};

export default ChipDatePickerList;
