import type { BaseComponentProps } from "@q4/nimbus-ui";
import { ColDef } from "ag-grid-community";

export const SurveillanceGridModalId = "surveillance_grid_modal";
export const SurveillanceGridModalLiteralId = "surveillance_grid_modal_literal";

export interface SurveillanceGridModalProps<DataType> extends BaseComponentProps {
  customClassName?: string;
  showModal: boolean;
  title: string;
  subtitle?: string;
  nextButtonCallback?: () => void;
  setShowModal: (showModal: boolean) => void;
  getData: () => Promise<Array<DataType>> | Promise<[]> | [];
  defaultColDef: ColDef;
  colDefs: Array<ColDef>;
}

export enum SurveillanceGridModalClassName {
  Base = "surveillance-grid-modal",
}
