import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface ReportModalProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

export enum ReportModalClassName {
  Base = "report-modal",
}
