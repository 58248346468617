import { Checkbox, Form } from "@q4/nimbus-ui";
import React from "react";
import GridToolbar from "../../../root/components/GridToolbar/GridToolbar";
import SVLNumericInput from "../../../root/components/SVLNumericInput/SVLNumericInput";
import { MutualFundDataTestId } from "../../mutual-fund.definition";
import { MutualFundGridToolbarClassName, MutualFundGridToolbarProps } from "./MutualFundGridToolbar.definition";
import "./MutualFundGridToolbar.scss";

const MutualFundGridToolbar = (props: MutualFundGridToolbarProps): JSX.Element => {
  const { gridRef, minDifference, setMinDifference, minChange, setMinChange, includeReviewed, setIncludeReviewed } = props;
  const renderGridToolbarLeftContent = () => {
    const minChangeTitle = "Min Change (%)";
    const minDifferenceTitle = "Min Difference";
    const includeReviewedTitle = "Show Reviewed:";
    const minCustodianDifferenceField = {
      key: "1",
      width: "1-of-3",
      children: (
        <SVLNumericInput
          data-testid={MutualFundDataTestId.MinCustodianDifference}
          className={MutualFundDataTestId.MinCustodianDifference}
          title={minDifferenceTitle}
          placeholder={minDifferenceTitle}
          inputMode="numeric"
          min={-Infinity}
          max={Infinity}
          value={minDifference}
          onChange={(value: number) => setMinDifference(value)}
        />
      ),
    };
    const minSuggestionDifferenceField = {
      key: "2",
      width: "1-of-3",
      children: (
        <SVLNumericInput
          data-testid={MutualFundDataTestId.MinSuggestionDifference}
          className={MutualFundDataTestId.MinSuggestionDifference}
          title={minChangeTitle}
          placeholder={minChangeTitle}
          inputMode="numeric"
          min={-100}
          max={100}
          format={(value: number | string) => value + "%"}
          value={minChange}
          onChange={(value: number) => setMinChange(value)}
        />
      ),
    };
    const includeReviewedField = {
      key: "3",
      width: "1-of-3",
      children: (
        <Checkbox
          id={MutualFundDataTestId.MutualFundIncludeReviewed}
          data-testid={MutualFundDataTestId.MutualFundIncludeReviewed}
          label={includeReviewedTitle}
          labelAlign="left"
          checked={includeReviewed}
          onChange={(value: boolean) => setIncludeReviewed(value)}
        />
      ),
    };
    return (
      <div className={MutualFundGridToolbarClassName.Base} data-testid={MutualFundDataTestId.MutualFundGridToolbar}>
        <Form fields={[minCustodianDifferenceField, minSuggestionDifferenceField, includeReviewedField]} />
      </div>
    );
  };
  return (
    <GridToolbar
      leftContent={<div className={MutualFundGridToolbarClassName.Title}>{"Fund To Date"}</div>}
      rightContent={renderGridToolbarLeftContent()}
      gridRef={gridRef}
      showDownloadDataButton={true}
    />
  );
};

export default MutualFundGridToolbar;
