import type { BaseComponentProps } from "@q4/nimbus-ui";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import React from "react";

export interface MutualFundGridToolbarProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  gridRef: React.RefObject<AgGridReact>;
  minDifference: number;
  setMinDifference: (n: number) => void;
  minChange: number;
  setMinChange: (n: number) => void;
  includeReviewed: boolean;
  setIncludeReviewed: (includeReviewed: boolean) => void;
}

export enum MutualFundGridToolbarClassName {
  Base = "mutual-fund-grid-toolbar",
  Title = "mutual-fund-grid-toolbar__title",
}
