import { EntityMetadata } from "q4-platform-common/src/models/entity/entity";

export function getEmptyMetadata(): EntityMetadata {
  return {
    NAME: "",
    AVERAGE_PRICE: null,
    EQUITY_ASSETS: null,
    ACCOUNTS: null,
    WITH_HOLDINGS: null,
    TYPE: null,
    LOCATION: null,
    LOAN: null,
    CUSTODIANS: [],
  };
}
