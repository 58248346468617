import { Checkbox, Grid, GridColumn, InputType, Select, SelectTheme, Textbox } from "@q4/nimbus-ui";
import { ManagerStyles, Styles, Turnovers, InstitutionTypes } from "q4-platform-common/src/models/manager/manager.constants";
import React, { SyntheticEvent, useCallback } from "react";
import { AddFundsFormClassName } from "../../../../../manager/components/AddFundsForm/AddFundsForm.definition";
import { EditManagerFormClassName, EditManagerFormProps } from "./EditManagerForm.definition";
import "./EditManagerForm.scss";

const EditManagerForm = (props: EditManagerFormProps): JSX.Element => {
  const { instName, instType, style, managerStyle, turnover, countryCd, countryName, activist, isStakeholder, cik } =
    props.form;
  const { updateState, valid, editMode } = props;

  const handleInstitutionNameChange = useCallback(
    (value: string) => {
      updateState({ instName: value });
    },
    [updateState]
  );

  const handleTurnoverChange = useCallback(
    (value: string) => {
      updateState({ turnover: value });
    },
    [updateState]
  );

  const handleStyleChange = useCallback(
    (value: string) => {
      updateState({ style: value });
    },
    [updateState]
  );

  const handleManagerStyleChange = useCallback(
    (value: string) => {
      updateState({ managerStyle: value });
    },
    [updateState]
  );

  const handleInstitutionTypeChange = useCallback(
    (value: string) => {
      updateState({ instType: value });
    },
    [updateState]
  );

  const handleIsStakeholderChange = useCallback(
    (value: boolean) => {
      updateState({ isStakeholder: value });
    },
    [updateState]
  );

  const handleIsActivistChange = useCallback(
    (value: boolean) => {
      updateState({ activist: value });
    },
    [updateState]
  );

  const handleCIKChange = useCallback(
    (value: string) => {
      if (value.length > 10) {
        return;
      }

      updateState({ cik: value });
    },
    [updateState]
  );

  const handleCountryChange = useCallback(
    (country: { countryName: string; countryCd: string }) => {
      updateState({ countryName: country?.countryName, countryCd: country?.countryCd });
    },
    [updateState]
  );

  const renderInstitutionName = () => {
    const label = "Institution Name";
    return (
      <label className={EditManagerFormClassName.Input}>
        <span className={EditManagerFormClassName.Label}>{label}</span>
        <Textbox
          className={AddFundsFormClassName.Textbox}
          placeholder={label}
          value={instName}
          onChange={handleInstitutionNameChange}
          disabled={editMode}
        />
        {!valid && (
          <span className={EditManagerFormClassName.Error} aria-live="polite">
            {label} is required.
          </span>
        )}
      </label>
    );
  };

  const renderTurnover = () => {
    const label = "Turnover";
    return (
      <label className={EditManagerFormClassName.Input}>
        <span className={EditManagerFormClassName.Label}>{label}</span>
        <Select
          value={turnover}
          options={Turnovers}
          onChange={handleTurnoverChange}
          theme={SelectTheme.LightGrey}
          placeholder={"Choose a Turnover"}
        />
      </label>
    );
  };

  const renderStyle = () => {
    const label = "Style";
    return (
      <label className={EditManagerFormClassName.Input}>
        <span className={EditManagerFormClassName.Label}>{label}</span>
        <Select
          value={style}
          options={Styles}
          onChange={handleStyleChange}
          theme={SelectTheme.LightGrey}
          placeholder={"Choose a Style"}
        />
      </label>
    );
  };

  const renderManagerStyle = () => {
    const label = "Manager Style";
    return (
      <label className={EditManagerFormClassName.Input}>
        <span className={EditManagerFormClassName.Label}>{label}</span>
        <Select
          value={managerStyle}
          options={ManagerStyles}
          onChange={handleManagerStyleChange}
          theme={SelectTheme.LightGrey}
          placeholder={"Choose a Manager Style"}
        />
      </label>
    );
  };

  const renderInstitutionType = () => {
    const label = "Institution Type";
    return (
      <label className={EditManagerFormClassName.Input}>
        <span className={EditManagerFormClassName.Label}>{label}</span>
        <Select
          value={instType}
          options={InstitutionTypes}
          onChange={handleInstitutionTypeChange}
          theme={SelectTheme.LightGrey}
          placeholder={"Choose an Institution Type"}
        />
      </label>
    );
  };

  const renderStakeholder = () => {
    const label = "Stakeholder";
    return (
      <label className={EditManagerFormClassName.Input}>
        <span className={EditManagerFormClassName.Label}>{label}</span>
        <Checkbox onChange={handleIsStakeholderChange} checked={isStakeholder} id={"isStakeholder"} />
      </label>
    );
  };

  const renderActivist = () => {
    const label = "Activist";
    return (
      <label className={EditManagerFormClassName.Input}>
        <span className={EditManagerFormClassName.Label}>{label}</span>
        <Checkbox onChange={handleIsActivistChange} checked={activist} id={"isActivist"} />
      </label>
    );
  };

  const renderCountry = () => {
    const label = "Country";
    return (
      <label className={EditManagerFormClassName.Input}>
        <span className={EditManagerFormClassName.Label}>{label}</span>
        <Select
          value={{ countryCd, countryName }}
          labelKey="countryName"
          valueKey="countryName"
          options={props.countries}
          onChange={handleCountryChange}
          theme={SelectTheme.LightGrey}
          placeholder={"Choose a Country"}
        />
      </label>
    );
  };

  const renderCIK = () => {
    const label = "CIK";
    return (
      <label className={EditManagerFormClassName.Input}>
        <span className={EditManagerFormClassName.Label}>{label}</span>
        <Textbox
          className={AddFundsFormClassName.Textbox}
          placeholder={cik?.toString()}
          value={cik}
          onChange={handleCIKChange}
          inputType={InputType.Number}
          maxLength={10}
        />
      </label>
    );
  };

  const onSubmit = (e: SyntheticEvent) => e.preventDefault();

  return (
    <form className={EditManagerFormClassName.Base} onSubmit={onSubmit} autoComplete="off">
      <Grid>
        <GridColumn width="3-of-3">{renderInstitutionName()}</GridColumn>
        <GridColumn width="3-of-3">{renderInstitutionType()}</GridColumn>
        <GridColumn width="1-of-3">{renderTurnover()}</GridColumn>
        <GridColumn width="1-of-3">{renderStyle()}</GridColumn>
        <GridColumn width="2-of-4">{renderManagerStyle()}</GridColumn>
        <GridColumn width="1-of-4">{renderStakeholder()}</GridColumn>
        <GridColumn width="1-of-4">{renderActivist()}</GridColumn>
        <GridColumn width="2-of-3">{renderCountry()}</GridColumn>
        <GridColumn width="1-of-3">{renderCIK()}</GridColumn>
      </Grid>
    </form>
  );
};

export default EditManagerForm;
