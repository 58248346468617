import { Banner, BannerSize, BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import React from "react";
import { HomeBannerClassName } from "./HomeBanner.definition";
import "./HomeBanner.scss";

export type HomeBannerProps = BaseComponentWithChildrenProps;

const HomeBanner = (props?: HomeBannerProps): JSX.Element => {
  const renderDetails = () => {
    return <div className={HomeBannerClassName.Options}>{props?.children}</div>;
  };

  return (
    <Banner
      className={HomeBannerClassName.Base}
      badgeIcon=""
      title={"Home"}
      size={BannerSize.Thin}
      details={renderDetails()}
    />
  );
};

export default HomeBanner;
