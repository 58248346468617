import { CellClassParams } from "ag-grid-community";
import { Shareholder } from "q4-platform-common/src/models/shareholder/shareholder";
import { RoutePath } from "../../../configurations/navigation.configuration";
import { NavigationContextUrlSearchParams } from "../../../contexts";
import {
  getBanklistColumnDef,
  getTextColumnDef,
  getNumericColumnDef,
  getPercentageColumnDef,
} from "../../../utils/ag-grid/ag-grid.column-defs";
import { BanklistCellClassName } from "../components/BanklistCell/BanklistCell.definition";

export { DefaultColDef } from "../../../utils/ag-grid/ag-grid.column-defs";

const urlSearchParamsFormatter = (search: React.MutableRefObject<URLSearchParams>, data: Shareholder) =>
  search.current.set(NavigationContextUrlSearchParams.Q4_ENTITY_ID, data.Q4_ENTITY_ID);

const cellClassParamsFormatter = (cellClassParams: CellClassParams): string => {
  if (cellClassParams && cellClassParams.data) {
    if (!cellClassParams.data.Q4_MANAGER) {
      return `${BanklistCellClassName.Base} ${BanklistCellClassName.Base}--mapping`;
    }
  }
  return BanklistCellClassName.Base;
};

export const TwoDayVarianceReportColumnDef = [
  { ...getTextColumnDef("BANKLIST MANAGER", "BANKLIST_MANAGER") },
  {
    ...getBanklistColumnDef(
      "Q4 MANAGER",
      "Q4_MANAGER",
      { path: RoutePath.Manager, urlSearchParamsFormatter },
      null,
      cellClassParamsFormatter
    ),
  },
  { ...getTextColumnDef("FUND NAME", "FUND_NAME") },
  { ...getNumericColumnDef("SHARES ON [BASE (END) DATE]", "SHARES_ON") },
  { ...getNumericColumnDef("DIFFERENCE", "DIFF"), sort: "desc" },
  { ...getPercentageColumnDef("DIFFERENCE (%)", "CHANGEPER") },
];
