import { ColDef } from "ag-grid-community";
import { SuggestedPosition } from "q4-platform-common/src/models/suggestedposition/suggestedposition";
import { RoutePath } from "../../../configurations/navigation.configuration";
import { NavigationContextUrlSearchParams } from "../../../contexts";
import {
  getDateColumnDef,
  getNumericColumnDef,
  getNameColumnDef,
  getBooleanColumnDef,
} from "../../../utils/ag-grid/ag-grid.column-defs";
import { BooleanCellClassName } from "../../root/components/Cell/BooleanCell/BooleanCell.definition";
import { NameCellClassName } from "../../root/components/Cell/NameCell/NameCell.definition";

export { DefaultColDef } from "../../../utils/ag-grid/ag-grid.column-defs";

const urlSearchParamsFormatter = (search: React.MutableRefObject<URLSearchParams>, data: Record<string, string>) =>
  search.current.set(NavigationContextUrlSearchParams.Q4_ENTITY_ID, data.Q4_ENTITY_ID);

const cellClassParamsFormatter = (): string => NameCellClassName.Base;
const FTD_NOTE = "EYE - Fund";

export const getTwoDayVarianceReportColumnDef = (
  onReviewCheck: (data: SuggestedPosition) => Promise<void>
): Array<ColDef> => {
  return [
    {
      ...getBooleanColumnDef(
        "REVIEWED",
        "REVIEWED",
        {
          onChange: (_: boolean, data: SuggestedPosition) => {
            onReviewCheck(data);
          },
        },
        null,
        () => BooleanCellClassName.Centered
      ),
    },
    {
      ...getNameColumnDef(
        "MANAGER",
        "INST_NAME",
        { path: RoutePath.Manager, urlSearchParamsFormatter, preview: true },
        null,
        cellClassParamsFormatter,
        "nameCell"
      ),
    },
    { ...getDateColumnDef("CURRENT DATE", "CUR_DATE") },
    { ...getNumericColumnDef("CURRENT POSITION", "CUR_POSITION") },
    { ...getDateColumnDef("SUGGESTED DATE", "SUGGESTED_DATE") },
    { ...getNumericColumnDef("SUGGESTED POSITION", "SUGGESTED_POSITION", { note: FTD_NOTE }, null, null, "positionCell") },
    { ...getNumericColumnDef("DIFFERENCE", "DIFF_POSITION"), initialSort: "desc" },
    { ...getNumericColumnDef("CHANGE (%)", "CHANGE_PERCENT") },
    { ...getNumericColumnDef("13F QUANTITY", "13F_QUANTITY") },
    { ...getNumericColumnDef("FUND TO DATE (FTD)", "FTD") },
  ];
};
