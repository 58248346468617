import { NotificationService } from "@q4/nimbus-ui";
import moment, { Moment } from "moment";
import { ReportConfig } from "q4-platform-common/src/models/report/report";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import conf from "../../config";
import { REQUEST_CANCELED } from "../../services/api/pollingApi.service";
import ReportService from "../../services/report/ReportService";
import { apiDateFormatter } from "../../utils/date/dateUtils";
import { TickerContext } from "../ticker";
import { ReportContextState, ReportProviderProps } from "./report.definition";

export const ReportContext = createContext<Partial<ReportContextState>>({});

export const ReportProvider = (props: ReportProviderProps): JSX.Element => {
  const { ticker, setTickerSelectorDisabled } = useContext(TickerContext);
  const [viewMounted, setViewMounted] = useState(false);
  const notificationService = useRef(new NotificationService());
  const maxWaitTime = conf.pollingTimeout; // The maximum amount of time to wait for a Report to generate (5min)

  const getModalMessage = (status: string) => {
    /**
     * Gets a message for the csv download modal
     */
    const messages: Record<string, JSX.Element> = {
      loading: (
        <p>
          Please wait while we generate your CSV report.
          <br></br>
          Please feel free to go get a cup of coffee ☕
        </p>
      ),
      download: <p>Click Download .CSV do get your CSV report.</p>,
      error: <p>An error occurred while getting the report! Please Try Again.</p>,
    } as const;
    return messages[status];
  };

  /* 
  useStateIfMounted prevents - Warning:
  Can't perform a React state update on an unmounted component.
  This is a no-op, but it indicates a memory leak in your application.
  To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
  */
  // Looks like this area is not a big issue for the above...? Switching a number of
  // this over to useState as useStateIfMounted messes up useEffects
  // TODO Clean this up when we get rid of the old reporing system completly
  const [granularity, setGranularity] = useState("weekly"); // weekly, monthly, quarterly
  const [q4StockId, setQ4StockId] = useState(null);
  const [baseDate, setBaseDate] = useState(moment().subtract(1, "months").endOf("month"));
  const [varianceDates, setVarianceDates] = useState([]);
  const [enableFunds, setEnableFunds] = useState(false);
  const [fundThreshold, setFundThreshold] = useState(0);
  const [changeColumns, setChangeColumns] = useState(false);
  const [csvData, setCsvData] = useState("");
  const [csvLoading, setCsvLoading] = useState(false);
  const [csvDownloaded, setCsvDownloaded] = useState(true); // There is no csv to download yet so its true
  const [downloadError, setDownloadError] = useState(true);
  const [csvLoadingPercent, setCsvLoadingPercent] = useState(0);
  const [modalMessage, setModalMessage] = useState(getModalMessage("loading"));
  const [tickerExchange, setTickerExchange] = useState("");
  const [tickerSymbol, setTickerSymbol] = useState("");

  useEffect(() => {
    if (window.location.pathname === "/report") {
      setTickerSelectorDisabled(!ticker || csvLoading);
    }
  }, [ticker, csvLoading, setTickerSelectorDisabled]);

  const reportService = useMemo(() => new ReportService(maxWaitTime), [maxWaitTime]);

  function cancelReportGeneration(): void {
    reportService.cancelReportGeneration();
    setCsvDownloaded(true);
  }

  /**
   * Gets the dates that are valid to select from for a report based on current context
   */
  const getValidDates = useCallback(
    (date: Date): boolean => {
      const dateToCheck = moment(date);
      if (granularity == "weekly") {
        return dateToCheck > moment() || !moment(dateToCheck).startOf("isoWeek").isSame(dateToCheck, "day");
      } else if (granularity == "monthly") {
        return dateToCheck > moment() || !moment(dateToCheck).endOf("month").isSame(dateToCheck, "day");
      } else if (granularity == "quarterly") {
        return dateToCheck > moment() || !moment(dateToCheck).endOf("quarter").isSame(dateToCheck, "day");
      }
      return dateToCheck > moment() || false;
    },
    [granularity]
  );

  /**
   * Starts the report generation based on the current state of the Report Context.
   * If the parameters of this function are set it will cause this function to run the
   * legacy report functions in the database using the legacy GET api. Otherwise, it will
   * generate a report config and request the data through the new post api
   * @param granularityParam [DEPRECATED] The granularity to get the report at
   * @param baseDateParam [DEPRECATED] The basedate the report is for
   */
  async function generateReport(granularityParam?: string, baseDateParam?: Moment) {
    setCsvLoading(true);
    setCsvDownloaded(false);
    setDownloadError(false);
    setModalMessage(getModalMessage("loading"));
    setCsvLoadingPercent(0);

    const stockId = ticker.Q4_SEC_ID;
    setQ4StockId(stockId);
    setTickerSymbol(ticker.SYMBOL);
    setTickerExchange(ticker.EXCHANGE_NAME);

    // Determine if we are running old or new here based on if params are set
    let config = null;
    if (granularityParam && baseDateParam) {
      setGranularity(granularityParam);
      setBaseDate(baseDateParam);
    } else {
      config = generateReportConfig(stockId);
    }

    const startTime = Date.now();
    const loader = setInterval(() => {
      const currentTime = Date.now();
      const completion = Math.floor(((currentTime - startTime) / maxWaitTime) * 90); // Load to 90% before timeout
      setCsvLoadingPercent(completion);
    }, 50);
    try {
      // If we have a report config use the new detailedReport api otherwise use the original
      let result = null;
      if (config) {
        result = await reportService.generateDetailedReport(config);
      } else {
        result = await reportService.generateReport(stockId, granularityParam, baseDateParam);
      }
      if (result.success && result.data) {
        setCsvLoadingPercent(100);
        setCsvData(result.data.toString());
        notificationService.current.info("Report generation complete, your report is ready for download");
        setModalMessage(getModalMessage("download"));
      } else {
        setCsvLoadingPercent(0);
        console.warn(result.message);
        if (result.message !== REQUEST_CANCELED) {
          notificationService.current.error(`Report generation failed: ${result.message}`);
          setDownloadError(true);
          setModalMessage(getModalMessage("error"));
        } else {
          setCsvDownloaded(true);
        }
      }
    } catch (error) {
      notificationService.current.error(`Report generation failed: ${error}`);
      setCsvDownloaded(true);
      setDownloadError(true);
      setModalMessage(getModalMessage("error"));
    } finally {
      clearInterval(loader);
      setCsvLoading(false);
    }
  }

  /**
   * Convert this context to a ReportConfig that can be used to call the detailed report api
   * @param stockId The stock id to generate a report config for
   * @returns a ReportConfig object that can be sent ot the API based on the current context
   */
  function generateReportConfig(stockId: string): ReportConfig {
    let localGranularity = "daily";
    if (granularity == "weekly") {
      localGranularity = "weekly";
    } else if (
      granularity == "quarterly" ||
      (baseDate.isSame(moment(baseDate).endOf("quarter"), "day") &&
        varianceDates.every((d) => d.isSame(moment(d).endOf("quarter"), "day")))
    ) {
      localGranularity = "quarterly";
    } else if (
      granularity == "monthly" ||
      (baseDate.isSame(moment(baseDate).endOf("month"), "day") &&
        varianceDates.every((d) => d.isSame(moment(d).endOf("month"), "day")))
    ) {
      localGranularity = "monthly";
    }

    return {
      q4StockId: stockId,
      baseDate: apiDateFormatter(baseDate),
      varianceDates: granularity == "weekly" ? undefined : varianceDates.map((d) => apiDateFormatter(d)),
      enableFunds: enableFunds ? enableFunds : false,
      fundThreshold: fundThreshold ? fundThreshold : 0,
      changeColumns: changeColumns ? changeColumns : false,
      granularity: localGranularity,
    };
  }

  /**
   * Update the variance dates based on the baseDate for specific granularities
   */
  const updateVarianceDates = useCallback(() => {
    if (granularity == "monthly") {
      let monthlyVarDates = [
        moment(baseDate).subtract(1, "months").endOf("month"),
        moment(baseDate).subtract(1, "quarters").endOf("quarter"),
        moment(baseDate).subtract(2, "quarters").endOf("quarter"),
        moment(baseDate).subtract(3, "quarters").endOf("quarter"),
        moment(baseDate).subtract(4, "quarters").endOf("quarter"),
        moment(baseDate).subtract(5, "quarters").endOf("quarter"),
      ];
      if (monthlyVarDates[0].diff(monthlyVarDates[1], "days") == 0) {
        // Get rid of potential duplicate if month and quarter are the same for first two variances
        monthlyVarDates = monthlyVarDates.slice(1, monthlyVarDates.length);
      }
      monthlyVarDates = monthlyVarDates.slice(0, 5); // Truncate to max 5
      setVarianceDates(monthlyVarDates);
      setEnableFunds(true);
      setChangeColumns(false);
      setFundThreshold(0);
    } else if (granularity == "quarterly") {
      setVarianceDates([
        moment(baseDate).subtract(1, "quarters").endOf("quarter"),
        moment(baseDate).subtract(2, "quarters").endOf("quarter"),
        moment(baseDate).subtract(3, "quarters").endOf("quarter"),
        moment(baseDate).subtract(4, "quarters").endOf("quarter"),
        moment(baseDate).subtract(5, "quarters").endOf("quarter"),
      ]);
      setEnableFunds(false);
      setChangeColumns(false);
      setFundThreshold(0);
    }
  }, [baseDate, granularity]);

  /**
   * Update the baseDate based on specific granularity changes
   */
  const updateBaseDate = useCallback(() => {
    if (granularity == "weekly") {
      setBaseDate(moment().startOf("isoWeek"));
    } else if (granularity == "monthly") {
      setBaseDate(moment().subtract(1, "months").endOf("month"));
    } else if (granularity == "quarterly") {
      setBaseDate(moment().subtract(1, "quarters").endOf("quarter"));
    }
  }, [granularity]);

  // Effects to drive granularity and basedate updates
  useEffect(() => {
    updateBaseDate();
  }, [granularity, updateBaseDate]);

  useEffect(() => {
    updateVarianceDates();
  }, [baseDate, granularity, updateVarianceDates]);

  return (
    <ReportContext.Provider
      value={{
        viewMounted,
        csvData,
        csvLoading,
        csvDownloaded,
        csvLoadingPercent,
        downloadError,
        granularity,
        baseDate,
        varianceDates,
        enableFunds,
        fundThreshold,
        changeColumns,
        q4StockId,
        modalMessage,
        tickerExchange,
        tickerSymbol,
        setGranularity,
        setBaseDate,
        updateBaseDate,
        getValidDates,
        setVarianceDates,
        updateVarianceDates,
        setEnableFunds,
        setFundThreshold,
        setChangeColumns,
        setViewMounted,
        setCsvLoadingPercent,
        setDownloadError,
        cancelReportGeneration,
        setCsvLoading,
        setCsvDownloaded,
        generateReport,
        setCsvData,
        getModalMessage,
        setModalMessage,
        setTickerExchange,
      }}
    >
      {props.children}
    </ReportContext.Provider>
  );
};
