"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestJobType = void 0;
var RequestJobType;
(function (RequestJobType) {
    RequestJobType["StudioEventCreate"] = "studioEventCreate";
    RequestJobType["StudioEventUpdate"] = "studioEventUpdate";
    RequestJobType["StudioEventRemove"] = "studioEventRemove";
    RequestJobType["StudioPresentationCreate"] = "studioPresentationCreate";
    RequestJobType["StudioPresentationUpdate"] = "studioPresentationUpdate";
    RequestJobType["StudioPresentationRemove"] = "studioPresentationRemove";
    RequestJobType["StudioNewsCreate"] = "studioNewsCreate";
    RequestJobType["StudioNewsUpdate"] = "studioNewsUpdate";
    RequestJobType["StudioNewsRemove"] = "studioNewsRemove";
    RequestJobType["StudioPersonCreate"] = "studioPersonCreate";
    RequestJobType["StudioPersonUpdate"] = "studioPersonUpdate";
    RequestJobType["StudioPersonRemove"] = "studioPersonRemove";
    RequestJobType["StudioGovernanceCreate"] = "studioGovernanceCreate";
    RequestJobType["StudioGovernanceUpdate"] = "studioGovernanceUpdate";
    RequestJobType["StudioGovernanceRemove"] = "studioGovernanceRemove";
    RequestJobType["StudioJobPostingCreate"] = "studioJobPostingCreate";
    RequestJobType["StudioJobPostingUpdate"] = "studioJobPostingUpdate";
    RequestJobType["StudioJobPostingRemove"] = "studioJobPostingRemove";
    RequestJobType["StudioDividendsCreate"] = "studioDividendsCreate";
    RequestJobType["StudioDividendsUpdate"] = "studioDividendsUpdate";
    RequestJobType["StudioDividendsRemove"] = "studioDividendsRemove";
    RequestJobType["StudioFAQCreate"] = "studioFAQCreate";
    RequestJobType["StudioFAQUpdate"] = "studioFAQUpdate";
    RequestJobType["StudioFAQRemove"] = "studioFAQRemove";
    RequestJobType["StudioAnalystCoverageCreate"] = "studioAnalystCoverageCreate";
    RequestJobType["StudioAnalystCoverageUpdate"] = "studioAnalystCoverageUpdate";
    RequestJobType["StudioAnalystCoverageRemove"] = "studioAnalystCoverageRemove";
    RequestJobType["StudioOtherSubmit"] = "studioOtherSubmit";
    RequestJobType["BillingSubmit"] = "billingSubmit";
    RequestJobType["AccountSubmit"] = "accountSubmit";
    RequestJobType["GeneralQuestionSubmit"] = "generalQuestionSubmit";
})(RequestJobType = exports.RequestJobType || (exports.RequestJobType = {}));
