import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface TrashButtonProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  disabled?: boolean;
  onClick: () => void;
}

export enum TrashButtonClassName {
  Base = "trash-button",
}
