import { isEmpty, isNil, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import localForage from "localforage";
import config from "../../config";
import { ApiMethod, ApiResponse, ContentType, OfflineApiServiceKey, ResponseCode, ResponseCodeKey } from "./api.definition";

export default class ApiService {
  constructor() {
    localForage.config({
      driver: localForage.LOCALSTORAGE,
      name: "offline",
      storeName: "keyvaluepairs",
    });
  }

  public get<TResponse>(relativeUrl: string, offlineApiServiceKey?: OfflineApiServiceKey): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<void, TResponse>(relativeUrl, ApiMethod.Get, null, ContentType.Json, offlineApiServiceKey);
  }

  public post<TBody, TResponse = TBody>(
    relativeUrl: string,
    data: TBody,
    contentType = ContentType.Json
  ): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<TBody, TResponse>(relativeUrl, ApiMethod.Post, data, contentType);
  }

  public put<TBody, TResponse = TBody>(
    relativeUrl: string,
    data: TBody,
    contentType = ContentType.Json
  ): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<TBody, TResponse>(relativeUrl, ApiMethod.Put, data, contentType);
  }

  public patch<TBody, TResponse = TBody>(
    relativeUrl: string,
    data: TBody,
    contentType = ContentType.Json
  ): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<TBody, TResponse>(relativeUrl, ApiMethod.Patch, data, contentType);
  }

  public delete<TBody, TResponse = TBody>(relativeUrl: string, data?: TBody): Promise<ApiResponse<TResponse>> {
    return this.makeRequest<TBody, TResponse>(relativeUrl, ApiMethod.Delete, data);
  }

  public requestBlob<TBody>(relativeUrl: string, data?: TBody, contentType = ContentType.Json): Promise<ApiResponse<Blob>> {
    const method = ApiMethod.Post;
    const authToken = this.getAuthToken();

    if (!this.isAuthorized(authToken)) {
      return Promise.resolve(this.getUnauthorized());
    }

    return this.request<TBody>(`${config.api.url}${relativeUrl}`, method, contentType, data, authToken, {
      headers: { Accept: ContentType.Pdf },
    })
      .then((response) => this.inspectStatusCode(response))
      .then((response) => response.blob())
      .then((blob) => {
        if ("type" in blob) {
          return new ApiResponse({ success: true, data: blob });
        }
        throw new Error("Unknown file returned.");
      })
      .catch((e: Error) => {
        const { message } = e;
        return new ApiResponse<Blob>({ success: false, message });
      });
  }

  public getOfflineData<T>(key: OfflineApiServiceKey): Promise<T> {
    if (isNullOrWhiteSpace(key)) return Promise.resolve(null);

    return localForage.getItem(key);
  }

  makeExternalRequest<TBody>(
    absolutePath: string,
    method: ApiMethod,
    data?: TBody,
    contentType: ContentType | File["type"] = ContentType.Json
  ): Promise<ApiResponse<never>> {
    return this.request<TBody>(`${absolutePath}`, method, contentType, data)
      .then((response) => this.inspectStatusCode(response))
      .then((response) => {
        if ([ResponseCode.Ok, ResponseCode.OkNoContent, ResponseCode.Accepted].includes(response.status)) {
          return new ApiResponse<never>({ success: true });
        }
        throw new Error(`Failed to upload: ${response.status}`);
      })
      .catch((e: Error) => {
        const { message } = e;
        return new ApiResponse<never>({ success: false, message });
      });
  }

  private isAuthorized(authToken: string): boolean {
    return !isNullOrWhiteSpace(authToken);
  }

  private getUnauthorized<TResponse>(): ApiResponse<TResponse> {
    return new ApiResponse<TResponse>({ success: false, message: "Unauthorized" });
  }

  private async makeRequest<TBody, TResponse = TBody>(
    relativeUrl: string,
    method: ApiMethod,
    payload?: TBody,
    contentType = ContentType.Json,
    offlineApiServiceKey?: OfflineApiServiceKey
  ): Promise<ApiResponse<TResponse>> {
    const authToken = this.getAuthToken();

    if (!this.isAuthorized(authToken)) {
      return Promise.resolve(this.getUnauthorized());
    }

    const useOffline = !isNullOrWhiteSpace(offlineApiServiceKey);
    try {
      const response = await this.request<TBody>(`${config.api.url}${relativeUrl}`, method, contentType, payload, authToken);
      this.inspectStatusCode(response);
      const status = response?.status;

      if (status == 200) {
        const json = await response
          .json()
          .then((x) => new ApiResponse<TResponse>({ ...x, status, isDeleted: method === ApiMethod.Delete }));

        if ("success" in json) {
          if (useOffline) {
            localForage.setItem(offlineApiServiceKey, { ...json, offline: true });
          }

          return this.inspectForError(json);
        }
      } else {
        return { success: true, status: response?.status };
      }

      throw new Error("Unknown data object returned.");
    } catch (error) {
      const message = error;

      if (useOffline) {
        const offlineResponse = await this.getOfflineData<ApiResponse<TResponse>>(offlineApiServiceKey);

        if (!isEmpty(offlineResponse)) {
          console.error(message);
          return offlineResponse;
        }
      }

      return new ApiResponse<TResponse>({ success: false, message: message.toString() });
    }
  }

  private request<T>(
    apiPath: string,
    method: ApiMethod,
    contentType: string,
    payload?: T,
    authToken?: string,
    overrideOptions?: RequestInit
  ): Promise<Response> {
    const defaultHeaders = {
      "Content-Type": contentType,
      "Authorization": `Bearer ${authToken}`,
    };

    if (isEmpty(authToken)) {
      delete defaultHeaders["Authorization"];
    }

    if (contentType === ContentType.FormData) {
      delete defaultHeaders["Content-Type"];
    }

    const body = !isEmpty(payload) && contentType === ContentType.Json ? JSON.stringify(payload) : payload;

    const { headers: extraHeaders, ...extraOptions } = overrideOptions || {};
    const options: RequestInit = {
      method,
      body: body as BodyInit,
      ...extraOptions,
      headers: {
        ...defaultHeaders,
        ...extraHeaders,
      },
    };

    return fetch(apiPath, options);
  }

  private getAuthToken(): string {
    const token = localStorage.getItem(config.auth0.storageKey);

    if (isNullOrWhiteSpace(token) || token === "undefined") return null;

    return token;
  }

  private inspectStatusCode(response: Response): Response {
    const { status } = response;

    if ([ResponseCode.Ok, ResponseCode.OkNoContent, ResponseCode.Accepted].includes(status)) return response;
    if (Object.values(ResponseCode).includes(status)) {
      throw new Error(ResponseCodeKey[status]);
    }
    throw new Error(`An error occurred: Status Code(${status})`);
  }

  private inspectForError = <T>(response: ApiResponse<T>): ApiResponse<T> => {
    if (!isNil(response)) {
      const { message, success } = response;
      !success && console.error(`API error: ${message}`);
      return response;
    } else {
      const errorMessage = "An error occurred";
      throw new Error(errorMessage);
    }
  };
}
