import moment from "moment";
import React from "react";
import { DateCellProps, DateCellClassName } from "./DateCell.definition";
import "./DateCell.scss";

export interface Params<T> {
  params: T;
}

const DateCell = (props: DateCellProps): React.ReactFragment => {
  const { value } = props;
  const formattedDate =
    value && typeof value === "string" && value.trim().length > 0 ? moment(value.trim()).format("MM/DD/YYYY") : "";
  return <div className={DateCellClassName.Base}>{formattedDate}</div>;
};

export default DateCell;
