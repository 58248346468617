import { NotificationService } from "@q4/nimbus-ui";
import moment from "moment";
import { CustodianMovement } from "q4-platform-common/src/models/custodian/custodian";
import { Shareholder } from "q4-platform-common/src/models/shareholder/shareholder";
import { ShareholderMovement } from "q4-platform-common/src/models/shareholder/shareholderMovement";
import React, { createContext, useContext, useEffect, useMemo, useState, useRef, useCallback } from "react";
import { TickerContext } from "..";
import CustodianService from "../../services/custodian/CustodianService";
import ShareholderService from "../../services/shareholder/ShareholderService";
import StockDataService from "../../services/stockData/StockDataService";
import { RefetchContext, REFETCH_SCOPE } from "../refetch";
import { HomeContextState, HomeProviderProps } from "./home.definition";

export const HomeContext = createContext<Partial<HomeContextState>>({});

export const HomeProvider = (props: HomeProviderProps): JSX.Element => {
  const [shareholderData, setShareholderData] = useState<Array<Shareholder>>([]);
  const [shareholderDataLoading, setShareholderDataLoading] = useState(false);
  const [shareholderMovementData, setShareholderMovementData] = useState<Array<ShareholderMovement>>([]);
  const [shareholderMovementDataLoading, setShareholderMovementDataLoading] = useState(false);
  const [custodianMovementData, setCustodianMovementData] = useState<Array<CustodianMovement>>([]);
  const [custodianMovementDataLoading, setCustodianMovementDataLoading] = useState(true);
  const [homeStockPriceActivityData, setHomeStockPriceActivityData] = useState([]);
  const [homeStockPriceActivityDataLoading, setHomeStockPriceActivityDataLoading] = useState(true);
  const [showStockPriceGrid, setShowStockPriceGrid] = useState(true);
  const [showTopDTCActivityGrid, setShowTopDTCActivityGrid] = useState(true);
  const [showTopNetActivityGrid, setShowTopNetActivityGrid] = useState(true);
  const [showInstitutionalHolderGrid, setShowInstitutionalHolderGrid] = useState(true);
  const [viewMounted, setViewMounted] = useState(false);
  const { ticker, setTickerSelectorDisabled } = useContext(TickerContext);
  const { addRefetchHook } = useContext(RefetchContext);
  const shareholderService = useMemo(() => new ShareholderService(), []);
  const custodianService = useMemo(() => new CustodianService(), []);
  const stockDataService = useMemo(() => new StockDataService(), []);
  const notificationService = useRef(new NotificationService());
  const prevTicker = useRef(null);

  useEffect(() => {
    if (window.location.pathname === "/") {
      setTickerSelectorDisabled(!ticker || shareholderDataLoading);
    }
  }, [ticker, shareholderDataLoading, setTickerSelectorDisabled]);

  const fetchShareholderData = useCallback(async () => {
    if (ticker) {
      const q4SecurityId = ticker.Q4_SEC_ID;
      setShareholderDataLoading(true);
      const result = await shareholderService.getTopShareholderData(q4SecurityId);
      setShareholderDataLoading(false);
      if (result.success) {
        setShareholderData(result.data);
      } else if (!result.data) {
        setShareholderData([]);
        console.warn(result.message);
        notificationService.current.warn("No ShareHolder data was found");
      } else {
        notificationService.current.error(`ShareHolder failed ${result.message}`);
        console.warn(result.message ? result.message : "No ShareHolder Data returned");
      }
    }
  }, [ticker, shareholderService]);

  addRefetchHook(REFETCH_SCOPE.MANAGER_TRANSACTIONS, "home.fetchShareholderData", fetchShareholderData);

  const fetchShareholderMovementData = useCallback(
    async (weeks = 4, top = 5) => {
      const q4SecurityId = ticker.Q4_SEC_ID;
      setShareholderMovementDataLoading(true);
      setShareholderMovementData(null);
      const result = await shareholderService.getShareholderMovement(q4SecurityId, weeks, top);
      setShareholderMovementDataLoading(false);
      if (result.success) {
        setShareholderMovementData(result.data);
      } else if (!result.data) {
        setShareholderMovementData([]);
        console.warn(result.message);
        notificationService.current.warn(`No Shareholder Movement data was found: ${result.message}`);
      } else {
        notificationService.current.error(`Shareholder Movement failed ${result.message}`);
        console.warn(result.message ? result.message : "No Shareholder Movement Data returned");
      }
    },
    [ticker, shareholderService, setShareholderMovementData]
  );

  const fetchCustodianMovementData = useCallback(
    async (weeks = 4) => {
      const q4SecurityId = ticker.Q4_SEC_ID;
      setCustodianMovementDataLoading(true);
      setCustodianMovementData(null);
      // Calculate the variance date from the base date by substracting number of weeks using moment
      // const varianceDate = moment().subtract(weeks, "weeks").format("YYYY-MM-DD");
      const result = await custodianService.getCustodianMovement(q4SecurityId, null, null, weeks);
      setCustodianMovementDataLoading(false);
      if (result.success) {
        setCustodianMovementData(result.data);
      } else if (!result.data) {
        setCustodianMovementData([]);
        console.warn(result.message);
        notificationService.current.warn(`No Custodian Movement data was found: ${result.message}`);
      } else {
        notificationService.current.error(`Custodian Movement failed ${result.message}`);
        console.warn(result.message ? result.message : "No Custodian Movement Data returned");
      }
    },
    [ticker, custodianService, setCustodianMovementData]
  );

  const fetchHomeStockPriceActivity = useCallback(
    async (baseDate) => {
      if (viewMounted && ticker && baseDate) {
        const { Q4_TICKER_ID } = ticker;
        setHomeStockPriceActivityDataLoading(true);
        const result = await stockDataService.getStockSummary(
          Q4_TICKER_ID,
          `${baseDate.year()}-${baseDate.month() + 1}-${baseDate.date()}`.toString(),
          28
        );
        setHomeStockPriceActivityDataLoading(false);
        if (result.success && result.data && result.data.length > 0) {
          setHomeStockPriceActivityData(result.data);
        } else {
          setHomeStockPriceActivityData([]);
          result.success
            ? notificationService.current.warn("No Home Stock Summary was found")
            : notificationService.current.error(`Home Stock Summary failed ${result.message}`);
          console.warn(result.message ? result.message : "No Home Stock Summary Data returned");
        }
      }
    },
    [viewMounted, ticker, stockDataService]
  );

  useEffect(() => {
    // Only load data when mounted and we are updating the view (starts null on first load)
    if (viewMounted && ticker != prevTicker.current) {
      prevTicker.current = ticker;
      fetchShareholderData();
      fetchCustodianMovementData();
      fetchShareholderMovementData();
      fetchHomeStockPriceActivity(moment());
    }
  }, [
    viewMounted,
    ticker,
    fetchShareholderData,
    fetchCustodianMovementData,
    fetchHomeStockPriceActivity,
    fetchShareholderMovementData,
  ]);

  const value = {
    shareholderData,
    shareholderDataLoading,
    shareholderMovementData,
    shareholderMovementDataLoading,
    custodianMovementData,
    custodianMovementDataLoading,
    homeStockPriceActivityData,
    homeStockPriceActivityDataLoading,
    showStockPriceGrid,
    showTopDTCActivityGrid,
    showTopNetActivityGrid,
    showInstitutionalHolderGrid,
    setViewMounted,
    fetchShareholderData,
    fetchShareholderMovementData,
    fetchCustodianMovementData,
    setCustodianMovementData,
    setShowStockPriceGrid,
    setShowTopDTCActivityGrid,
    setShowTopNetActivityGrid,
    setShowInstitutionalHolderGrid,
  };
  return <HomeContext.Provider value={value}>{props.children}</HomeContext.Provider>;
};
