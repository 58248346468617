import { BaseComponentProps, IdModelBase, PopoverIdModel, SearchIdModel } from "@q4/nimbus-ui";
import { Search } from "q4-platform-common/src/models/search/search";
import { Key } from "react";

export interface SearchInputProps extends BaseComponentProps {
  modal?: boolean;
  popover?: boolean;
  searchType?: string;
  selectionCallback?: (search: Search) => void;
  defaultEntityType?: string;
}

export enum SearchInputClassNames {
  Base = "svl-search",
  BasePopoverModifier = "svl-search--popover",
  List = "svl-search_list",
  ListWithLoadingModifier = "svl-search_list--loading",
  ListWithPopoverModifier = "svl-search_list--popover",
  ListOptionWithLoadingModifier = "svl-search-list_option--loading",
}
export class SearchInputIdModel extends IdModelBase {
  search: SearchIdModel;
  popover: PopoverIdModel;

  constructor(id: string, index?: Key, postfix?: string) {
    super(id, index, postfix);

    this.search = new SearchIdModel(`${this.id}Search`);
    this.popover = new PopoverIdModel(`${this.id}Popover`);
  }
}
