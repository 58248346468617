import {
  CellClassParams,
  ColDef,
  HeaderCheckboxSelectionCallbackParams,
  ValueFormatterFunc,
  ValueFormatterParams,
} from "ag-grid-community";
import { SurveillanceGridClassName } from "../../views/root/components/SurveillanceGrid/SurveillanceGrid.definition";
import { formatNumberWithCommas, formatNumberAsCurrency, formatNumberAsPercentage } from "./ag-grid.value-formatters";

export type CellStyleFormatter = (params: CellClassParams, field: string) => Record<string, unknown>;
export type CellClassFormatter = (cellClassParams?: CellClassParams) => string;

export const isFirstColumn = (params: HeaderCheckboxSelectionCallbackParams): boolean => {
  const displayedColumns = params.columnApi.getAllDisplayedColumns();
  return displayedColumns[0] === params.column;
};

export const DefaultColDef: {
  headerCheckboxSelection: boolean;
  checkboxSelection: boolean | ((params: HeaderCheckboxSelectionCallbackParams) => boolean);
  sortingOrder: Array<string>;
} = {
  headerCheckboxSelection: false,
  checkboxSelection: false,
  sortingOrder: ["desc", "asc"],
};

export const getGenericColumnDef = (
  headerName: string,
  field: string,
  cellRendererParams?: Record<string, unknown>,
  cellStyleFormatter?: CellStyleFormatter,
  cellClass?: CellClassFormatter
): ColDef => {
  return {
    headerName,
    headerTooltip: headerName,
    field,
    cellRendererParams: cellRendererParams || {},
    ...(cellStyleFormatter && {
      cellStyle: (params: CellClassParams) => cellStyleFormatter(params, field),
    }),
    cellClass: cellClass ? () => `${SurveillanceGridClassName.Cell} ${cellClass()}` : () => SurveillanceGridClassName.Cell,
    headerClass: "surveillance-grid__header", // Can't import from views in here
    sortable: true,
  };
};

// Boolean column defs
export const getBooleanColumnDef = (
  headerName: string,
  field: string,
  cellRendererParams?: Record<string, unknown>,
  cellStyleFormatter?: CellStyleFormatter,
  cellClass?: CellClassFormatter
): ColDef => ({
  ...getGenericColumnDef(headerName, field, cellRendererParams, cellStyleFormatter, cellClass),
  cellRenderer: "booleanCell",
});

// Text column defs
export const getNameColumnDef = (
  headerName: string,
  field: string,
  cellRendererParams?: Record<string, unknown>,
  cellStyleFormatter?: CellStyleFormatter,
  cellClass?: CellClassFormatter,
  renderer?: string
): ColDef => ({
  ...getGenericColumnDef(headerName, field, cellRendererParams, cellStyleFormatter, cellClass),
  cellRenderer: renderer ? renderer : "nameCell",
});

export const getBanklistColumnDef = (
  headerName: string,
  field: string,
  cellRendererParams?: Record<string, unknown>,
  cellStyleFormatter?: CellStyleFormatter,
  cellClass?: CellClassFormatter
): ColDef => ({
  ...getGenericColumnDef(headerName, field, cellRendererParams, cellStyleFormatter, cellClass),
  cellRenderer: "banklistCell",
});

export const getTextColumnDef = (
  headerName: string,
  field: string,
  cellRendererParams?: Record<string, unknown>,
  cellStyleFormatter?: CellStyleFormatter,
  cellClass?: CellClassFormatter
): ColDef => ({
  ...getGenericColumnDef(headerName, field, cellRendererParams, cellStyleFormatter, cellClass),
});

// Numeric column defs
// ! If you provide a "cellClass" to any of the numeric column defs, you will need to redefine the properties
// ! type: "rightAligned" or type: "numericColumn" manually like below on the object returned
/*
rightAligned: {
    headerClass: 'ag-right-aligned-header',
    cellClass: 'ag-right-aligned-cell'
}
*/
export const getDateColumnDef = (
  headerName: string,
  field: string,
  cellRendererParams?: Record<string, unknown>,
  cellStyleFormatter?: CellStyleFormatter,
  cellClass?: CellClassFormatter
): ColDef => ({
  ...getGenericColumnDef(headerName, field, cellRendererParams, cellStyleFormatter, cellClass),
  cellRenderer: "dateCell",
  type: "rightAligned",
});

export const getNumericColumnDef = (
  headerName: string,
  field: string,
  cellRendererParams?: Record<string, unknown>,
  cellStyleFormatter?: CellStyleFormatter,
  cellClass?: CellClassFormatter,
  renderer?: string,
  customFormatter?: ValueFormatterFunc
): ColDef => ({
  ...getGenericColumnDef(headerName, field, cellRendererParams, cellStyleFormatter, cellClass),
  valueFormatter: customFormatter
    ? customFormatter
    : (params: ValueFormatterParams) => formatNumberWithCommas(params, field),
  cellRenderer: renderer ? renderer : null,
  type: "numericColumn",
});

export const getCurrencyColumnDef = (
  headerName: string,
  field: string,
  cellRendererParams?: Record<string, unknown>,
  cellStyleFormatter?: CellStyleFormatter,
  cellClass?: CellClassFormatter
): ColDef => ({
  ...getGenericColumnDef(headerName, field, cellRendererParams, cellStyleFormatter, cellClass),
  valueFormatter: (params: ValueFormatterParams) => formatNumberAsCurrency(params),
  type: "numericColumn",
});

export const getPercentageColumnDef = (
  headerName: string,
  field: string,
  cellRendererParams?: Record<string, unknown>,
  cellStyleFormatter?: CellStyleFormatter,
  cellClass?: CellClassFormatter
): ColDef => ({
  ...getGenericColumnDef(headerName, field, cellRendererParams, cellStyleFormatter, cellClass),
  valueFormatter: (params: ValueFormatterParams) => formatNumberAsPercentage(params, field),
  type: "percentageColumn",
});

export const getFixedColumnDef = (
  headerName: string,
  field: string,
  cellRendererParams?: Record<string, unknown>,
  cellStyleFormatter?: CellStyleFormatter,
  cellClass?: CellClassFormatter
): ColDef => ({
  ...getGenericColumnDef(headerName, field, cellRendererParams, cellStyleFormatter, cellClass),
  width: 300,
  sortable: false,
  suppressMenu: true,
  pinned: "left",
  // !The styles are defined here because they are set inline somewhere by ag-grid which would override the stylesheet
  cellStyle: {
    color: "white",
    borderColor: "#297ac3",
    backgroundColor: "#4796e0",
    textTransform: "uppercase",
    fontWeight: "normal",
  },
});

export const getCustodiansColumnDef = (
  headerName: string,
  field: string,
  cellRendererParams?: Record<string, unknown>,
  cellStyleFormatter?: CellStyleFormatter,
  cellClass?: CellClassFormatter
): ColDef => ({
  ...getGenericColumnDef(headerName, field, cellRendererParams, cellStyleFormatter, cellClass),
  cellRenderer: "custodianListCell",
});
