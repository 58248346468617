import { Button, ButtonTheme } from "@q4/nimbus-ui";
import React from "react";
import { LeftArrowButtonClassName, LeftArrowButtonProps } from "./LeftArrowButton.definition";
import "./LeftArrowButton.scss";

const LeftArrowButton = (props: LeftArrowButtonProps): JSX.Element => {
  const { disabled, onClick } = props;
  return (
    <Button
      className={LeftArrowButtonClassName.Base}
      icon={`ni-caret-${props?.direction ? props.direction : "left"}-4pt`}
      theme={ButtonTheme.Ink}
      label={""}
      disabled={disabled}
      onClick={() => {
        (document.activeElement as HTMLElement).blur();
        onClick();
      }}
    />
  );
};

export default LeftArrowButton;
