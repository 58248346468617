import "./TickerSelector.component.scss";
import { AsyncSelect, ComponentSizeModifier, SelectOption, SelectTheme } from "@q4/nimbus-ui";
import React, { useContext } from "react";
import { OptionsType } from "react-select";
import { Ticker } from "../../../../../../../src/api/models/ticker";
import { TickerContext } from "../../../../contexts";
import { HeaderClassName } from "./TickerSelector.definition";

const TickerSelector = (): JSX.Element => {
  const { ticker, tickers, tickerSelectorDisabled, setTicker } = useContext(TickerContext);
  const getTickerFormattedForUI = (t: Ticker) => {
    const { SYMBOL: symbol, EXCHANGE_NAME: exchangeName, Q4_TICKER_ID: value } = t;
    return { label: `${symbol} | ${exchangeName}`, value };
  };
  const onChange = (e: Record<string, string>) => {
    const found = tickers && e ? tickers.find((t: Ticker) => t.Q4_TICKER_ID === e.value) : false;
    if (found) {
      setTicker(found);
    }
  };
  const selectPropsOptions = tickers.map(getTickerFormattedForUI);
  const placeholder = ticker ? getTickerFormattedForUI(ticker).label : "Select a ticker";
  const asyncOptions: Array<Record<string, string>> = selectPropsOptions.map((option: Record<string, string>) => ({
    label: option.label,
    value: option.value,
  }));
  const filterOptions = (inputValue: string): OptionsType<SelectOption<Record<string, unknown>>> => {
    const filteredAsyncOptions = asyncOptions.filter((i: Record<string, string>) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    return filteredAsyncOptions as unknown as OptionsType<SelectOption<Record<string, unknown>>>;
  };
  const loadOptions = (
    inputValue: string,
    callback: (options: OptionsType<SelectOption<Record<string, unknown>>>) => void
  ): void => {
    setTimeout(() => callback(filterOptions(inputValue)), 1000);
  };
  return (
    <AsyncSelect
      key={!ticker ? "ticker-selector" : `ticker-selector-${ticker.Q4_TICKER_ID}`}
      defaultOptions
      loadOptions={loadOptions}
      onChange={onChange}
      className={HeaderClassName.Base}
      theme={SelectTheme.Steel}
      placeholder={placeholder}
      loading={false}
      disabled={tickerSelectorDisabled}
      size={ComponentSizeModifier.Compact}
    />
  );
};

export default TickerSelector;
