import { useFlags } from "launchdarkly-react-client-sdk";
import React, { memo } from "react";
import { FeatureFlag } from "../../../../config";
import { ReportToolbarContainerProps } from "./ReportToolbarContainer.definition";
import "./ReportToolbarContainer.scss";
import ReportToolbarLegacy from "./ReportToolbarLegacy/ReportToolbarLegacy";
import ReportToolbarNew from "./ReportToolbarNew/ReportToolbarNew";

const ReportToolbarContainer = (props: ReportToolbarContainerProps): JSX.Element => {
  const features = useFlags();
  const { modalVisible, setShowModal } = props;
  const renderNewReportToolbar = () => {
    return <ReportToolbarNew modalVisible={modalVisible} setShowModal={setShowModal} />;
  };
  const renderLegacyReportToolbar = () => {
    return <ReportToolbarLegacy modalVisible={modalVisible} setShowModal={setShowModal} />;
  };
  return features[FeatureFlag.ReportCustomDateControls] ? renderNewReportToolbar() : renderLegacyReportToolbar();
};

export default memo(ReportToolbarContainer);
