import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface ContactButtonProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  disabled?: boolean;
  id: string;
  onClick: () => void;
}

export enum ContactButtonClassName {
  Base = "contact-button",
}
