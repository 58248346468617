import moment from "moment";
import { FilingData, SetFilingData } from "q4-platform-common/src/models/filingData/filingData";
import { apiDateFormatter } from "../../utils/date/dateUtils";
import { ApiResponse } from "../api/api.definition";
import ApiService from "../api/api.service";

export default class FilingService {
  private readonly apiService: ApiService;
  private readonly ApiPath = "/filingData";
  constructor() {
    this.apiService = new ApiService();
  }

  async getFilingData(q4SecurityId: string): Promise<ApiResponse<Array<FilingData>>> {
    const quarterAdjustment = (moment().month() % 3) + 1;
    const lastQuarterEndDate = moment().subtract({ months: quarterAdjustment }).endOf("month");
    const date = apiDateFormatter(lastQuarterEndDate);
    const response = await this.apiService.get<Array<FilingData>>(
      `${this.ApiPath}?secId=${q4SecurityId}&filingDate=${date}`
    );
    return this.mapFilingDataResponse(response);
  }

  private mapFilingDataResponse(response: ApiResponse<Array<FilingData>>): ApiResponse<Array<FilingData>> {
    return new ApiResponse({ ...response });
  }

  async putFilingData(filings: Array<SetFilingData>): Promise<ApiResponse<[SetFilingData]>> {
    return this.apiService.put(this.ApiPath, { filings });
  }
}
