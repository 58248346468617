import { EntityMetadata } from "q4-platform-common/src/models/entity/entity";
import { AddFundTransaction, DeleteFundTransaction, FundTransaction } from "q4-platform-common/src/models/fund/fund";
import { ApiResponse } from "../api/api.definition";
import ApiService from "../api/api.service";

export default class FundService {
  private readonly apiService: ApiService;
  private readonly ApiPath = "/fund";
  constructor() {
    this.apiService = new ApiService();
  }

  async getFundMetadata(q4SecurityId: string, entityId: string): Promise<ApiResponse<Array<EntityMetadata>>> {
    const response = await this.apiService.get<Array<EntityMetadata>>(
      `${this.ApiPath}?secId=${q4SecurityId}&entityId=${entityId}`
    );
    return this.mapEntityMetadataResponse(response);
  }

  async getFundTransactionsData(q4SecurityId: string, entityId: string): Promise<ApiResponse<Array<FundTransaction>>> {
    const response = await this.apiService.get<Array<FundTransaction>>(
      `${this.ApiPath}/transactions?secId=${q4SecurityId}&entityId=${entityId}`
    );
    return this.mapFundTransactionResponse(response);
  }

  async putFundTransactionsData(data: AddFundTransaction): Promise<ApiResponse<AddFundTransaction>> {
    const response = await this.apiService.put<AddFundTransaction>(`${this.ApiPath}/transactions`, data);
    return this.mapAddFundTransactionResponse(response);
  }

  async deleteFundTransactionData(
    data: DeleteFundTransaction[],
    secId: string,
    entityId: string
  ): Promise<ApiResponse<DeleteFundTransaction>> {
    return this.apiService.delete(`${this.ApiPath}/transactions`, { data, secId, entityId });
  }

  private mapEntityMetadataResponse = (response: ApiResponse<Array<EntityMetadata>>): ApiResponse<Array<EntityMetadata>> =>
    new ApiResponse({ ...response });

  private mapFundTransactionResponse = (
    response: ApiResponse<Array<FundTransaction>>
  ): ApiResponse<Array<FundTransaction>> => new ApiResponse({ ...response });

  private mapAddFundTransactionResponse = (response: ApiResponse<AddFundTransaction>): ApiResponse<AddFundTransaction> =>
    new ApiResponse({ ...response });
}
