import { Moment } from "moment";
import { BanklistVar, Dates } from "q4-platform-common/src/models/banklist/banklist";
import { apiDateFormatter } from "../../utils/date/dateUtils";
import { ApiResponse } from "../api/api.definition";
import ApiService from "../api/api.service";

export default class BanklistService {
  private readonly apiService: ApiService;
  private readonly ApiPath = "/banklist";
  constructor() {
    this.apiService = new ApiService();
  }

  async getBanklistVariance(
    banklistId: number,
    q4StockId: string,
    varianceDate: Moment,
    baseDate: Moment
  ): Promise<ApiResponse<Array<BanklistVar>>> {
    const formattedVarianceDate = apiDateFormatter(varianceDate);
    const formattedBaseDate = apiDateFormatter(baseDate);
    const response = await this.apiService.get<Array<BanklistVar>>(
      `${this.ApiPath}/variance?banklistId=${banklistId}&stockId=${q4StockId}&baseDate=${formattedBaseDate}&varianceDate=${formattedVarianceDate}`
    );
    return new ApiResponse({ ...response });
  }

  async getBanklistDates(banklistId: number, q4SecurityId: string): Promise<ApiResponse<Dates[]>> {
    return this.apiService.get<Array<Dates>>(`${this.ApiPath}/dates?banklistId=${banklistId}&stockId=${q4SecurityId}`);
  }

  async putBanklistMapping(sourceId: number, q4EntityId: string, sourceKey: string): Promise<ApiResponse<string>> {
    return this.apiService.put(
      `${this.ApiPath}/mapping?sourceId=${sourceId}&q4EntityId=${q4EntityId}&sourceKey=${encodeURIComponent(sourceKey)}`,
      ""
    );
  }

  async putBanklist(banklistId: number, file: string): Promise<ApiResponse<string>> {
    return this.apiService.put(`${this.ApiPath}?banklistId=${banklistId}`, { excelB64: file });
  }
}
