import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface BooleanCellProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  value: boolean;
  data: Record<string, unknown>;
  onChange: (newSate: boolean, data: Record<string, unknown>) => Promise<void>;
}

export enum BooleanCellClassName {
  Base = "boolean-cell",
  Centered = "boolean-cell-centered",
}
