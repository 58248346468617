import { CellClassParams, ValueFormatterParams } from "ag-grid-community";
import { CustodianWeekly } from "q4-platform-common/src/models/custodian/custodian";
import { RoutePath } from "../../../configurations/navigation.configuration";
import { CustodianRef, NavigationContextUrlSearchParams } from "../../../contexts";
import { colorToChangeCellStyleFormatter } from "../../../utils/ag-grid/ag-grid.cellstyle-formatters";
import {
  getDateColumnDef,
  getTextColumnDef,
  getNameColumnDef,
  getNumericColumnDef,
  getPercentageColumnDef,
  getFixedColumnDef,
} from "../../../utils/ag-grid/ag-grid.column-defs";
import { formatNumberAsCurrency, formatValueWithCommas } from "../../../utils/ag-grid/ag-grid.value-formatters";
import { NameCellClassName } from "../../root/components/Cell/NameCell/NameCell.definition";

const cellClassParamsFormatter = (): string => NameCellClassName.Base;

export { DefaultColDef } from "../../../utils/ag-grid/ag-grid.column-defs";

const urlSearchParamsFormatter = (search: React.MutableRefObject<URLSearchParams>, data: CustodianRef) => {
  search.current.set(
    NavigationContextUrlSearchParams.Q4_CUSTODIAN_ID,
    data.Q4_CUSTODIAN_ID ? data.Q4_CUSTODIAN_ID : data.Q4_ENTITY_ID
  );
};

export const DTCCOverviewColumnDefs = [
  { ...getDateColumnDef("TRADE DATE", "TRADE_DATE") },
  { ...getDateColumnDef("SETTLEMENT DATE", "SETTLEMENT_DATE") },
  { ...getTextColumnDef("CLOSE", "CLOSE") },
  { ...getTextColumnDef("CHANGE", "CHANGE", null, colorToChangeCellStyleFormatter) },
  { ...getNumericColumnDef("VOLUME", "VOLUME") },
];

const getOutsizedDateFormat = (dt: string) => `${dt} TRADE`;

export const DTCCustodiansDefs = (
  elements?: Array<CustodianWeekly>,
  outsized?: Array<string>
): Record<string, unknown>[] => {
  const colDefs = new Array(5 + (elements?.length || 0));
  colDefs[0] = { ...getTextColumnDef("ID", "DTC_ID") };
  colDefs[1] = {
    ...getNameColumnDef(
      "NAME",
      "NAME",
      { path: RoutePath.Custodian, urlSearchParamsFormatter },
      null,
      cellClassParamsFormatter
    ),
  };
  colDefs[2] = { ...getTextColumnDef("STYLE", "STYLE") };

  if (elements && elements.length > 0) {
    let colIdx = 3;
    Object.keys(elements[0])
      .filter((name) => name.includes("TRADE"))
      .sort((a, b) => a.localeCompare(b))
      .forEach((col) => {
        // TRADE columns have dynamic names and need dynamic column definitions all other columns are static
        // col: 2024-02-22 TRADE | 02-26 STLMT
        const colName = col.replace(" | ", "\n").substring(5);

        const isWKColumn = col.includes("STLMT WK");
        const isPOSColumn = col.includes("STLMT POS");

        if (isWKColumn) {
          // The TRADE STLMT WK column is the column we want to sort the grid on
          colDefs[colDefs.length - 3] = {
            ...getNumericColumnDef(colName, col, null, colorToChangeCellStyleFormatter),
            sort: "desc",
          };
        } else if (isPOSColumn) {
          //STLMT POS column should be in BLACK since it’s not calculating a change
          colDefs[colDefs.length - 4] = {
            ...getNumericColumnDef(colName, col, null),
          };
        } else {
          const headerClass = outsized.some((x) => colName.includes(getOutsizedDateFormat(x))) ? " oversized" : "";
          colDefs[colIdx] = {
            ...getNumericColumnDef(colName, col, null, colorToChangeCellStyleFormatter),
            headerClass: "surveillance-grid__header" + headerClass,
          };
          colIdx++;
        }
      });
  }

  colDefs[colDefs.length - 2] = { ...getNumericColumnDef("QTD CHG", "QTD_CHG", null, colorToChangeCellStyleFormatter) };
  colDefs[colDefs.length - 1] = {
    ...getNumericColumnDef("PRV QTR CHG", "PRV_QTR_CHG", null, colorToChangeCellStyleFormatter),
  };
  return colDefs;
};

export const DTCCTwoDayVarianceReportDefs = [
  { ...getTextColumnDef("ID", "DTC_ID") },
  {
    ...getNameColumnDef(
      "NAME",
      "NAME",
      { path: RoutePath.Custodian, urlSearchParamsFormatter },
      null,
      cellClassParamsFormatter
    ),
  },
  { ...getTextColumnDef("TYPE", "TYPE") },
  { ...getDateColumnDef("START DATE", "START_DATE") },
  { ...getDateColumnDef("END DATE", "END_DATE") },
  { ...getNumericColumnDef("VARIANCE QUANTITY", "VARIANCE_QUANTITY") },
  { ...getNumericColumnDef("BASE QUANTITY", "BASE_QUANTITY") },
  { ...getNumericColumnDef("DIFF", "DIFF", null, colorToChangeCellStyleFormatter) },
  { ...getPercentageColumnDef("DIFF (%)", "DIFFPER", null, colorToChangeCellStyleFormatter) },
];

const colorToChangeOnlyForChangeRows = (params: CellClassParams, field: string): Record<string, unknown> => {
  if (params.data["TITLE"].includes("Change")) {
    return {
      ...colorToChangeCellStyleFormatter(params, field),
    };
  }
};

const customNumberFormatStockPriceActivity = (params: ValueFormatterParams): string => {
  if (params.data["TITLE"].includes("$")) {
    return formatNumberAsCurrency(params);
  }

  if (params.data["TITLE"].toUpperCase().includes("VOLUME")) {
    return formatValueWithCommas(params);
  }

  return params.value;
};

export const getStockPriceActivityDefs = (
  stockSummaryData: Array<Record<string, unknown>>
): Array<Record<string, unknown>> => [
  { ...getFixedColumnDef("TRADE DATE", "TITLE") },
  ...stockSummaryData.map((data: Record<string, unknown>, vIndex: number) => ({
    ...getNumericColumnDef(
      data.date as string,
      `DATE_${vIndex + 1}`,
      null,
      colorToChangeOnlyForChangeRows,
      null,
      null,
      customNumberFormatStockPriceActivity
    ),
  })),
];
