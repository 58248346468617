import { Grid, GridColumn, Toolbar, ToolbarTheme } from "@q4/nimbus-ui";
import React from "react";
import DownloadButton from "../Button/DownloadButton/DownloadButton";
import ExportButton from "../Button/ExportButton/ExportButton";
import { GridToolbarClassName, GridToolbarProps } from "./GridToolbar.definition";
import "./GridToolbar.scss";

const GridToolbar = (props: GridToolbarProps): JSX.Element => {
  const {
    leftContent = "Grid Toolbar",
    leftContentWidth = "1-of-3",
    rightContent,
    rightContentWidth = "2-of-3",
    gridRef,
    showDownloadDataButton,
    showUploadDataButton,
    disabled,
    uploadData,
  } = props;
  const downloadData = () => {
    if (gridRef && gridRef.current && gridRef.current.api && gridRef.current.api.exportDataAsCsv) {
      gridRef.current.api.exportDataAsCsv();
      console.info(`${leftContent} - Calling exportDataAsCsv to generate the CSV document.`);
    } else {
      console.warn(`${leftContent} - Cannot call exportDataAsCsv because gridApi was unavailable.`);
    }
  };
  const renderUploadDataButton = () =>
    showUploadDataButton ? <ExportButton disabled={disabled} onClick={uploadData} /> : false;
  const renderDownloadDataButton = () =>
    showDownloadDataButton ? <DownloadButton disabled={disabled} onClick={downloadData} /> : false;
  return (
    <Toolbar className={GridToolbarClassName.Base} theme={ToolbarTheme.Q4Blue}>
      <Grid className={GridToolbarClassName.Grid}>
        <GridColumn className={GridToolbarClassName.LeftColumn} width={leftContentWidth}>
          {leftContent}
        </GridColumn>
        <GridColumn className={GridToolbarClassName.RightColumn} width={rightContentWidth}>
          {rightContent}
          {renderUploadDataButton()}
          {renderDownloadDataButton()}
        </GridColumn>
      </Grid>
    </Toolbar>
  );
};

export default GridToolbar;
