import React, { useCallback, useEffect, useRef, useState } from "react";
import { DragAndDropClassName, DragAndDropProps } from "./DragAndDrop.definition";
import "./DragAndDrop.scss";

const DragAndDrop = (props: DragAndDropProps): JSX.Element => {
  const [dragging, setDragging] = useState<boolean>(false);
  const [dragCounter, setDragCounter] = useState<number>(0);
  const [modifier, setModifier] = useState<string>("");
  const { children, dropMessage = "Drop here", handleDragIn, handleDragOut, handleDrag, handleDrop } = props;
  const dropRef = useRef(null);
  const dragEnter = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setModifier("dragenter");
      setDragCounter(dragCounter + 1);
      if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
        setDragging(true);
      }
      if (handleDragIn) {
        handleDragIn(event);
      }
    },
    [dragCounter, handleDragIn]
  );
  const dragLeave = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setModifier("dragleave");
      setDragCounter(dragCounter - 1);
      if (dragCounter < 0) {
        setDragging(false);
        if (handleDragOut) {
          handleDragOut(event);
        }
      }
    },
    [dragCounter, handleDragOut]
  );
  const dragOver = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setModifier("dragover");
      if (handleDrag) {
        handleDrag(event);
      }
    },
    [handleDrag]
  );
  const drop = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setModifier("drop");
      setDragging(false);
      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        if (handleDrop) {
          handleDrop(event.dataTransfer.files, event);
        }
        event.dataTransfer.clearData();
        setDragCounter(0);
      }
    },
    [handleDrop]
  );
  useEffect(() => {
    const current = dropRef.current;
    current.addEventListener("dragenter", dragEnter);
    current.addEventListener("dragleave", dragLeave);
    current.addEventListener("dragover", dragOver);
    current.addEventListener("drop", drop);
    return () => {
      current.removeEventListener("dragenter", dragEnter);
      current.removeEventListener("dragleave", dragLeave);
      current.removeEventListener("dragover", dragOver);
      current.removeEventListener("drop", drop);
    };
  }, [dragEnter, dragLeave, dragOver, drop]);
  return (
    <div
      className={
        modifier ? `${DragAndDropClassName.Base} ${DragAndDropClassName.Base}--${modifier}` : DragAndDropClassName.Base
      }
      ref={dropRef}
    >
      {dragging && (
        <div className={DragAndDropClassName.Overlay}>
          <div className={DragAndDropClassName.OverlayMessage}>
            <div>{dropMessage}</div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default DragAndDrop;
