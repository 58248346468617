import { Button, ButtonTheme } from "@q4/nimbus-ui";
import React from "react";
import { TrashButtonClassName, TrashButtonProps } from "./TrashButton.definition";
import "./TrashButton.scss";

const TrashButton = (props: TrashButtonProps): JSX.Element => {
  const { disabled, onClick } = props;
  return (
    <Button
      className={TrashButtonClassName.Base}
      icon={"q4i-trashbin-4pt"}
      theme={ButtonTheme.Rain}
      label={""}
      disabled={disabled}
      onClick={() => {
        (document.activeElement as HTMLElement).blur();
        onClick();
      }}
    />
  );
};

export default TrashButton;
