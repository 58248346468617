import { Button } from "@q4/nimbus-ui";
import { TwoDayVarianceManager } from "q4-platform-common/src/models/banklist/banklist";
import React, { SyntheticEvent, useContext } from "react";
import { NavigateOptions } from "react-router-dom";
import { BanklistContext, NavigationContext } from "../../../../contexts";
import { BanklistCellProps } from "./BanklistCell.definition";
import "./BanklistCell.scss";

export interface Params<T> {
  params: T;
}

const BanklistCell = (props: BanklistCellProps): React.ReactFragment => {
  const { setShowMapBanklistToManagerModal, setTwoDayVarianceManager } = useContext(BanklistContext);
  const { searchRef, goto } = useContext(NavigationContext);
  const { value, colDef, data } = props;
  const { cellRendererParams } = colDef;
  const { path, urlSearchParamsFormatter = null } = cellRendererParams;
  return value ? (
    <a
      onClick={(event: SyntheticEvent) => {
        event.preventDefault();
        if (urlSearchParamsFormatter) {
          urlSearchParamsFormatter(searchRef, data); // mutates searchRef
        }
        if (path) {
          goto(path, null, { state: data } as NavigateOptions, true);
        }
      }}
    >
      {value}
    </a>
  ) : (
    <Button
      icon={"q4i-add-4pt"}
      label={"Map Manager"}
      onClick={() => {
        setTwoDayVarianceManager(props.data as unknown as TwoDayVarianceManager);
        setShowMapBanklistToManagerModal(true);
      }}
    />
  );
};

export default BanklistCell;
