import moment, { Moment } from "moment";
import {
  Custodian,
  CustodianManager,
  CustodianMovement,
  CustodianMutualFund,
  CustodianTransaction,
  CustodianVariance,
  MaxDate,
  CustodianWeeklyResponse,
} from "q4-platform-common/src/models/custodian/custodian";
import { apiDateFormatter } from "../../utils/date/dateUtils";
import { ApiResponse } from "../api/api.definition";
import ApiService from "../api/api.service";

export default class CustodianService {
  private readonly apiService: ApiService;
  private readonly ApiPath = "/custodian";
  constructor() {
    this.apiService = new ApiService();
  }

  // Base Custodian
  async getCustodian(q4StockId: string, q4EntityId: string): Promise<ApiResponse<Array<Custodian>>> {
    const response = await this.apiService.get<Array<Custodian>>(
      `${this.ApiPath}?secId=${q4StockId}&entityId=${q4EntityId}`
    );
    return new ApiResponse({ ...response });
  }

  async getCustodianMetadata(custodianId: string): Promise<ApiResponse<Array<Custodian>>> {
    const response = await this.apiService.get<Array<Custodian>>(`${this.ApiPath}/metadata?custodianId=${custodianId}`);
    return new ApiResponse({ ...response });
  }

  async getCustodianTransactions(
    q4StockId: string,
    custodianId?: string
  ): Promise<ApiResponse<Array<CustodianTransaction>>> {
    const response = await this.apiService.get<Array<CustodianTransaction>>(
      `${this.ApiPath}/transactions?secId=${q4StockId}&custodianId=${custodianId}`
    );
    return new ApiResponse({ ...response });
  }

  async getCustodianManagers(q4StockId: string, custodianId?: string): Promise<ApiResponse<Array<CustodianManager>>> {
    const response = await this.apiService.get<Array<CustodianManager>>(
      `${this.ApiPath}/managers?secId=${q4StockId}&custodianId=${custodianId}`
    );
    return new ApiResponse({ ...response });
  }

  async getCustodianMutualFunds(q4StockId: string, custodianId?: string): Promise<ApiResponse<Array<CustodianMutualFund>>> {
    const response = await this.apiService.get<Array<CustodianMutualFund>>(
      `${this.ApiPath}/mutualfunds?secId=${q4StockId}&custodianId=${custodianId}`
    );
    return new ApiResponse({ ...response });
  }

  async putCustodianMapping(q4EntityId: string, custodianId: string): Promise<ApiResponse<string>> {
    return this.apiService.put(`${this.ApiPath}/mapping?entityId=${q4EntityId}&custodianId=${custodianId}`, "");
  }

  async putCustodianDTCFile(file: string): Promise<ApiResponse<string>> {
    return this.apiService.put(`${this.ApiPath}/upload`, { excelB64: file });
  }

  // Custodian Summarizations
  async getCustodianLatestDate(q4SecurityId: string): Promise<ApiResponse<MaxDate[]>> {
    return this.apiService.get<Array<MaxDate>>(`${this.ApiPath}/summary/latestDate?secId=${q4SecurityId}`);
  }

  async getCustodiansWeekly(
    q4SecurityId: string,
    baseDate?: Moment,
    days?: number
  ): Promise<ApiResponse<CustodianWeeklyResponse>> {
    const momentDate = baseDate ? baseDate : moment();
    const date = apiDateFormatter(momentDate);
    const response = await this.apiService.get<CustodianWeeklyResponse>(
      `${this.ApiPath}/summary?secId=${q4SecurityId}&baseDate=${date}&days=${days}`
    );
    return new ApiResponse({ ...response });
  }

  async getCustodiansVariance(
    stockId: string,
    varianceDate: Moment,
    baseDate: Moment
  ): Promise<ApiResponse<Array<CustodianVariance>>> {
    const formattedVarianceDate = apiDateFormatter(varianceDate);
    const formattedBaseDate = apiDateFormatter(baseDate);
    const response = await this.apiService.get<Array<CustodianVariance>>(
      `${this.ApiPath}/summary/variance?secId=${stockId}&stockId=${stockId}&varianceDate=${formattedVarianceDate}&baseDate=${formattedBaseDate}`
    );
    return new ApiResponse({ ...response });
  }

  async getCustodianMovement(
    q4StockId: string,
    varianceDate?: string,
    baseDate?: string,
    weeks?: number
  ): Promise<ApiResponse<Array<CustodianMovement>>> {
    varianceDate = varianceDate ? varianceDate : "";
    baseDate = baseDate ? baseDate : "";
    weeks = weeks ? weeks : 0;
    const response = await this.apiService.get<Array<CustodianMovement>>(
      `${this.ApiPath}/summary/movement?secId=${q4StockId}&varianceDate=${varianceDate}&baseDate=${baseDate}&weeks=${weeks}`
    );
    return new ApiResponse({ ...response });
  }
}
