import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import React, { useContext, useRef } from "react";
import { ManagerContext } from "../../../../contexts";
import { DefaultColDef } from "../../../../utils/ag-grid/ag-grid.column-defs";
import AreYouSureModal from "../../../root/components/Modal/AreYouSureModal/AreYouSureModal";
import SurveillanceGrid from "../../../root/components/SurveillanceGrid/SurveillanceGrid";
import { BaseTransactionsColumnDefs } from "../../data/AgGridDefs";
import {
  DeleteCustomTransactionsModalClassName,
  DeleteCustomTransactionsModalProps,
} from "./DeleteCustomTransactionsModal.definition";
import "./DeleteCustomTransactionsModal.scss";

const DeleteCustomTransactionsModal = (props: DeleteCustomTransactionsModalProps): JSX.Element => {
  const { selectedTransactions, deleteTransactions } = useContext(ManagerContext);
  const transactionsGridRef = useRef<AgGridReact>(null);
  const { showModal, setShowModal } = props;
  const subtitle = "Deleting these custom transaction(s) cannot be reversed.";

  return (
    <AreYouSureModal
      customClassName={DeleteCustomTransactionsModalClassName.Base}
      showModal={showModal}
      setShowModal={setShowModal}
      subtitle={subtitle}
      callback={() => deleteTransactions()}
    >
      <SurveillanceGrid
        id={"delete-custom-transactions-surveillance-grid"}
        gridRef={transactionsGridRef}
        DefaultColDef={DefaultColDef}
        ColumnDefs={BaseTransactionsColumnDefs}
        isRowSelectable={() => false}
        data={selectedTransactions}
        overlayLoadingTemplate={""}
        overlayNoRowsTemplate={""}
      />
    </AreYouSureModal>
  );
};

export default DeleteCustomTransactionsModal;
