import type { BaseComponentProps } from "@q4/nimbus-ui";
import { Moment } from "moment";

export interface ReportToolbarProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  modalVisible: boolean;
  minDates?: number;
  maxDates?: number;
  maxDate?: Moment;
  datesPerRow?: number;
  setShowModal: (show: boolean) => void;
}

export enum ReportToolbarClassName {
  Base = "report-toolbar",
  Grid = "report-toolbar__grid",
  Buttons = "report-toolbar__buttons",
  Dates = "report-toolbar__dates",
  DatesRow = "report-toolbar__dates-row",
  DatePicker = "report-toolbar__datepicker",
  Form = "report-toolbar__form",
  GenerateReportButton = "report-toolbar__generate-report-button",
  RadioButton = "report-toolbar__radio-button",
  RadioButtonLabel = "report-toolbar__radio-button-label",
}
