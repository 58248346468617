import { ButtonTheme, Modal } from "@q4/nimbus-ui";
import { Search } from "q4-platform-common/src/models/search/search";
import React, { useState } from "react";
import SearchInput from "../../searchInput/SearchInput.component";
import { MapXToYModalClassName, MapXToYModalProps } from "./MapXToYModal.definition";
import "./MapXToYModal.scss";

const MapXToYModal = (props: MapXToYModalProps): JSX.Element => {
  const { title = "Map X To Y", subtitle = "", showModal, setShowModal, confirmCallback, searchEntityType } = props;
  const [selectedItem, setSelectedItem] = useState<Search>(null);
  const onClickCancel = () => {
    setShowModal(false);
  };
  const onClickAdd = () => {
    setShowModal(false);
    confirmCallback(selectedItem);
  };
  const getModalFooterActions = () => [
    { label: "Cancel", theme: ButtonTheme.DarkSlate, onClick: onClickCancel },
    { label: "Confirm", theme: ButtonTheme.Citrus, onClick: onClickAdd },
  ];
  return (
    <Modal
      className={MapXToYModalClassName.Base}
      visible={showModal}
      title={title}
      subtitle={subtitle}
      onCloseRequest={() => setShowModal(false)}
      footerActions={getModalFooterActions()}
    >
      <SearchInput popover={false} selectionCallback={setSelectedItem} defaultEntityType={searchEntityType} />
    </Modal>
  );
};

export default MapXToYModal;
