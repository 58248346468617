import { ButtonIdModel, IdModelBase, isNullOrWhiteSpace } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface HeaderProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
}

export enum HeaderClassName {
  Base = "header",
  ProfileButton = "header_profile-button",
  Toolbar = "header_toolbar",
  ToolbarGrid = "header_toolbar-grid",
  TickerSelector = "header_ticker-selector",
}

export class HeaderIdModel extends IdModelBase {
  profile: ButtonIdModel;
  logOut: ButtonIdModel;

  constructor(id: string) {
    super(id);
    if (isNullOrWhiteSpace(this.id)) {
      this.profile = new ButtonIdModel(null);
      this.logOut = new ButtonIdModel(null);

      return;
    }

    this.profile = new ButtonIdModel(`${this.id}ProfileButton`);
    this.logOut = new ButtonIdModel(`${this.id}LogOutButton`);
  }
}
