import { Button, ButtonTheme } from "@q4/nimbus-ui";
import { Custodian } from "q4-platform-common/src/models/custodian/custodian";
import React, { useContext } from "react";
import { NavigateOptions } from "react-router-dom";
import { RoutePath } from "../../../../configurations/navigation.configuration";
import { CustodianRef, NavigationContext, NavigationContextUrlSearchParams } from "../../../../contexts";
import { CustodianButtonSize, CustodianListClassName, CustodianListProps } from "./CustodianList.definition";
import "./CustodianList.scss";

const CustodianList = (props: CustodianListProps): JSX.Element => {
  const { searchRef, goto } = useContext(NavigationContext);
  const custodianTitle = "Custodians";
  const { custodians, showListOnly = false, buttonSize = CustodianButtonSize.Medium } = props;

  const urlSearchParamsFormatter = (search: React.MutableRefObject<URLSearchParams>, data: CustodianRef) => {
    search.current.set(
      NavigationContextUrlSearchParams.Q4_CUSTODIAN_ID,
      data.Q4_CUSTODIAN_ID ? data.Q4_CUSTODIAN_ID : data.Q4_ENTITY_ID
    );
  };

  const buttonClick = (custodian: CustodianRef): void => {
    urlSearchParamsFormatter(searchRef, custodian);
    goto(RoutePath.Custodian, null, { state: custodian } as NavigateOptions, true);
  };

  const getCustodianButtons = () => {
    if (custodians) {
      return custodians.map((custodian: Custodian, custodianIndex: number) => {
        return (
          <Button
            key={`custodian-${custodian.DTC_ID}-${custodianIndex}`}
            className={buttonSize}
            theme={ButtonTheme.Citrus}
            label={custodian.DTC_ID}
            onClick={() => buttonClick(custodian)}
          />
        );
      });
    } else {
      return <></>;
    }
  };

  const custodianButtons = getCustodianButtons();
  return (
    <div className={CustodianListClassName.Base}>
      {!showListOnly && (
        <Button
          theme={ButtonTheme.BlackSmoke}
          className={CustodianListClassName.CustodiansButton}
          label={custodianTitle}
          disabled
        />
      )}
      {custodianButtons}
    </div>
  );
};

export default CustodianList;
