import { Message } from "@q4/nimbus-ui";
import type { BaseComponentProps } from "@q4/nimbus-ui";
import React, { memo, useContext } from "react";
import { GlobalMessageContext, ConfirmationActionResponse } from "../../../../contexts";

function GlobalMessage(props: BaseComponentProps): JSX.Element {
  const { id } = props;

  const globalMessage = useContext(GlobalMessageContext);
  const { visible, title, message, primaryActionProps } = globalMessage;

  function handleCancel() {
    globalMessage?.close(new ConfirmationActionResponse(false));
  }

  function handleConfirm() {
    globalMessage?.close(new ConfirmationActionResponse(true));
  }

  return (
    <Message
      id={id}
      visible={visible}
      title={title}
      message={message}
      primaryActionProps={{
        ...primaryActionProps,
        onClick: handleConfirm,
      }}
      secondaryActionProps={{
        label: "Cancel",
        onClick: handleCancel,
      }}
    />
  );
}

export default memo(GlobalMessage);
