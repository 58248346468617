import React, { createContext, useCallback, useState } from "react";
import { RefetchContextState, RefetchMap, RefetchProviderProps, REFETCH_SCOPE } from "./refetch.definition";

export const RefetchContext = createContext<Partial<RefetchContextState>>({});

export const RefetchProvider = (props: RefetchProviderProps): JSX.Element => {
  const [refetchMap, setRefetchMap] = useState<RefetchMap>({
    [REFETCH_SCOPE.ALL]: {},
    [REFETCH_SCOPE.MANAGER_TRANSACTIONS]: {},
    [REFETCH_SCOPE.FUND_TRANSACTIONS]: {},
    [REFETCH_SCOPE.TICKERS]: {},
  });

  /**
   * Adds a refetch hook to a specified fetcher. This allows us to refetch the data that function fetches if
   * a reliant context makes a change tha affects the scope of that data. The function is used to add fetchers that
   * get data on pages such as home, manager, custodian, etc. The fetched function should skip execution on whether the page
   * is mounted to avoid excess fetches
   */
  const addRefetchHook = useCallback(
    (scope: REFETCH_SCOPE, name: string, fn: () => Promise<void>, async?: boolean) => {
      const mapUpdate = refetchMap;
      if (async == null) {
        async = false;
      }
      mapUpdate[REFETCH_SCOPE.ALL][name] = { fn, async };
      mapUpdate[scope][name] = { fn, async };
      setRefetchMap(mapUpdate);
    },
    [refetchMap]
  );

  /**
   * The refetch function itself goes over all hooked fetcher functions and refetches recalls them if
   * they are within scope of the specified refetch requrest. This function is generally called by a data
   * setter function that knows the scope that it is affecting, such as editing a custom position.
   */
  const refetch = useCallback(
    (scope: REFETCH_SCOPE, q4EntityId?: string) => {
      console.debug(`Refetching scope: ${scope}\n${refetchMap[scope]}`);
      const promises = [] as Array<Promise<void>>;
      Object.values(refetchMap[scope]).forEach((fetcher) => {
        if (fetcher.async) {
          fetcher.fn(q4EntityId);
        } else {
          promises.push(fetcher.fn(q4EntityId));
        }
      });
      return promises;
    },
    [refetchMap]
  );

  const value = { refetchMap, addRefetchHook, refetch };
  return <RefetchContext.Provider value={value}>{props.children}</RefetchContext.Provider>;
};
