import { ButtonTheme, Modal, NotificationService } from "@q4/nimbus-ui";
import { CountryForm, ManagerForm } from "q4-platform-common/src/models/manager/managerForm";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ManagerContext } from "../../../../../contexts";
import ManagerService from "../../../../../services/manager/ManagerService";
import DevLoader from "../../devLoader/DevLoader";
import EditManagerForm from "./EditManagerForm/EditManagerForm";
import { EditManagerModalClassName, EditManagerModalProps } from "./EditManagerModal.definition";
import "./EditManagerModal.scss";

const EditManagerModal = (props: EditManagerModalProps): JSX.Element => {
  const { showModal, q4StockId, q4EntityId, setShowModal, editMode } = props;
  const notificationService = useRef(new NotificationService());
  const managerService = useMemo(() => new ManagerService(), []);
  const [countriesLoading, setCountriesLoading] = useState(false);

  // Countries data
  const [countriesData, setCountriesData] = useState<Array<CountryForm>>([]);
  const { addManager, managerMetadata, managerMetadataLoading } = useContext(ManagerContext);

  const managerFormDefaultState: ManagerForm = {
    q4EntityId: q4EntityId ? q4EntityId : undefined,
  };

  const [formState, setFormState] = useState<ManagerForm>(managerFormDefaultState);

  const updateState = useCallback((partialUpdateForm: Partial<ManagerForm>) => {
    setFormState((prevState) => ({ ...prevState, ...partialUpdateForm }));
  }, []);

  const loadManager = useCallback(() => {
    const {
      NAME: instName,
      INST_TYPE: instType,
      TURNOVER: turnover,
      STYLE: style,
      IS_STAKEHOLDER: isStakeholder,
      ACTIVIST: activist,
      COUNTRY: countryName,
      MANAGER_STYLE: managerStyle,
      CIK: cik,
    } = managerMetadata;

    updateState({
      instName: instName || undefined,
      instType: instType || undefined,
      turnover: turnover || undefined,
      style: style || undefined,
      isStakeholder: isStakeholder || undefined,
      activist: activist || undefined,
      countryName: countryName || undefined,
      managerStyle: managerStyle || undefined,
      cik: cik || undefined,
    });
  }, [managerMetadata, updateState]);

  useEffect(() => {
    if (!managerMetadataLoading && editMode) {
      loadManager();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearLocalState = () => {
    updateState(managerFormDefaultState);
  };

  const fetchCountries = useCallback(async () => {
    setCountriesLoading(true);
    const result = await managerService.getCountries();
    setCountriesLoading(false);
    if (result.success && result.data) {
      setCountriesData(result.data);
      if (!editMode) {
        const USIndex = result.data.findIndex((v) => v.countryCd === "US");
        const suggestedCountryIndex = USIndex != -1 ? USIndex : 0;
        updateState({
          countryCd: result.data[suggestedCountryIndex].countryCd,
          countryName: result.data[suggestedCountryIndex].countryName,
        });
      }
    } else {
      setCountriesData([]);
      notificationService.current.warn(`Unable to get countries. ${result.message}`);
    }
  }, [editMode, managerService, updateState]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const onClickCancel = () => {
    setShowModal(false);
    clearLocalState();
  };

  const onClickAdd = async () => {
    setCountriesLoading(true);
    await addManager(formState, q4StockId);
    setCountriesLoading(false);
    setShowModal(false);
    clearLocalState();
  };

  const isValid = () => {
    return !!formState.instName;
  };

  const handleCloseRequest = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  function getModalFooterActions() {
    return [
      {
        label: "Cancel",
        theme: ButtonTheme.DarkSlate,
        onClick: onClickCancel,
        disabled: managerMetadataLoading || countriesLoading,
      },
      {
        label: editMode ? "Save" : "Add",
        theme: ButtonTheme.Citrus,
        onClick: onClickAdd,
        disabled: !isValid() || managerMetadataLoading || countriesLoading,
      },
    ];
  }
  return (
    <Modal
      className={EditManagerModalClassName.Base}
      visible={showModal}
      title={`${editMode ? "Edit" : "Add"} Custom Manager`}
      subtitle={""}
      onCloseRequest={handleCloseRequest}
      footerActions={getModalFooterActions()}
    >
      {managerMetadataLoading || countriesLoading ? (
        <DevLoader />
      ) : (
        <EditManagerForm
          form={formState}
          valid={isValid()}
          editMode={editMode}
          showModal={true}
          updateState={updateState}
          countries={countriesData}
        />
      )}
    </Modal>
  );
};

export default EditManagerModal;
