import type { BaseComponentWithChildrenProps, ErrorLogContextState } from "@q4/nimbus-ui";

export interface HomeProps extends BaseComponentWithChildrenProps {
  errorLog?: ErrorLogContextState;
}

// Action Label Block for filtering options
export interface FilterOptionType {
  label: string;
  action: () => void;
}

// Leyed to data columns
export interface FilterMapIndex {
  [key: string]: FilterOptionType;
}

// Filter state, keyed by data columns, if string set filters to only that column value
export interface FilterState {
  [key: string]: string | null;
}

// If we want to add more filters simply add the column that we want to filter on here
// FUTURE IDEA we could move the filterMap into the SurveillanceGrid Toolbar and make it generic
export const filterMap = {
  TURNOVER: {} as FilterMapIndex,
  STYLE: {} as FilterMapIndex,
  TYPE: {} as FilterMapIndex,
  ACTIVIST: {} as FilterMapIndex,
  ORIENTATION: {} as FilterMapIndex,
  IS_STAKEHOLDER: {} as FilterMapIndex,
};

export enum HomeClassName {
  Base = "home",
  DatePicker = "date-picker",
  Grid = "home__grid",
  LeftGrid = "home__left-grid",
  StockPerformance = "home__stock-performance",
  StockPerformanceToolbarContent = "home__stock-performance-content",
  InstitutionalHolder = "home__institutional-holder",
  InstitutionalHolderToolbarContent = "home__institutional-holder-content",
  SummaryEnhancements = "home__summary-enhancements",
}

export const DEF_WEEKS = 4;
const MAX_WEEKS = 16;
export const WEEK_OPTIONS = Array.from(Array(MAX_WEEKS + 1).keys());
export const DEF_TOP_OPTIONS = 5;
const MAX_OPTIONS = 10;
export const TOP_OPTIONS = Array.from(Array(MAX_OPTIONS + 1).keys());
