import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface FileUploadModalProps extends BaseComponentProps {
  title?: string;
  subtitle?: string;
  overlayMessage?: string;
  showModal: boolean;
  fileLabel: string;
  setShowModal: (showModal: boolean) => void;
  confirmCallback: (value: string) => Promise<boolean>;
}

export enum FileUploadModalClassName {
  Base = "file-upload-modal",
  Content = "file-upload-modal__content",
  Input = "file-upload-modal__input",
}
