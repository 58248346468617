import type { BaseComponentProps } from "@q4/nimbus-ui";
import { Custodian } from "q4-platform-common/src/models/custodian/custodian";

export interface CustodianListProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  custodians?: Array<Custodian>;
  showListOnly?: boolean;
  buttonSize?: CustodianButtonSize;
}

export enum CustodianListClassName {
  Base = "svl-custodian-list",
  CustodiansButton = "svl-custodian-button",
}

export enum CustodianButtonSize {
  Thin = "thin",
  Medium = "medium",
}
