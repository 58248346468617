import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface AreYouSureModalProps extends BaseComponentProps {
  children: JSX.Element;
  customClassName?: string;
  title?: string;
  subtitle?: string;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  callback: () => void;
}

export enum AreYouSureModalClassName {
  Base = "are-you-sure-modal",
}
