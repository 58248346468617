import { ButtonTheme, Modal } from "@q4/nimbus-ui";
import moment from "moment";
import React, { useState } from "react";
import DevLoader from "../../devLoader/DevLoader";
import AddTransactionsForm from "./AddTransactionsForm/AddTransactionsForm";
import { AddTransactionsModalClassName, AddTransactionsModalProps } from "./AddTransactionsModal.definition";
import "./AddTransactionsModal.scss";

const AddTransactionsModal = (props: AddTransactionsModalProps): JSX.Element => {
  const {
    showModal,
    q4SecurityId,
    q4EntityId,
    initialQuantity = 0,
    initialDifference = 0,
    initialNote = "",
    initialDate = moment(),
    setShowModal,
    addTransaction,
  } = props;
  const [transactionDate, setTransactionDate] = useState(initialDate);
  const [transactionChange, setTransactionChange] = useState<number>(initialDifference);
  const [transactionNote, setTransactionNote] = useState(initialNote);
  const [showValidationError, setShowValidationError] = useState(false);
  const [loading, setLoading] = useState(false);
  const getData = () => ({
    SEC_ID: q4SecurityId,
    Q4_ENTITY_ID: q4EntityId,
    DATE: transactionDate.format("YYYY-MM-DD"),
    QUANTITY: initialQuantity + transactionChange,
    NOTE: transactionNote,
  });
  const clearLocalState = () => {
    setTransactionDate(moment);
    setTransactionChange(initialDifference);
    setTransactionNote(initialNote);
    setShowValidationError(false);
  };
  const onClickCancel = () => {
    setShowModal(false);
    clearLocalState();
  };
  const onClickAdd = async () => {
    // Quantity must be greater than or equal to 0 and change must always be greater than zero
    if (transactionChange != null && initialQuantity + transactionChange >= 0) {
      setShowValidationError(false);
      const data = getData();
      setLoading(true);
      await addTransaction(data);
      setLoading(false);
      setShowModal(false);
      clearLocalState();
    } else {
      setShowValidationError(true);
    }
  };
  const getModalFooterActions = () => [
    { label: "Cancel", theme: ButtonTheme.DarkSlate, onClick: onClickCancel, disabled: loading },
    { label: "Add", theme: ButtonTheme.Citrus, onClick: onClickAdd, disabled: loading },
  ];
  return (
    <Modal
      className={AddTransactionsModalClassName.Base}
      visible={showModal}
      title={"Add Transaction"}
      subtitle={""}
      onCloseRequest={() => setShowModal(false)}
      footerActions={getModalFooterActions()}
    >
      {loading ? (
        <DevLoader />
      ) : (
        <AddTransactionsForm
          transactionDate={transactionDate}
          transactionQuantity={initialQuantity + transactionChange}
          transactionChange={transactionChange}
          transactionNote={transactionNote}
          valid={!showValidationError}
          setTransactionDate={setTransactionDate}
          setTransactionQuantity={(value: number) => setTransactionChange(value - initialQuantity)}
          setTransactionChange={setTransactionChange}
          setTransactionNote={setTransactionNote}
        />
      )}
    </Modal>
  );
};

export default AddTransactionsModal;
