import { ButtonTheme, Modal } from "@q4/nimbus-ui";
import React, { useContext } from "react";
import { ReportContext } from "../../../../contexts";
import ProgressBar from "../../../root/components/progressBar/ProgressBar.component";
import { ReportModalClassName, ReportModalProps } from "./ReportModal.definition";
import "./ReportModal.scss";

const ReportModal = (props: ReportModalProps): JSX.Element => {
  const { showModal, setShowModal } = props;
  const {
    tickerSymbol,
    tickerExchange,
    csvData,
    baseDate,
    granularity,
    modalMessage,
    csvLoading,
    csvLoadingPercent,
    setCsvDownloaded,
    downloadError,
    cancelReportGeneration,
  } = useContext(ReportContext);

  const granularityMap = (): string => (granularity == "daily" ? "custom" : granularity);

  const title = `${granularityMap().charAt(0).toUpperCase() + granularityMap().slice(1)} Shareholder Report`;

  const getReportTypeDownloadText = () => {
    const reportTextGranularityMap: Record<string, string> = {
      weekly: "ClientVariableReport",
      monthly: "MonthlyDetails",
      quarterly: "QuarterlyDetails",
      daily: "CustomDetails",
    };
    return reportTextGranularityMap[granularity] ? reportTextGranularityMap[granularity] : granularity;
  };

  const downloadCsv = () => {
    const fileName = `${tickerSymbol} - ${tickerExchange} ${baseDate.format(
      "YYYY-MM-DD"
    )} ${getReportTypeDownloadText()}.csv`;
    const fileType = "text/csv";
    const element = document.createElement("a");
    element.setAttribute("href", `data:${fileType};base64,` + csvData);
    element.setAttribute("download", fileName);
    element.click();
    element.remove();
    setCsvDownloaded(true);
    setShowModal(false);
  };
  const cancelReport = () => {
    if (csvLoading) {
      cancelReportGeneration();
    }
    setShowModal(false);
    setCsvDownloaded(true);
  };
  const getModalFooterActions = () => [
    { label: "CANCEL", theme: ButtonTheme.Citrus, onClick: cancelReport },
    {
      label: "DOWNLOAD .CSV",
      theme: ButtonTheme.Citrus,
      onClick: downloadCsv,
      loading: csvLoading,
      disabled: downloadError,
    },
  ];
  return (
    <Modal
      className={ReportModalClassName.Base}
      visible={showModal}
      title={title}
      // subtitle={subtitle}
      onCloseRequest={() => {
        setShowModal(false);
      }}
      footerActions={getModalFooterActions()}
    >
      {modalMessage}
      <ProgressBar percentComplete={csvLoadingPercent} loading={csvLoading}></ProgressBar>
    </Modal>
  );
};

export default ReportModal;
