import { Exchange, GetTicker } from "q4-platform-common/src/models/ticker/ticker";
import { ApiResponse } from "../api/api.definition";
import ApiService from "../api/api.service";

export default class TickerService {
  private readonly apiService: ApiService;
  private readonly ApiPath = "/ticker";
  constructor() {
    this.apiService = new ApiService();
  }

  async getTickers(): Promise<ApiResponse<Array<GetTicker>>> {
    const response = await this.apiService.get<Array<GetTicker>>(`${this.ApiPath}`);
    return this.mapTickers(response);
  }

  async getExchanges(): Promise<ApiResponse<Array<Exchange>>> {
    const response = await this.apiService.get<Array<Exchange>>(`${this.ApiPath}/exchanges`);
    return this.mapTickers(response);
  }

  private mapTickers<T>(response: ApiResponse<Array<T>>): ApiResponse<Array<T>> {
    return new ApiResponse({ ...response });
  }
}
