import type { BaseComponentProps } from "@q4/nimbus-ui";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import React from "react";

export interface GridToolbarProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  leftContent?: string | React.ReactFragment;
  leftContentWidth?: string;
  rightContent?: string | React.ReactFragment;
  rightContentWidth?: string;
  gridRef?: React.RefObject<AgGridReact>;
  disabled?: boolean;
  showDownloadDataButton?: boolean;
  showUploadDataButton?: boolean;
  uploadData?: () => void;
}

export enum GridToolbarClassName {
  Base = "grid-toolbar",
  Grid = "grid-toolbar__grid",
  LeftColumn = "grid-toolbar__left-column",
  RightColumn = "grid-toolbar__right-column",
}
