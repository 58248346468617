import { Moment } from "moment";

/**
 * Date formatter for sending requests to the API in YYYY-MM-DD format
 * @param date The date to format
 * @returns The formatted date
 */
export function apiDateFormatter(date: Moment): string {
  return `${date.year()}-${date.month() + 1}-${date.date()}`;
}
