import { Moment } from "moment";
import { SuggestedPosition } from "q4-platform-common/src/models/suggestedposition/suggestedposition";
import { apiDateFormatter } from "../../utils/date/dateUtils";
import { ApiResponse } from "../api/api.definition";
import ApiService from "../api/api.service";

export default class SuggestedPositionService {
  private readonly apiService: ApiService;
  private readonly ApiPath = "/suggestedposition";
  constructor() {
    this.apiService = new ApiService();
  }

  async getSuggestedPositionData(q4StockId: string): Promise<ApiResponse<Array<SuggestedPosition>>> {
    const response = await this.apiService.get<Array<SuggestedPosition>>(`${this.ApiPath}/ftd?q4StockId=${q4StockId}`);
    return new ApiResponse(response);
  }

  async setSuggestedPositionReviewToggle(
    q4StockId: string,
    q4EntityId: string,
    suggestedPosition: number,
    suggestedDate: Moment
  ): Promise<ApiResponse<null>> {
    const response = await this.apiService.put(
      `${
        this.ApiPath
      }/ftd/reviewed?q4StockId=${q4StockId}&q4EntityId=${q4EntityId}&suggestedPosition=${suggestedPosition}&suggestedDate=${apiDateFormatter(
        suggestedDate
      )}`,
      null
    );
    return new ApiResponse(response);
  }

  async refreshSuggestedPositions(q4StockId: string, q4EntityId: string): Promise<ApiResponse<null>> {
    const response = await this.apiService.post(
      `${this.ApiPath}/ftd/refreshPositions?q4StockId=${q4StockId}&q4EntityId=${q4EntityId}`,
      null
    );
    return new ApiResponse(response);
  }
}
