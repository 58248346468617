import { IdModelBase, TextIdModel } from "@q4/nimbus-ui";
import type { BaseComponentWithChildrenProps, ErrorLogContextState } from "@q4/nimbus-ui";
import { ReportJobListId } from "./components/job/reportJob.definition";

export interface ReportProps extends BaseComponentWithChildrenProps {
  errorLog?: ErrorLogContextState;
}

export enum ReportClassName {
  Base = "report",
  Banner = "report_banner",
  ToggleButtons = "report_toggle-buttons",
  Toolbar = "report_toolbar",
  ToolbarGrid = "report_toolbar-grid",
  ModalGrid = "report_modal-grid",
}
export class ReportIdModel extends IdModelBase {
  title: TextIdModel;
  jobList: ReportJobListId;

  constructor(id: string) {
    super(id);
    this.title = new TextIdModel(`${this.id}Title`);
    this.jobList = new ReportJobListId(this.id);
  }
}

export const ViewId = "Report";
export const ViewIdModel = new ReportIdModel(ViewId);
