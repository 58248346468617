import { NewCustomer } from "q4-platform-common/src/models/customerEnablement/customerEnable";
import { ApiResponse } from "../api/api.definition";
import ApiService from "../api/api.service";

export default class CustomerService {
  private readonly apiService: ApiService;
  private readonly ApiPath = "/customer-enablement";
  constructor() {
    this.apiService = new ApiService();
  }

  async enableClient(client: NewCustomer): Promise<ApiResponse<string>> {
    return this.apiService.put(`${this.ApiPath}`, { customer: client });
  }
}
