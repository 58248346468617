import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface ReportToolbarLegacyProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  modalVisible: boolean;
  setShowModal: (show: boolean) => void;
}

export enum ReportToolbarLegacyClassName {
  Base = "report-toolbar-legacy",
  Grid = "report-toolbar-legacy__grid",
  Buttons = "report-toolbar-legacy__buttons",
  DatePicker = "report-toolbar-legacy__datepicker",
  GenerateReportButton = "report-toolbar-legacy__generate-report-button",
}
