import React from "react";
import { ToggleButtonProps } from "./ToggleButton.definition";

import "./ToggleButton.scss";

const ToggleButton = ({ label, onChange, checked, disabled = false, id }: ToggleButtonProps): JSX.Element => {
  return (
    <label key={id} className="toggleButton">
      <input onChange={() => onChange()} checked={checked} type="checkbox" disabled={disabled} />
      <span className="labels" data-text={label}></span>
    </label>
  );
};

export default ToggleButton;
