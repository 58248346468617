import { Tabs, Modal, TabsTheme, ComponentSizeModifier } from "@q4/nimbus-ui";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { InstitutionTicker, SectorSummary } from "q4-platform-common/src/models/manager/manager";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ManagerService from "../../../../services/manager/ManagerService";
import GridToolbar from "../../../root/components/GridToolbar/GridToolbar";
import SurveillanceGrid from "../../../root/components/SurveillanceGrid/SurveillanceGrid";
import {
  SectorSummaryColumnDefs,
  SectorDefaultColDef,
  InstutionTockersColDef,
  InstitutionTickersColumnDefs,
} from "../../data/AgGridDefs";
import { AnalysisModalProps } from "./AnalysisModal.definition";

import "./AnalysisModal.scss";

const AnalysisModal = ({ showModal, setShowModal, currentEntityId, institutionName }: AnalysisModalProps): JSX.Element => {
  const [currentTab, setCurrentTab] = useState(0);
  const [sectorTimeFrame, setSectorTimeFrame] = useState("CALCULATING");
  const [sectorData, setSectorData] = useState<Array<SectorSummary>>(null);
  const [tickersData, setTickersData] = useState<Array<InstitutionTicker>>(null);
  const gridSectorAnalysisRef = useRef<AgGridReact>(null);
  const gridHoldsRef = useRef<AgGridReact>(null);

  const tabItems = useMemo(() => {
    return [
      {
        ariaLabel: "Sector Summary",
        disabled: false,
        key: "sector",
        label: "Sector Summary",
        value: "sector",
      },
      {
        ariaLabel: "Tickers Positions",
        disabled: false,
        key: "tickers",
        label: "Tickers",
        value: "tickers",
      },
    ];
  }, []);

  const getInstitutionTickersData = useCallback(async () => {
    const managerService = new ManagerService();

    const res = await managerService.getManagerHoldings(currentEntityId);

    if (res.success) {
      return res.data;
    }
    return [];
  }, [currentEntityId]);

  const getSectorAnalysisData = useCallback(async () => {
    const managerService = new ManagerService();

    const dataRes = await managerService.getManagerSectorSummary(currentEntityId);
    if (dataRes.success) {
      if (dataRes.data) {
        if (dataRes.data.length > 0) {
          const currentDate = dataRes.data[0].LATEST_DATE;
          const prevDate = dataRes.data[0].PREV_DATE;
          setSectorTimeFrame(`${currentDate} - ${prevDate}`);
        }
        const sumRow = {} as SectorSummary;
        sumRow.SECTOR = "TOTAL";
        sumRow.CURRENT_HOLDING = dataRes.data.reduce((sum, sector) => sum + sector.CURRENT_HOLDING, 0);
        sumRow.CURRENT_MV = dataRes.data.reduce((sum, sector) => sum + sector.CURRENT_MV, 0);
        sumRow.HOLDINGS_CHANGE = dataRes.data.reduce((sum, sector) => sum + sector.HOLDINGS_CHANGE, 0);
        sumRow.MV_CHANGE = dataRes.data.reduce((sum, sector) => sum + sector.MV_CHANGE, 0);
        sumRow.PERCENT_PORTFOLIO = dataRes.data.reduce((sum, sector) => sum + sector.PERCENT_PORTFOLIO, 0);
        sumRow.PERCENT_PORTFOLIO_CHANGE = dataRes.data.reduce((sum, sector) => sum + sector.PERCENT_PORTFOLIO_CHANGE, 0);
        sumRow.PREVIOUS_HOLDING = dataRes.data.reduce((sum, sector) => sum + sector.PREVIOUS_HOLDING, 0);
        sumRow.PREV_MV = dataRes.data.reduce((sum, sector) => sum + sector.PREV_MV, 0);
        sumRow.PREV_PERCENT_PORTFOLIO = dataRes.data.reduce((sum, sector) => sum + sector.PREV_PERCENT_PORTFOLIO, 0);
        sumRow.PRICE_CHG_MV = dataRes.data.reduce((sum, sector) => sum + sector.PRICE_CHG_MV, 0);
        sumRow.SHARE_CHG_MV = dataRes.data.reduce((sum, sector) => sum + sector.SHARE_CHG_MV, 0);
        dataRes.data.push(sumRow);
        return dataRes.data;
      }
      return [];
    } else {
      return [];
    }
  }, [currentEntityId]);

  useEffect(() => {
    const fetchSectorData = async () => {
      if (!sectorData) {
        setSectorData(await getSectorAnalysisData());
      }
    };

    const fetchTickersData = async () => {
      if (!tickersData) {
        setTickersData(await getInstitutionTickersData());
      }
    };

    if (showModal && currentTab === 0) {
      fetchSectorData();
    } else {
      fetchTickersData();
    }
  }, [currentTab, sectorData, tickersData, showModal, getSectorAnalysisData, getInstitutionTickersData]);

  const renderTabs = () => (
    <Tabs
      theme={TabsTheme.Slate}
      size={ComponentSizeModifier.Compact}
      className="tabs--custom"
      dataId="CustomTabs"
      id="CustomTabs"
      items={tabItems}
      onChange={(idx) => setCurrentTab(idx)}
      selected={currentTab}
    />
  );

  return (
    <>
      <Modal
        className="analysis-modal"
        title={renderTabs()}
        visible={showModal}
        onCloseRequest={() => setShowModal(false)}
        fullscreen={true}
      >
        {tabItems[currentTab].key === "sector" ? (
          <>
            <GridToolbar
              leftContent={
                <h2 className="subTitle">{`Sector Analysis - Per sector aggregation for ${sectorTimeFrame} by manager`}</h2>
              }
              gridRef={gridSectorAnalysisRef}
              showDownloadDataButton={true}
            />
            <SurveillanceGrid
              gridRef={gridSectorAnalysisRef}
              data={sectorData}
              id="sector-analysis-grid"
              DefaultColDef={SectorDefaultColDef}
              ColumnDefs={SectorSummaryColumnDefs}
            />
          </>
        ) : (
          <>
            <GridToolbar
              leftContent={<h2 className="subTitle">{`${institutionName} holds`}</h2>}
              gridRef={gridHoldsRef}
              showDownloadDataButton={true}
            />
            <SurveillanceGrid
              gridRef={gridHoldsRef}
              data={tickersData}
              id="institution-tickers-grid"
              DefaultColDef={InstutionTockersColDef}
              ColumnDefs={InstitutionTickersColumnDefs}
              disableLoaderText={true}
              overlayNoRowsTemplate="No Data Available"
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default AnalysisModal;
