import { Button, ButtonTheme, Modal } from "@q4/nimbus-ui";
import React, { ChangeEvent, useRef, useState } from "react";
import DragAndDrop from "../../DragAndDrop/DragAndDrop";
import DevLoader from "../../devLoader/DevLoader";
import { FileUploadModalClassName, FileUploadModalProps } from "./FileUploadModal.definition";
import "./FileUploadModal.scss";

const FileUploadModal = (props: FileUploadModalProps): JSX.Element => {
  const { fileLabel, title = "", subtitle = "", showModal, setShowModal, confirmCallback } = props;
  const [files, setFiles] = useState<Array<File>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const inputFile = useRef(null);
  const filterFiles = (fileList: FileList): Array<File> => {
    const fileTypes = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    return [...fileList].map((file: File) => file).filter((file: File) => fileTypes.includes(file.type));
  };
  const browseFilesOnChange = (event: ChangeEvent<HTMLInputElement>) => setFiles(filterFiles(event.target.files));
  const onClickCancel = () => {
    setFiles([]);
    setShowModal(false);
  };
  const onClickAdd = () => {
    const reader = new FileReader();
    const readerOnLoadHandler = async () => {
      reader.removeEventListener("load", readerOnLoadHandler);
      const res = reader.result as string;
      const data = res.substring(res.indexOf("base64,") + 7);
      setLoading(true);
      try {
        await confirmCallback(data);
      } finally {
        setLoading(false);
        setShowModal(false);
      }
      return true;
    };
    reader.addEventListener("load", readerOnLoadHandler, false);
    if (files[0]) {
      reader.readAsDataURL(files[0]);
    }
    setFiles([]);
  };
  const getModalFooterActions = () => [
    { label: "Cancel", theme: ButtonTheme.DarkSlate, onClick: onClickCancel },
    { label: "Upload", theme: ButtonTheme.Citrus, onClick: onClickAdd, disabled: files.length === 0 },
  ];
  const renderDragAndDropContent = () => {
    const instructions = `Drag and drop your ${fileLabel} excel file here for your chosen bank and click upload.`;
    const or = "or";
    const browseFiles = "Browse Files";
    return (
      <div className={FileUploadModalClassName.Content}>
        <div>
          <p>{instructions}</p>
          <p>{or}</p>
        </div>
        <Button label={browseFiles} onClick={() => (inputFile ? inputFile.current.click() : false)} />
      </div>
    );
  };
  const renderDragAndDrop = () => {
    const dropMessage = `Drop your ${fileLabel} excel file here for your chosen bank and click upload.`;
    return (
      <DragAndDrop dropMessage={dropMessage} handleDrop={(fileList: FileList) => setFiles(filterFiles(fileList))}>
        {renderDragAndDropContent()}
      </DragAndDrop>
    );
  };
  const renderFileUploading = () => {
    return <DevLoader disableText noMargin />;
  };
  const renderUploadInstructions = () => {
    const instructions1 = `Click the upload button to start uploading your ${fileLabel} excel`;
    const instructions2 = "to your chosen bank.";
    return (
      <div className={FileUploadModalClassName.Content}>
        <p>{instructions1}</p>
        <p>${files[0].name}</p>
        <p>{instructions2}</p>
      </div>
    );
  };
  const renderFileBrowserInput = () => {
    return (
      <input
        className={FileUploadModalClassName.Input}
        type="file"
        id="file"
        ref={inputFile}
        onChange={browseFilesOnChange}
      />
    );
  };
  const renderDialog = () => {
    if (loading) {
      return renderFileUploading();
    }
    return (
      <>
        {files.length === 0 ? renderDragAndDrop() : renderUploadInstructions()}
        {renderFileBrowserInput()}
      </>
    );
  };
  const renderModal = () => {
    return (
      <Modal
        className={FileUploadModalClassName.Base}
        visible={showModal}
        title={title}
        subtitle={subtitle}
        onCloseRequest={() => setShowModal(false)}
        footerActions={getModalFooterActions()}
      >
        {renderDialog()}
      </Modal>
    );
  };
  return renderModal();
};

export default FileUploadModal;
