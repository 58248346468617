import "./header.component.scss";
import { ButtonSize, Grid, GridColumn, Toolbar, ToolbarTheme } from "@q4/nimbus-ui";
import React, { memo, useMemo, useState } from "react";
import AddButton from "../Button/AddButton/AddButton";
import AddClientModal from "../Modal/AddClientModal/AddClientModal";
import ProfileMenu from "../profileMenu/ProfileMenu.component";
import SearchInput from "../searchInput/SearchInput.component";
import TickerSelector from "../tickerSelector/TickerSelector.component";
import { HeaderClassName, HeaderIdModel, HeaderProps } from "./header.definition";

function Header(props: HeaderProps): JSX.Element {
  const { id } = props;
  const idModel = useMemo(() => new HeaderIdModel(id), [id]);
  const [showAddClientModal, setShowAddClientModal] = useState(false);

  const renderAddClientModal = () => (
    <>{showAddClientModal && <AddClientModal showModal={showAddClientModal} setShowModal={setShowAddClientModal} />}</>
  );
  const renderLeftColumn = () => {
    return (
      <GridColumn width="1-of-2" smallWidth="1-of-2">
        <SearchInput id="testIdHelloWorld" />
      </GridColumn>
    );
  };
  const renderRightColumn = () => {
    return (
      <GridColumn width="1-of-2" smallWidth="1-of-2">
        <AddButton size={ButtonSize.Large} onClick={() => setShowAddClientModal(true)} />
        <TickerSelector />
        <ProfileMenu id={id} />
      </GridColumn>
    );
  };
  return (
    <Toolbar
      className={HeaderClassName.Base}
      id={idModel.id}
      theme={ToolbarTheme.Q4Blue}
      autoRow
      autoRowProps={{ justifyContent: "flex-end" }}
    >
      <Grid className={HeaderClassName.ToolbarGrid}>
        {renderLeftColumn()}
        {renderRightColumn()}
      </Grid>
      {renderAddClientModal()}
    </Toolbar>
  );
}

export default memo(Header);
