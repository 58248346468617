import {
  AsyncSelect,
  ButtonTheme,
  Checkbox,
  Grid,
  GridColumn,
  Modal,
  NotificationService,
  SelectOption,
  SelectTheme,
  Textbox,
} from "@q4/nimbus-ui";
import { NewCustomer } from "q4-platform-common/src/models/customerEnablement/customerEnable";
import { Exchange } from "q4-platform-common/src/models/ticker/ticker";
import React, { SyntheticEvent, useContext, useEffect, useMemo, useRef, useState } from "react";
import { FiAlertTriangle } from "react-icons/fi";
import { OptionsType } from "react-select";
import { REFETCH_SCOPE, RefetchContext } from "../../../../../contexts";
import CustomerService from "../../../../../services/customer/CustomerService";
import TickerService from "../../../../../services/ticker/TickerService";
import DevLoader from "../../devLoader/DevLoader";
import { AddClientModalProps, FormClassName } from "./AddClientModal.definition";

import "./AddClientModal.scss";

const AddClientModal = ({ showModal, setShowModal }: AddClientModalProps): JSX.Element => {
  const [exchanges, setExchanges] = useState<Exchange[]>([]);
  const [exchangesLoading, setExchangesLoading] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [client, setClient] = useState<NewCustomer>();
  const notificationService = useRef(new NotificationService());
  const { refetch } = useContext(RefetchContext);

  const tickerService = useMemo(() => new TickerService(), []);
  const customerService = useMemo(() => new CustomerService(), []);

  useEffect(() => {
    const fetchExchanges = async () => {
      const resp = await tickerService.getExchanges();

      if (resp.success) {
        setExchanges(resp?.data);
      }

      setExchangesLoading(false);
    };

    if (showModal) {
      fetchExchanges();
    } else {
      setExchanges([]);
    }
  }, [tickerService, showModal]);

  const onClickAdd = async () => {
    const resp = await customerService.enableClient(client);
    if (resp.success) {
      notificationService.current.success("Client enabled!");
      setShowModal(false);
      refetch(REFETCH_SCOPE.TICKERS);
    } else {
      notificationService.current.error(resp.message);
    }
  };

  const getModalFooterActions = () => {
    return [
      {
        label: "Cancel",
        theme: ButtonTheme.DarkSlate,
        onClick: () => setShowModal(false),
        disabled: false,
      },
      {
        label: "Add",
        theme: ButtonTheme.Citrus,
        onClick: onClickAdd,
        disabled: false,
      },
    ];
  };

  const handleExchangeChange = (e: Record<string, string>) => {
    setClient((prev) => ({ ...prev, EXCHANGE_CD: e.value }));
  };

  const handleTickerInputChange = (value: string) => {
    setClient((prev) => ({ ...prev, SYMBOL: value?.toUpperCase() }));

    if (value?.trim().length === 0) {
      setIsValid(false);
    } else if (!isValid) {
      setIsValid(true);
    }
  };

  const renderCheckBox = (label: string) => {
    return (
      <label className={FormClassName.CheckOption}>
        <Checkbox
          onChange={() => {
            return;
          }}
          disabled={true}
          checked={true}
          id={label}
        />
        <span>{label}</span>
      </label>
    );
  };

  const asyncOptions: Array<Record<string, string>> = exchanges.map((option) => ({
    label: option.EXCHANGE_NAME,
    value: option.EXCHANGE_CD,
  }));

  const filterOptions = (inputValue: string): OptionsType<SelectOption<Record<string, string>>> => {
    const filteredAsyncOptions = asyncOptions.filter((i: Record<string, string>) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    return filteredAsyncOptions as unknown as OptionsType<SelectOption<Record<string, string>>>;
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: OptionsType<SelectOption<Record<string, string>>>) => void
  ): void => {
    setTimeout(() => callback(filterOptions(inputValue)), 1000);
  };

  const placeholder = client
    ? exchanges.find((e) => e.EXCHANGE_CD === client.EXCHANGE_CD)?.EXCHANGE_NAME
    : "Choose the exchange";

  const renderExchanges = () => {
    const label = "Exchange";
    return (
      <label>
        <span>{label}</span>
        <AsyncSelect
          onChange={handleExchangeChange}
          theme={SelectTheme.LightGrey}
          placeholder={placeholder}
          loadOptions={loadOptions}
          loading={exchangesLoading}
          defaultOptions
        />
      </label>
    );
  };

  const renderTickerInput = () => {
    const label = "Ticker";
    return (
      <label>
        <span>{label}</span>
        <Textbox placeholder={label} value={client?.SYMBOL} onChange={handleTickerInputChange} />
        {!isValid && (
          <span className={FormClassName.RequiredError} aria-live="polite">
            {label} is required.
          </span>
        )}
      </label>
    );
  };

  const onSubmit = (e: SyntheticEvent) => e.preventDefault();

  const AddClientForm = () => (
    <form onSubmit={onSubmit} autoComplete="off">
      <Grid>
        <GridColumn width="1-of-4">{renderTickerInput()}</GridColumn>
        <GridColumn width="3-of-4">{renderExchanges()}</GridColumn>
        <GridColumn width="2-of-4">{renderCheckBox("Enable Custom Positions")}</GridColumn>
        <GridColumn width="2-of-4">{renderCheckBox("Enable Reports")}</GridColumn>
        <GridColumn className={FormClassName.AlertMsgContainer} width="4-of-4">
          <FiAlertTriangle className={FormClassName.AlertMsgIcon}></FiAlertTriangle>
          <span>{"New clients needs 24h for the data be available at the system"}</span>
        </GridColumn>
      </Grid>
    </form>
  );

  return (
    <Modal
      // className={EditManagerModalClassName.Base}
      visible={showModal}
      title={"Add Client"}
      subtitle={""}
      onCloseRequest={() => setShowModal(false)}
      footerActions={getModalFooterActions()}
    >
      {false ? <DevLoader /> : <AddClientForm />}
    </Modal>
  );
};

export default AddClientModal;
