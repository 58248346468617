import { Button, ButtonTheme } from "@q4/nimbus-ui";
import React from "react";
import { ExportButtonClassName, ExportButtonProps } from "./ExportButton.definition";
import "./ExportButton.scss";

const ExportButton = (props: ExportButtonProps): JSX.Element => {
  const { disabled, onClick } = props;
  return (
    <Button
      className={ExportButtonClassName.Base}
      icon={"q4i-export-4pt"}
      theme={ButtonTheme.Rain}
      label={""}
      disabled={disabled}
      onClick={() => {
        (document.activeElement as HTMLElement).blur();
        onClick();
      }}
    />
  );
};

export default ExportButton;
