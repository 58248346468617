import type { BaseComponentProps } from "@q4/nimbus-ui";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

export interface FilingToolbarProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  gridRef?: React.RefObject<AgGridReact>;
  allOtherFilingsProcessed: boolean;
  setShowDeleteModal: (show: boolean) => void;
}

export enum FilingToolbarClassName {
  Base = "filing-toolbar",
  Grid = "filing-toolbar__grid",
  Checkbox = "filing-toolbar__checkbox",
}

export interface FilingActions {
  Name?: string;
  Action?: () => void;
  Disabled: boolean;
}
