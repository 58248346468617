import { Banner, BannerSize } from "@q4/nimbus-ui";
import React from "react";
import { FilingBannerClassName } from "./FilingBanner.definition";
import "./FilingBanner.scss";

const FilingBanner = (): JSX.Element => {
  const title = "Filing";
  const details = "Latest 13F Filings";
  return (
    <Banner className={FilingBannerClassName.Base} badgeIcon="" title={title} size={BannerSize.Thin} details={details} />
  );
};

export default FilingBanner;
