import moment from "moment";
import { SuggestedPosition } from "q4-platform-common/src/models/suggestedposition/suggestedposition";
import React, { useContext, useState } from "react";
import { ManagerContext, TickerContext } from "../../../../../contexts";
import AddTransactionsModal from "../../Modal/AddTransactionsModal/AddTransactionsModal";
import { PositionCellProps } from "./PositionCell.definition";
import "./PositionCell.scss";

export interface Params<T> {
  params: T;
}

const PositionCell = (props: PositionCellProps): React.ReactFragment => {
  const { ticker } = useContext(TickerContext);
  const { addTransaction } = useContext(ManagerContext);
  const [showModal, setShowModal] = useState(false);
  const { value, data = [], note = "" } = props;

  const onclick = () => {
    setShowModal(!showModal);
  };

  const nf = new Intl.NumberFormat("en-US");
  const dataTyped = data as SuggestedPosition;
  const suggestedPosition = dataTyped ? dataTyped.SUGGESTED_POSITION : 0;
  const currentPosition = dataTyped ? dataTyped.CUR_POSITION : 0;
  const initialDifference = suggestedPosition - currentPosition;
  const suggestedDate = dataTyped ? moment(dataTyped.SUGGESTED_DATE) : moment();

  return value ? (
    <>
      <a onClick={() => onclick()}>{nf.format(suggestedPosition)}</a>
      <AddTransactionsModal
        q4EntityId={dataTyped.Q4_ENTITY_ID}
        q4SecurityId={ticker.Q4_SEC_ID}
        showModal={showModal}
        initialQuantity={currentPosition}
        initialDifference={initialDifference}
        initialDate={suggestedDate}
        initialNote={note}
        setShowModal={setShowModal}
        addTransaction={addTransaction}
      />
    </>
  ) : (
    false
  );
};

export default PositionCell;
