import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface CheckmarkButtonProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  disabled?: boolean;
  onClick: () => void;
}

export enum CheckmarkButtonClassName {
  Base = "checkmark-button",
}
