import { Button, ButtonTheme } from "@q4/nimbus-ui";
import React from "react";
import { EditButtonClassName, EditButtonProps } from "./EditButton.definition";
import "./EditButton.scss";

const EditButton = (props: EditButtonProps): JSX.Element => {
  const { disabled, onClick } = props;
  return (
    <Button
      className={EditButtonClassName.Base}
      icon={"q4i-edit-4pt"}
      theme={ButtonTheme.BlackSmoke}
      label={""}
      disabled={disabled}
      onClick={() => {
        (document.activeElement as HTMLElement).blur();
        onClick();
      }}
    />
  );
};

export default EditButton;
