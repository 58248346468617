import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface CloseButtonProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  disabled?: boolean;
  onClick: () => void;
}

export enum CloseButtonClassName {
  Base = "close-button",
}
