const StockPriceActivityTitles = {
  CLOSING_PRICE: "Closing price ($)",
  CHANGE: "Change ($)",
  PERCENTAGE_CHANGE: "Change (%)",
  SHORT_VOLUME: "Short volume",
  TOTAL_VOLUME: "Total volume",
  PERCENTAGE_OF_30_DAY_AVG: "% of 30 day avg",
};

export const getStockPriceActivityDisplayData = (data: Array<Record<string, unknown>>): Array<Record<string, unknown>> => {
  const close = Object.assign({ TITLE: StockPriceActivityTitles.CLOSING_PRICE });
  const change = Object.assign({ TITLE: StockPriceActivityTitles.CHANGE });
  const percentChange = Object.assign({ TITLE: StockPriceActivityTitles.PERCENTAGE_CHANGE });
  const shortVolume = Object.assign({ TITLE: StockPriceActivityTitles.SHORT_VOLUME });
  const volume = Object.assign({ TITLE: StockPriceActivityTitles.TOTAL_VOLUME });
  const percentAverageVolume = Object.assign({ TITLE: StockPriceActivityTitles.PERCENTAGE_OF_30_DAY_AVG });
  const populateStockSummaryDisplayData = (existingData: Record<string, unknown>, value: string) => {
    data.forEach((data: Record<string, unknown>, dataIndex: number) => {
      const obj: Record<string, unknown> = {};
      const label = `DATE_${dataIndex + 1}`;
      const key = data[value] ? data[value] : 0;
      obj[label] = key;
      existingData = Object.assign(existingData, obj);
    });
  };
  populateStockSummaryDisplayData(close, "close");
  populateStockSummaryDisplayData(change, "change");
  populateStockSummaryDisplayData(percentChange, "percentChange");
  populateStockSummaryDisplayData(shortVolume, "shortVolume");
  populateStockSummaryDisplayData(volume, "volume");
  populateStockSummaryDisplayData(percentAverageVolume, "percentAverageVolume");
  return [close, change, percentChange, shortVolume, volume, percentAverageVolume];
};
