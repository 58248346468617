import "./Navigation.scss";
import { Button, Ghostable, getClassName, NavigationRoute } from "@q4/nimbus-ui";
import React, { useEffect, useMemo, memo, useState } from "react";
import type { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavigationClassName, NavigationProps, NavigationIdModel, NavigationRouteIdModel } from "./Navigation.definition";

const Navigation = (props: NavigationProps): JSX.Element => {
  const { id, dataId, className, logo, collapsedLogo, routes, collapseToggleIcon, footerActions } = props;
  const { search } = useLocation();
  const [chosenPath, setChosenPath] = useState(window.location.pathname);
  const [collapsed, setCollapsed] = useState(true);
  const getIdModel = (): NavigationIdModel => new NavigationIdModel(id);

  useEffect(() => {
    const onPopState = () => {
      setChosenPath(window.location.pathname);
    };
    const subscribe = () => {
      window.addEventListener("popstate", onPopState, false);
    };
    subscribe();
    return function unsubscribe() {
      window.removeEventListener("popstate", onPopState);
    };
  }, [setChosenPath]);

  const getBaseClassName = (): string =>
    getClassName(NavigationClassName.Base, [
      { condition: !!className, trueClassName: className },
      {
        condition: collapsed,
        trueClassName: NavigationClassName.BaseWithCollapsedModifier,
      },
    ]);

  const idModel = useMemo(getIdModel, [id]);
  const baseClassName = useMemo(getBaseClassName, [className, collapsed]);

  const getRouteActive = (path: string): boolean => path === chosenPath;

  const handleCollapseToggle = (): void => setCollapsed(!collapsed);

  const renderLogo = () => (
    <div className={NavigationClassName.Logo} id={idModel.logo}>
      {collapsed ? (collapsedLogo ? collapsedLogo : logo) : logo}
    </div>
  );

  const renderListItem = (route: NavigationRoute, index: number) => {
    const { id: itemId, className, path, icon, label } = route;
    const routeIdBase = idModel.routeList.getId(itemId || index);
    const routeIdModel = new NavigationRouteIdModel(routeIdBase);
    const conditionalClassName = [
      { condition: !!className, trueClassName: className },
      {
        condition: getRouteActive(path),
        trueClassName: NavigationClassName.RouteWithActiveModifier,
      },
    ];
    const routeClassName = getClassName(NavigationClassName.Route, conditionalClassName);
    // TODO we will want to make the ${search} logic optional via a prop before backfitting into NimbusUI
    return (
      <li key={path} className={NavigationClassName.Item} id={routeIdModel.id}>
        <Link
          to={`${path}${search}`}
          className={routeClassName}
          id={routeIdModel.link}
          target="_blank"
          onClick={() => setChosenPath(path)}
        >
          <i className={`${NavigationClassName.Icon} ${icon}`} id={routeIdModel.icon} />
          <span className={NavigationClassName.Label} id={routeIdModel.label}>
            {label}
          </span>
        </Link>
      </li>
    );
  };

  const renderList = () => (
    <ul className={NavigationClassName.List}>{(routes || []).map((route, index) => renderListItem(route, index))}</ul>
  );

  const renderFooter = () =>
    footerActions ? (
      <Ghostable ghosted={collapsed}>
        <footer className={NavigationClassName.Footer}>
          {footerActions.map(
            (buttonProps, index): ReactNode => (
              <Button
                id={idModel.footerActionListId.getId(index)}
                key={`navigation_footer-action--${index}`}
                {...buttonProps}
              />
            )
          )}
        </footer>
      </Ghostable>
    ) : (
      false
    );

  const renderCollapseToggle = () => (
    <div className={NavigationClassName.CollapseToggle}>
      <i className={collapseToggleIcon} id={idModel.toggle} onClick={handleCollapseToggle} />
    </div>
  );

  return (
    <nav className={baseClassName} id={idModel.id} data-id={dataId}>
      {renderLogo()}
      {renderList()}
      {renderFooter()}
      {renderCollapseToggle()}
    </nav>
  );
};

Navigation.defaultProps = {
  collapseToggleIcon: "ni-caret-left-4pt",
};

export default memo(Navigation);
