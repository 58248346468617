import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface LeftArrowButtonProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  direction?: string;
  disabled?: boolean;
  onClick: () => void;
}

export enum LeftArrowButtonClassName {
  Base = "left-arrow-button",
}
