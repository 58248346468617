import "./mutual-fund.view.scss";
import { BannerSize, Grid, GridColumn, Layout, LayoutPadding, LayoutTheme } from "@q4/nimbus-ui";
import { SuggestedPosition } from "q4-platform-common/src/models/suggestedposition/suggestedposition";
import React, { memo, useRef, useContext, useEffect, useState } from "react";
import { MutualFundContext } from "../../contexts";
import EntityBanner from "../root/components/EntityBanner/EntityBanner";
import SurveillanceGrid from "../root/components/SurveillanceGrid/SurveillanceGrid";
import DevLoader from "../root/components/devLoader/DevLoader";
import MutualFundGridToolbar from "./components/MutualFundGridToolbar/MutualFundGridToolbar";
import { DefaultColDef, getTwoDayVarianceReportColumnDef } from "./data/AgGridDefs";
import { MutualFundClassName, MutualFundDataTestId } from "./mutual-fund.definition";

const MutualFund = (): JSX.Element => {
  const gridRef = useRef(null);
  const { mutualFundData, mutualFundDataLoading, setViewMounted, setMutualFundReviewed } = useContext(MutualFundContext);
  const [minDifference, setMinDifference] = useState<number>(null);
  const [minChange, setMinChange] = useState<number>(null);
  const [includeReviewed, setIncludeReviewed] = useState<boolean>(false);

  useEffect(() => {
    setViewMounted(true);
    return () => {
      setViewMounted(false);
    };
  }, [setViewMounted]);

  const filterData = (suggestedPositions: Array<SuggestedPosition>): Array<SuggestedPosition> => {
    return suggestedPositions.filter(
      (suggestedPosition) =>
        ((minChange && suggestedPosition.CHANGE_PERCENT >= minChange) || !minChange) &&
        ((minDifference && suggestedPosition.DIFF_POSITION >= minDifference) || !minDifference) &&
        (includeReviewed || (!includeReviewed && !suggestedPosition.REVIEWED))
    );
  };

  return (
    <Layout className={MutualFundClassName.Base} padding={LayoutPadding.None} flex={false}>
      <EntityBanner
        loading={false}
        entityMetadata={{
          NAME: "Mutual Fund",
        }}
        size={BannerSize.Thin}
      />
      {mutualFundDataLoading ? (
        <DevLoader />
      ) : (
        <Grid className={MutualFundClassName.Grid}>
          <GridColumn width="1-of-1">
            <MutualFundGridToolbar
              gridRef={gridRef}
              minChange={minChange}
              minDifference={minDifference}
              setMinChange={setMinChange}
              setMinDifference={setMinDifference}
              includeReviewed={includeReviewed}
              setIncludeReviewed={setIncludeReviewed}
            />
            <Layout theme={LayoutTheme.PaleGrey} direction="column">
              <SurveillanceGrid
                id={MutualFundDataTestId.MutualFundSurveillanceGrid}
                gridRef={gridRef}
                DefaultColDef={DefaultColDef}
                ColumnDefs={getTwoDayVarianceReportColumnDef(async (data: SuggestedPosition) => {
                  await setMutualFundReviewed(data);
                })}
                data={filterData(mutualFundData)}
                overlayNoRowsTemplate={"No Data Available"}
                disableLoaderText={true}
              />
            </Layout>
          </GridColumn>
        </Grid>
      )}
    </Layout>
  );
};

export default memo(MutualFund);
