import type { BaseComponentProps } from "@q4/nimbus-ui";
import { Moment } from "moment";

export interface AddTransactionsFormProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  transactionDate: Moment;
  transactionQuantity: number;
  transactionChange: number;
  transactionNote: string;
  valid: boolean;
  setTransactionDate: (transactionDate: Moment) => void;
  setTransactionQuantity: (transactionQuantity: number) => void;
  setTransactionChange: (transactionChange: number) => void;
  setTransactionNote: (transactionNote: string) => void;
}

export enum AddTransactionsFormClassName {
  Base = "add-transactions-form",
  Input = "add-transactions-form__input",
  Label = "add-transactions-form__label",
  Textarea = "add-transactions-form__textarea",
  NumericInput = "add-transactions-form__numericinput",
  DatePicker = "add-transactions-form__datepicker",
  Error = "add-transactions-form__error",
}
