import {
  Button,
  ButtonSize,
  ButtonTheme,
  Checkbox,
  DatePicker,
  DatePickerTheme,
  Grid,
  GridColumn,
  RadioButton,
  Textbox,
  ToggleButtons,
  Toolbar,
  ToolbarTheme,
} from "@q4/nimbus-ui";
import moment, { Moment } from "moment";
import React, { memo, useCallback, useContext, useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { ReportContext, TickerContext } from "../../../../../contexts";
import ChipDatePickerList from "../ChipDatePickerList/ChipDatePickerList";
import { ReportToolbarClassName, ReportToolbarProps } from "./ReportToolbarNew.definition";
import "./ReportToolbarNew.scss";

// Options for the granularity toggle
const TOGGLE_BUTTON_OPTIONS = [
  { key: `${ReportToolbarClassName.Base}-toggle-button-item-${1}`, label: "Monthly", value: "monthly" },
  { key: `${ReportToolbarClassName.Base}-toggle-button-item-${2}`, label: "Quarterly", value: "quarterly" },
  { key: `${ReportToolbarClassName.Base}-toggle-button-item-${3}`, label: "Custom", value: "custom" },
];

// Map to convert from granularity to option list index
const GRANULARITY_TO_TOGGLE_OPTION = {
  daily: 2,
  monthly: 0,
  quarterly: 1,
} as Record<string, number>;

// Options for the report toolbar radio buttons to switch report types
const REPORT_TOOLBAR_OPTIONS = [
  { id: "client-variable", label: "Client Variable", value: "0" },
  { id: "breakdown", label: "Breakdown", value: "1" },
];

const ReportToolbarNew = (props: ReportToolbarProps): JSX.Element => {
  const { modalVisible, setShowModal, minDates = 1, maxDates = 10, datesPerRow = 5 } = props;

  const [selectedOption, setSelectedOption] = useStateIfMounted("client-variable");
  const [reportToggle, setReportToggle] = useStateIfMounted(0);
  const { ticker } = useContext(TickerContext);
  const {
    csvDownloaded,
    csvLoading,
    downloadError,
    granularity,
    baseDate,
    setBaseDate,
    enableFunds,
    setEnableFunds,
    changeColumns,
    fundThreshold,
    varianceDates,
    getValidDates,
    generateReport,
    setGranularity,
    setChangeColumns,
    setFundThreshold,
    setVarianceDates,
  } = useContext(ReportContext);
  const componentsDisabled = csvLoading || !csvDownloaded || !ticker;

  useEffect(() => {
    /**
     * Note that granularity is used to set custom and non custom, when granularity is set to daily it will switch to custom by default
     * However the context will figure out what granularity should be requested from the report generator based on the dates when it
     * calls the backend
     */
    if (selectedOption == "breakdown") {
      if (TOGGLE_BUTTON_OPTIONS[reportToggle].value == "custom") {
        setGranularity("daily");
      } else {
        setGranularity(TOGGLE_BUTTON_OPTIONS[reportToggle].value);
      }
    } else {
      setGranularity("weekly");
    }
  }, [reportToggle, selectedOption, setGranularity]);

  const renderDatePicker = useCallback(
    (id: string, disabled: boolean, disabledDays: (date: Date) => boolean, onChange: (e: Moment) => void, value: Moment) => {
      return (
        <DatePicker
          id={id}
          key={id}
          className={ReportToolbarClassName.DatePicker}
          theme={DatePickerTheme.Rain}
          disabled={disabled}
          disabledDays={disabledDays}
          onChange={onChange}
          value={value}
        />
      );
    },
    []
  );

  const renderToggleButtons = () => {
    return (
      <ToggleButtons
        id="ReportToolbarToggleButtons"
        dataId="ReportToolbarToggleButtons"
        className={ReportToolbarClassName.Buttons}
        size={ButtonSize.Default}
        items={TOGGLE_BUTTON_OPTIONS}
        disabled={componentsDisabled}
        selected={GRANULARITY_TO_TOGGLE_OPTION[granularity]}
        onChange={(e) => {
          setReportToggle(e);
        }}
      />
    );
  };

  const renderGenerateReportButton = () => {
    if ((!csvLoading && csvDownloaded) || (csvLoading && modalVisible)) {
      const generateReportLabel = "Generate Report";
      return (
        <Button
          className={ReportToolbarClassName.GenerateReportButton}
          label={generateReportLabel}
          icon=""
          loading={csvLoading}
          disabled={!ticker}
          theme={ButtonTheme.Citrus}
          onClick={() => {
            generateReport();
            setShowModal(true);
          }}
        />
      );
    } else {
      let generateReportLabel = "See Report Progress";
      if (!csvLoading && downloadError) {
        generateReportLabel = "See Report Error";
      } else if (!csvLoading && !downloadError) {
        generateReportLabel = "Report Ready";
      }
      return (
        <Button
          className={ReportToolbarClassName.GenerateReportButton}
          label={generateReportLabel}
          icon=""
          theme={ButtonTheme.Citrus}
          onClick={() => setShowModal(true)}
        />
      );
    }
  };
  const renderClientVariable = () => (
    <>
      {renderDatePicker("ReportToolbarTier1DatePicker", componentsDisabled, getValidDates, (e) => setBaseDate(e), baseDate)}
      {renderGenerateReportButton()}
    </>
  );

  const radioButtonOnChange = (_checked: boolean, value: string): void => {
    setSelectedOption(value);
  };
  const renderRadioButtons = () => (
    <div>
      {REPORT_TOOLBAR_OPTIONS.map((option: Record<string, string>) => (
        <label key={option.id} className={ReportToolbarClassName.RadioButtonLabel}>
          <RadioButton
            className={ReportToolbarClassName.RadioButton}
            value={option.id}
            disabled={componentsDisabled}
            onChange={radioButtonOnChange}
            id={option.id}
            name={option.label}
            checked={selectedOption === option.id}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
  const renderCheckbox = (
    id: string,
    label: string,
    checked: boolean,
    disabled: boolean,
    onChange: (checked: boolean) => void
  ) => {
    return (
      <label>
        <Checkbox id={id} disabled={disabled} onChange={onChange} checked={checked} />
        {label}
      </label>
    );
  };
  const renderBreakdown = () => {
    return (
      <>
        {renderDatePicker("ReportToolbarDatePicker", componentsDisabled, getValidDates, setBaseDate, baseDate)}
        {renderToggleButtons()}
        {renderGenerateReportButton()}
      </>
    );
  };
  const renderChipDatePickerList = () => {
    return (
      <GridColumn width="1-of-2" className={ReportToolbarClassName.Dates}>
        <ChipDatePickerList
          minDates={minDates}
          maxDates={maxDates}
          datesPerRow={datesPerRow}
          dates={varianceDates}
          disabled={componentsDisabled}
          setDates={(e) => {
            // Only update state and set to "custom" if the dates have been changed
            // The component fires a callback with no actual date change...
            if (e.length != varianceDates.length || !e.every((v, i) => v.isSame(varianceDates[i], "day"))) {
              setGranularity("daily");
              setVarianceDates(e);
            }
          }}
          maxDate={moment(moment(baseDate).subtract(1, "days"))}
        />
      </GridColumn>
    );
  };
  const renderBreakdownForm = () => {
    return (
      <GridColumn width="1-of-2" className={ReportToolbarClassName.Form}>
        <form>
          {renderCheckbox("column-change", "Change Column", changeColumns, componentsDisabled, (c) => {
            setGranularity("daily");
            setChangeColumns(c);
          })}
          {renderCheckbox("fund", "Fund", enableFunds, componentsDisabled, (c) => {
            setGranularity("daily");
            setEnableFunds(c);
          })}
          <Textbox
            placeholder={"Fund Threshold"}
            value={fundThreshold}
            disabled={!enableFunds || componentsDisabled}
            onChange={(e: string) => {
              if (!isNaN(Number(e))) {
                setGranularity("daily");
                setFundThreshold(Number(e));
              }
            }}
          />
        </form>
      </GridColumn>
    );
  };
  const renderBreakdownMenu = () => {
    return (
      <Grid>
        {renderChipDatePickerList()}
        {renderBreakdownForm()}
      </Grid>
    );
  };
  return (
    <Toolbar className={ReportToolbarClassName.Base} theme={ToolbarTheme.Q4Blue}>
      <Grid className={ReportToolbarClassName.Grid}>
        <GridColumn width="1-of-1" className="tier-1">
          {renderRadioButtons()}
          {selectedOption === "client-variable" ? renderClientVariable() : renderBreakdown()}
        </GridColumn>
        {selectedOption === "breakdown" && (
          <GridColumn width="1-of-1" className="tier-2">
            {renderBreakdownMenu()}
          </GridColumn>
        )}
      </Grid>
    </Toolbar>
  );
};

export default memo(ReportToolbarNew);
