import "./report.view.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { Layout, LayoutPadding, LayoutTheme } from "@q4/nimbus-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { memo, useState } from "react";
import { FeatureFlag } from "../../config";
import { getIsFeatureEnabled } from "../../utils/feature/feature.utils";
import DevLoader from "../root/components/devLoader/DevLoader";
import ReportBanner from "./components/ReportBanner/ReportBanner";
import ReportModal from "./components/ReportModal/ReportModal";
import ReportToolbarContainer from "./components/ReportToolbarContainer/ReportToolbarContainer";
import { ReportClassName, ViewIdModel } from "./report.definition";

const Report = (): JSX.Element => {
  const { isAuthenticated: authenticated, isLoading: loading } = useAuth0();
  const [showModal, setShowModal] = useState(false);
  const features = useFlags();

  const ReportText = <p>This is a featured flagged text block. You should only see this if it its flagged in LD</p>;
  const RenderReportText = getIsFeatureEnabled(features[FeatureFlag.ReportTextTest]) ? ReportText : <div></div>;
  const renderContentArea = () => {
    return (
      <Layout className={ReportClassName.Base} id={ViewIdModel.id} theme={LayoutTheme.PaleGrey} direction="column">
        <div>{RenderReportText}</div>
      </Layout>
    );
  };

  return (
    <Layout className={ReportClassName.Base} padding={LayoutPadding.None} flex={false}>
      <ReportBanner />
      {!authenticated || loading ? (
        <DevLoader interval={2500} />
      ) : (
        <>
          <ReportToolbarContainer modalVisible={showModal} setShowModal={setShowModal} />
          {renderContentArea()}
        </>
      )}
      <ReportModal showModal={showModal} setShowModal={setShowModal} />
    </Layout>
  );
};

export default memo(Report);
