import React, { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { DevLoaderClassName, DevLoaderProps } from "./DevLoader.definition";
import "./DevLoader.scss";

const DevLoader = (props: DevLoaderProps): JSX.Element => {
  const messages = [
    "Reticulating splines",
    "Swapping time and space",
    "Financial Analysts are the best Analysts",
    "Go Phoenix Team!",
    "Is this Cyclops 2.0?",
    "Is this Surveillance 2.0?",
    "Up, Up, Down, Down, Left, Right, Left, Right, B, A",
    "Buy all the Etherium!",
    "Buy all the Bitcoin!",
    "Sell all the Etherium!",
    "Sell all the Bitcoin!",
    "What is Crypto Currency!?",
    "Did you check your stock porfolio today?",
    "DOGE to the Moon!",
    "Please deposit one Bitcoin to continue",
    "Keep calm and buy more Shorts",
    "Spinning violently around the y-axis",
    "Tokenizing real life",
    "Have a good day",
    "Upgrading Windows, your PC will restart several times. Sit back and relax",
    "640K ought to be enough for anybody",
    "The architects are still drafting",
    "(Pay no attention to the man behind the curtain)",
    "Checking the gravitational constant in your locale",
    "The server is powered by a lemon and two electrodes",
    "Please wait while an IR software vendor in Toronto takes over the world",
    "Follow the white rabbit",
    "keep calm and npm install",
    "The bits are flowing slowly today",
    "The squareroot -1 is i",
    "My other loading screen is much faster",
    "Reconfoobling energymotron",
    "Counting backwards from Infinity",
    "Don't panic",
    "Embiggening Prototypes",
    "Creating time-loop inversion field",
    "I'm sorry Dave, I can't do that",
    "All your web browser are belong to us",
    "All I really need is a kilobit",
    "I feel like im supposed to be loading something",
    "What do you call 8 Hobbits? A Hobbyte",
    "Is this Windows?",
    "Is this Linux?",
    "Is this Mac?",
    "Adjusting flux capacitor",
    "The Capital Markets are Open for Business",
    "Keeping all the 1's and removing all the 0's",
    "We need more dilithium crystals",
    "Where did all the internets go",
    "Convincing AI not to turn evil",
    "Computing the secret to life, the universe, and everything",
    "When nothing is going right, go left!!",
    "Nothing is less than something",
    "git happens",
    "May the forks be with you",
    "Constructing additional pylons",
    "Dividing by zero",
    "Web developers do it with <style>",
    "Analyze This",
    "Proving P=NP",
    "Entangling superstrings",
    "Distracted by cat gifs",
    "Unix is user-friendly. It's just very selective about who its friends are",
  ];
  const [message, setMessage] = useStateIfMounted(messages[Math.floor(Math.random() * messages.length)]);
  const [animate, setAnimate] = useStateIfMounted(DevLoaderClassName.Visible);
  useEffect(() => {
    const interval = props.interval ? props.interval : 5000;
    const int = setInterval(() => {
      setAnimate(DevLoaderClassName.Invisible);
      setTimeout(() => {
        setMessage(messages[Math.floor(Math.random() * messages.length)]);
      }, 250);
      setTimeout(() => {
        setAnimate(DevLoaderClassName.Visible);
      }, 500);
    }, interval);
    return () => clearInterval(int);
  });

  return (
    <div className={animate ? `${DevLoaderClassName.Base} ${animate}` : DevLoaderClassName.Base}>
      <div className={`loader-6 ${props.noMargin ? "loader-6--no-margin" : ""}`}>
        <span></span>
      </div>
      {props.disableText ? false : <h1>{message}</h1>}
    </div>
  );
};

export default DevLoader;
