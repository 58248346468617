import { NotificationService } from "@q4/nimbus-ui";
import { RowNode } from "ag-grid-community";
import { isEqual } from "lodash";
import { Fund, AddFundTransaction, FundTransaction } from "q4-platform-common/src/models/fund/fund";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { NavigationContext, TickerContext, NavigationContextUrlSearchParams } from "../../contexts";
import FundService from "../../services/fund/FundService";
import { getEmptyMetadata } from "../../utils/entity/entityUtils";
import { FundContextState, FundProviderProps } from "./fund.definition";

export const FundContext = createContext<Partial<FundContextState>>({});

export const FundProvider = (props: FundProviderProps): JSX.Element => {
  const location = useLocation();
  const [viewMounted, setViewMounted] = useState(false);
  const { searchRef, searchRefHooks, setSearchRefHooks } = useContext(NavigationContext);
  const { ticker, setTickerSelectorDisabled } = useContext(TickerContext);
  const tickerRef = useRef(null);
  const [fund, setFund] = useState(null);
  const fundRef = useRef(null);
  const [fundMetadata, setFundMetadata] = useState<Fund>(null);
  const [fundMetadataLoading, setFundMetadataLoading] = useState<boolean>(false);
  const [fundTransactions, setFundTransactions] = useState<Array<FundTransaction>>(null);
  const [fundTransactionsLoading, setFundTransactionsLoading] = useState<boolean>(false);
  const notificationService = useRef(new NotificationService());
  const fundService = useMemo(() => new FundService(), []);
  const [selectedTransactions, setSelectedTransactions] = useState([]);

  useEffect(() => {
    if (!fund && location.state) {
      setFund(location.state);
    }
  }, [fund, location.state, setFund]);

  useEffect(() => {
    const searchRefHook = (search: React.MutableRefObject<URLSearchParams>) =>
      setFund({ ...fund, ...{ Q4_FUND_ID: search.current.get(NavigationContextUrlSearchParams.Q4_FUND_ID) } });
    searchRefHooks["FUND"] = searchRefHook;
    setSearchRefHooks(searchRefHooks);
  }, [fund, searchRefHooks, setSearchRefHooks]);

  useEffect(() => {
    if (window.location.pathname === "/fund") {
      setTickerSelectorDisabled(!ticker || fundMetadataLoading || fundTransactionsLoading);
    }
  }, [ticker, fund, fundMetadataLoading, fundTransactionsLoading, setTickerSelectorDisabled]);

  const getQ4FundId = useCallback(() => {
    const urlSearchParamsExist =
      searchRef && searchRef.current && searchRef.current?.get(NavigationContextUrlSearchParams.Q4_FUND_ID);
    if (urlSearchParamsExist) {
      return searchRef.current?.get(NavigationContextUrlSearchParams.Q4_FUND_ID);
    }
    return fund.Q4_FUND_ID ? fund.Q4_FUND_ID : fund.Q4_ENTITY_ID;
  }, [searchRef, fund]);

  /**
   * Get Fund Metadata
   */
  const fetchFundMetadata = useCallback(
    async (q4SecurityId: string, entityId: string) => {
      setFundMetadataLoading(true);
      const result = await fundService.getFundMetadata(q4SecurityId, entityId);
      setFundMetadataLoading(false);
      if (result.success && result.data) {
        setFundMetadata(result.data[0]);
      } else {
        setFundMetadata(getEmptyMetadata());
        notificationService.current.info("There was no manager metadata available.");
        console.warn(result.message);
      }
    },
    [fundService]
  );

  const deleteTransactionsData = useCallback(async () => {
    if (ticker && fund) {
      if (selectedTransactions.length) {
        const mappedTransactions = fundTransactions.filter((transaction: FundTransaction) => {
          return !selectedTransactions.some(({ data: selectedTransaction }) => {
            return isEqual(transaction, selectedTransaction);
          });
        });
        setFundTransactions(mappedTransactions);
        notificationService.current.info(
          "The transaction was deleted. It will take a few minutes for the change to apply everywhere"
        );
      } else {
        setFundTransactions([]);
        notificationService.current.info("There were no transactions available.");
      }
    } else {
      console.warn("There were no tickers to update the q4 security ID from.");
    }
    setFundTransactionsLoading(false);
  }, [fund, ticker, selectedTransactions, fundTransactions]);

  const addTransactionsData = useCallback(
    async (fundTransaction: AddFundTransaction) => {
      if (ticker && fund) {
        if (fundTransaction) {
          const transaction: FundTransaction = {
            DATE: fundTransaction.DATE,
            SHARES: fundTransaction.QUANTITY,
            SOURCE: "Custom",
            NOTE: fundTransaction.NOTE,
            CHANGE: 0,
            QTR_CHANGE: 0,
            PRICE: 0,
          };
          setFundTransactions([transaction, ...fundTransactions]);
          notificationService.current.info(
            "The transaction was added. It will take a few minutes for the change to apply everywhere"
          );
        } else {
          notificationService.current.info("There were no transaction available.");
        }
      } else {
        console.error("There were no tickers to update the q4 security ID from.");
      }
      setFundTransactionsLoading(false);
    },
    [fund, ticker, fundTransactions]
  );

  /**
   * Get all Fund Transactions
   */
  const fetchFundTransactions = useCallback(
    async (secId: string, fundId: string) => {
      setFundTransactions(null);
      setFundTransactionsLoading(true);
      const transactions = await fundService.getFundTransactionsData(secId, fundId);
      if (transactions.success && transactions.data && transactions.data.length > 0) {
        setFundTransactions(transactions.data);
      } else {
        setFundTransactions([]);
        transactions.success
          ? notificationService.current.warn("No Fund Transactions Found")
          : notificationService.current.error(`Fund Transactions Failed to Load: ${transactions.message}`);
        console.warn(transactions.message ? transactions.message : "No Fund Transactions Returned");
      }
      setFundTransactionsLoading(false);
    },
    [setFundTransactions, setFundTransactionsLoading, fundService]
  );

  /**
   * Execute all data fetches for current contextual view when upstream contexts change
   */
  useEffect(() => {
    if (ticker && fund) {
      const q4FundId = getQ4FundId();
      const tickerRefDoesNotMatch = tickerRef.current != ticker.Q4_SEC_ID;
      const fundRefDoesNotMatch = fundRef.current != q4FundId;
      if (viewMounted && (tickerRefDoesNotMatch || fundRefDoesNotMatch)) {
        tickerRef.current = ticker.Q4_SEC_ID;
        fundRef.current = q4FundId;
        fetchFundTransactions(ticker.Q4_SEC_ID, q4FundId);
        fetchFundMetadata(ticker.Q4_SEC_ID, q4FundId);
      }
    }
  }, [fund, viewMounted, ticker, tickerRef, fundRef, fetchFundTransactions, fetchFundMetadata, getQ4FundId]);

  const addTransaction = async (fundTransaction: AddFundTransaction) => {
    setFundTransactionsLoading(true);
    const result = await fundService.putFundTransactionsData(fundTransaction);
    if (result.success) {
      addTransactionsData(fundTransaction);
    } else {
      console.warn("There was an error adding new transaction data: result: ", result);
    }
  };

  const deleteTransactionsPromise = async (data: Array<RowNode>): Promise<void> => {
    const transactions = data.map(({ data: transaction }: { data: FundTransaction }) => ({
      DATE: transaction.DATE,
      QUANTITY: transaction.SHARES,
    }));
    const result = await fundService.deleteFundTransactionData(transactions, ticker.Q4_SEC_ID, getQ4FundId());
    if (result.success) {
      return;
    } else {
      notificationService.current.error("Error: ", result.message as unknown as Record<string, unknown>);
      throw new Error("There was an error calling delete transaction");
    }
  };

  const deleteTransactions = async () => {
    notificationService.current.info("Deleting transactions");
    setFundTransactionsLoading(true);
    try {
      await deleteTransactionsPromise(selectedTransactions);
    } catch (error) {
      if (error instanceof Error) {
        notificationService.current.error(error.message);
      }
    } finally {
      deleteTransactionsData();
      notificationService.current.info("Deleting transaction(s) complete");
    }
  };

  const value = {
    fund,
    fundMetadata,
    fundMetadataLoading,
    fundTransactions,
    fundTransactionsLoading,
    selectedTransactions,
    viewMounted,
    setFund,
    setFundMetadata,
    setFundMetadataLoading,
    addTransaction,
    deleteTransactions,
    setSelectedTransactions,
    setViewMounted,
  };
  return <FundContext.Provider value={value}>{props.children}</FundContext.Provider>;
};
