// ! Moving forward we will not be using Skeleton for loading placeholders, there are libraries that handle this better, but this will suffice for now
import { Button, ButtonTheme, Banner, BannerSize, Grid, GridColumn, Skeleton } from "@q4/nimbus-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import React, { useCallback } from "react";
import { FeatureFlag } from "../../../../config";
import { getIsFeatureEnabled } from "../../../../utils/feature/feature.utils";
import CustodianList from "../../../manager/components/CustodianList/CustodianList";
import EditButton from "../Button/EditButton/EditButton";
import ExternalLink from "../ExternalLink/ExternalLink";
import { EntityBannerClassName, EntityBannerProps } from "./EntityBanner.definition";

import "./EntityBanner.scss";

const EntityBanner = (props: EntityBannerProps): JSX.Element => {
  const features = useFlags();
  const showCustodianFeature = getIsFeatureEnabled(features[FeatureFlag.CustodianView]);
  const showPeerFeature = window.location.pathname === "/manager" && getIsFeatureEnabled(features[FeatureFlag.Peers]);
  const {
    loading,
    entityMetadata,
    onEditEntity,
    custodians,
    setShowSectorModal,
    rows = 4,
    size = BannerSize.Medium,
  } = props;
  const getAveragePrice = () => {
    const averagePriceNumberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
    return entityMetadata?.AVERAGE_PRICE ? averagePriceNumberFormat.format(entityMetadata.AVERAGE_PRICE) : false;
  };
  const getEquityAssets = () => {
    const equityAssetsNumberFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
    return entityMetadata?.EQUITY_ASSETS ? `${equityAssetsNumberFormat.format(entityMetadata.EQUITY_ASSETS)} MM` : false;
  };
  const getAccounts = () => {
    const accountsNumberFormat = new Intl.NumberFormat("en-US");
    return entityMetadata?.ACCOUNTS ? accountsNumberFormat.format(entityMetadata.ACCOUNTS) : false;
  };
  const getWithHoldings = () => {
    const holdingsNumberFormat = new Intl.NumberFormat("en-US");
    return entityMetadata?.WITH_HOLDINGS ? holdingsNumberFormat.format(entityMetadata.WITH_HOLDINGS) : false;
  };
  const getType = () => {
    return entityMetadata?.TYPE ? entityMetadata.TYPE : false;
  };
  const getLocation = () => {
    return entityMetadata?.LOCATION ? entityMetadata.LOCATION : false;
  };
  const getLoan = () => {
    if (entityMetadata && "LOAN" in entityMetadata) {
      if (entityMetadata.LOAN) {
        return "True";
      } else {
        return "False";
      }
    }
    return false;
  };
  const getQualityRating = () => {
    const qualityRating = new Intl.NumberFormat("en-US");
    return entityMetadata?.QUALITY_RATING ? qualityRating.format(entityMetadata.QUALITY_RATING) : false;
  };
  const getPercentPortfolio = () => {
    const qualityRating = new Intl.NumberFormat("en-US");
    return entityMetadata?.PERCENT_PORTFOLIO ? qualityRating.format(entityMetadata.PERCENT_PORTFOLIO) : false;
  };
  const getPurchasingPower = () => {
    const purchasingPower = new Intl.NumberFormat("en-US");
    return entityMetadata?.PURCHASING_POWER ? purchasingPower.format(entityMetadata.PURCHASING_POWER) : false;
  };
  const getTurnover = () => {
    return entityMetadata?.TURNOVER ? entityMetadata.TURNOVER : false;
  };
  const getStyle = () => {
    return entityMetadata?.STYLE ? entityMetadata.STYLE : false;
  };
  const getIsSteakholder = () => {
    return entityMetadata?.IS_STAKEHOLDER != undefined && entityMetadata.IS_STAKEHOLDER != null
      ? entityMetadata.IS_STAKEHOLDER.toString()
      : false;
  };
  const getActivist = () => {
    return entityMetadata?.ACTIVIST != undefined && entityMetadata.ACTIVIST != null
      ? entityMetadata.ACTIVIST.toString()
      : false;
  };
  const getCountry = () => {
    return entityMetadata?.COUNTRY ? entityMetadata.COUNTRY : false;
  };
  const getCity = () => {
    return entityMetadata?.CITY ? entityMetadata.CITY : false;
  };
  const getState = () => {
    return entityMetadata?.STATE ? entityMetadata.STATE : false;
  };
  const getManagerStyle = () => {
    return entityMetadata?.MANAGER_STYLE ? entityMetadata.MANAGER_STYLE : false;
  };
  const getInstitutionType = () => {
    return entityMetadata?.INST_TYPE ? entityMetadata.INST_TYPE : false;
  };
  const getOrientation = () => {
    return entityMetadata?.ORIENTATION ? entityMetadata.ORIENTATION : false;
  };
  const getCIK = () => {
    return entityMetadata?.CIK ? entityMetadata.CIK : false;
  };

  const renderManagerInfo = () => {
    const list = [
      { title: "Average Price:", value: getAveragePrice() },
      { title: "Equity Assets:", value: getEquityAssets() },
      { title: "DTC Accounts:", value: getAccounts() },
      { title: "DTC Holdings:", value: getWithHoldings() },
      { title: "Type:", value: getType() },
      { title: "Location:", value: getLocation() },
      { title: "Loan:", value: getLoan() },
      { title: "Quality Rating:", value: getQualityRating() },
      { title: "Purchasing Power:", value: getPurchasingPower() },
      { title: "Percent Portfolio:", value: getPercentPortfolio() },
      { title: "Turnover:", value: getTurnover() },
      { title: "Style:", value: getStyle() },
      { title: "Manager Style:", value: getManagerStyle() },
      { title: "Institution Type:", value: getInstitutionType() },
      { title: "Stakeholder:", value: getIsSteakholder() },
      { title: "Activist:", value: getActivist() },
      { title: "Orientation:", value: getOrientation() },
      { title: "Country:", value: getCountry() },
      { title: "State:", value: getState() },
      { title: "City:", value: getCity() },
      {
        title: "CIK:",
        value: getCIK(),
        render: (value: string, idx: number) => (
          <tr key={`manager-info-${idx}-table-data-content`}>
            <td />
            <td>
              <ExternalLink label="SEC link" url={`https://www.sec.gov/edgar/browse/?CIK=${value}`} />
            </td>
          </tr>
        ),
      },
    ].filter((item) => item.value);

    const matrix = _.chunk(list, rows);
    const getTableData = () => {
      const tableData: Array<JSX.Element> = [];
      for (let m = 0; m < matrix.length; m += 1) {
        tableData.push(
          <td key={`manager-info-${m}-table-data`}>
            <table>
              <tbody>
                {matrix[m].map((n, nIndex) => {
                  return n.render ? (
                    n.render(n.value.toString(), nIndex)
                  ) : (
                    <tr key={`manager-info-${nIndex}-table-data-content`}>
                      <td>{n.title}</td>
                      <td>{n.value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </td>
        );
      }
      return tableData;
    };
    const entityMetadataMarkup = loading ? (
      <>
        <Skeleton style={{ minWidth: "120px", width: "120px", height: "16px", marginBottom: "8px" }} />
        <br />
        <Skeleton style={{ minWidth: "80px", width: "80px", height: "16px", marginBottom: "8px" }} />
      </>
    ) : (
      <>
        <table>
          <tbody>
            <tr>{getTableData()}</tr>
          </tbody>
        </table>
      </>
    );
    return <div className={EntityBannerClassName.ManagerInfo}>{entityMetadataMarkup}</div>;
  };
  const renderCustodianList = () => {
    const custodianListMarkup = !loading ? <CustodianList custodians={custodians} /> : false;
    return showCustodianFeature ? custodianListMarkup : false;
  };

  const renderLeftColumn = () => {
    return (
      <GridColumn width="2-of-3">
        {renderManagerInfo()}
        {custodians?.length > 0 ? renderCustodianList() : false}
      </GridColumn>
    );
  };
  const handleEditButtonClick = useCallback(() => {
    onEditEntity();
  }, [onEditEntity]);
  const renderRightColumn = () => {
    const sectorAnalysisLabel = "Portfolio";
    return (
      <GridColumn width="1-of-3">
        <Grid>
          <GridColumn width="1-of-2">
            {onEditEntity && !loading ? <EditButton onClick={handleEditButtonClick} /> : false}
          </GridColumn>
          <GridColumn width="2-of-2">
            {showPeerFeature && setShowSectorModal && !loading ? (
              <Button theme={ButtonTheme.BlackSmoke} label={sectorAnalysisLabel} onClick={() => setShowSectorModal(true)} />
            ) : (
              false
            )}
          </GridColumn>
        </Grid>
      </GridColumn>
    );
  };

  const renderTitle = () => {
    if (loading) {
      return <Skeleton style={{ width: "200px" }} />;
    }
    return entityMetadata?.NAME ? entityMetadata.NAME : "";
  };
  const renderDetails = () => (
    <Grid>
      {renderLeftColumn()}
      {renderRightColumn()}
    </Grid>
  );
  return (
    <Banner
      className={EntityBannerClassName.Base}
      badgeIcon=""
      title={renderTitle()}
      size={size}
      details={renderDetails()}
    />
  );
};

export default EntityBanner;
