import { NotificationService } from "@q4/nimbus-ui";
import { EntityMetadata } from "q4-platform-common/src/models/entity/entity";
import { ManagerTransaction } from "q4-platform-common/src/models/manager/manager";
import { ManagerForm } from "q4-platform-common/src/models/manager/managerForm";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { NavigateOptions, useLocation } from "react-router-dom";
import { NavigationContext, TickerContext, NavigationContextUrlSearchParams, RefetchContext } from "..";
import { AddManagerTransaction } from "../../../../../src/common/src/models/manager/manager";
import { RoutePath } from "../../configurations/navigation.configuration";
import { REFETCH_SCOPE } from "../../contexts/refetch/refetch.definition";
import CustodianService from "../../services/custodian/CustodianService";
import ManagerService from "../../services/manager/ManagerService";
import { ManagerContextState, ManagerProviderProps } from "./manager.definition";

export const ManagerContext = createContext<Partial<ManagerContextState>>({});

export const ManagerProvider = (props: ManagerProviderProps): JSX.Element => {
  const location = useLocation();
  const [viewMounted, setViewMounted] = useState(false);
  const notificationService = useRef(new NotificationService());
  const managerService = useMemo(() => new ManagerService(), []);
  const custodianService = useMemo(() => new CustodianService(), []);
  const { goto, searchRef, searchRefHooks, setSearchRefHooks } = useContext(NavigationContext);
  const { refetch, addRefetchHook } = useContext(RefetchContext);
  const { ticker, setTickerSelectorDisabled } = useContext(TickerContext);
  const managerRef = useRef<string>(null);
  const tickerRef = useRef<string>(null);

  // Manager data
  const [manager, setManager] = useState(null);
  const [managerLoading, setManagerLoading] = useState(false);
  const [managerMetadata, setManagerMetadata] = useState<EntityMetadata>(null);
  const [managerMetadataLoading, setManagerMetadataLoading] = useState(false);
  // Custodian data
  const [managerCustodians, setManagerCustodians] = useState<Array<EntityMetadata>>(null);
  const [managerCustodiansLoading, setManagerCustodianLoading] = useState(false);
  // Funds data
  const [fundsData, setFundsData] = useState(null);
  const [fundsDataLoading, setFundsDataLoading] = useState(false);
  // Transaction data
  const [selectedTransactions, setSelectedTransactions] = useState<ManagerTransaction[]>([]);
  const [transactionsData, setTransactionsData] = useState<ManagerTransaction[]>(null);
  const [transactionsDataLoading, setTransactionsDataLoading] = useState(false);

  useEffect(() => {
    if (!manager && location.state) {
      setManager(location.state);
    }
  }, [manager, location.state, setManager]);

  useEffect(() => {
    const searchRefHook = (search: React.MutableRefObject<URLSearchParams>) => {
      setManager({ ...manager, ...{ Q4_ENTITY_ID: search.current.get(NavigationContextUrlSearchParams.Q4_ENTITY_ID) } });
    };
    searchRefHooks["MANAGER"] = searchRefHook;
    setSearchRefHooks(searchRefHooks);
  }, [manager, searchRefHooks, setSearchRefHooks]);

  useEffect(() => {
    if (window.location.pathname === "/manager") {
      setTickerSelectorDisabled(
        !ticker || managerMetadataLoading || managerCustodiansLoading || fundsDataLoading || transactionsDataLoading
      );
    }
  }, [
    ticker,
    managerMetadataLoading,
    managerCustodiansLoading,
    fundsDataLoading,
    transactionsDataLoading,
    setTickerSelectorDisabled,
  ]);

  const getQ4EntityId = useCallback(() => {
    const urlSearchParamsExist =
      searchRef && searchRef.current && searchRef.current?.get(NavigationContextUrlSearchParams.Q4_ENTITY_ID);
    if (urlSearchParamsExist) {
      return searchRef.current?.get(NavigationContextUrlSearchParams.Q4_ENTITY_ID);
    }
    return manager.Q4_ENTITY_ID;
  }, [searchRef, manager]);

  const fetchManagerCustodians = useCallback(async () => {
    if (ticker && manager) {
      setManagerCustodianLoading(true);
      const result = await custodianService.getCustodian(ticker.Q4_SEC_ID, getQ4EntityId());
      setManagerCustodianLoading(false);
      if (result.success && result.data) {
        setManagerCustodians(result.data);
      } else {
        setManagerCustodians([]);
        console.warn(result.message);
      }
    } else {
      console.warn("There were no tickers to pull the q4 security ID from.");
    }
  }, [custodianService, manager, ticker, getQ4EntityId]);

  const fetchManagerMetadata = useCallback(async () => {
    if (ticker && manager) {
      setManagerMetadataLoading(true);
      const result = await managerService.getManagerMetadata(ticker.Q4_SEC_ID, getQ4EntityId());
      setManagerMetadataLoading(false);
      if (result.success && result.data) {
        const { data } = result;
        const firstData = (data as unknown as Array<EntityMetadata>)[0];
        setManagerMetadata(firstData);
      } else {
        setManagerMetadata({
          ACCOUNTS: null,
          AVERAGE_PRICE: null,
          EQUITY_ASSETS: null,
          NAME: "",
          WITH_HOLDINGS: null,
        });
        notificationService.current.info("There were no manager metadata available.");
      }
    } else {
      console.warn("There were no tickers to pull the q4 security ID from.");
    }
  }, [manager, managerService, ticker, getQ4EntityId]);

  const fetchTransactionsData = useCallback(async () => {
    if (ticker && manager) {
      setTransactionsDataLoading(true);
      const result = await managerService.getTransactionsData(ticker.Q4_SEC_ID, getQ4EntityId());
      setTransactionsDataLoading(false);
      if (result.success && result.data) {
        setTransactionsData(result.data);
      } else {
        setTransactionsData([]);
        notificationService.current.info("There were no transactions available.");
      }
      setTransactionsDataLoading(false);
    } else {
      console.warn("There were no tickers to pull the q4 security ID from.");
    }
  }, [manager, managerService, ticker, getQ4EntityId]);
  addRefetchHook(REFETCH_SCOPE.MANAGER_TRANSACTIONS, "manager.fetchTransactionsData", fetchTransactionsData);

  const fetchFundsData = useCallback(async () => {
    if (ticker && manager) {
      setFundsDataLoading(true);
      const result = await managerService.getFundsData(ticker.Q4_SEC_ID, getQ4EntityId());
      setFundsDataLoading(false);
      if (result.success && result.data) {
        setFundsData(result.data);
      } else {
        setFundsData([]);
        notificationService.current.info("There were no funds available.");
        console.warn(result.message);
      }
    } else {
      console.warn("There were no tickers to pull the q4 security ID from.");
    }
  }, [ticker, manager, managerService, setFundsDataLoading, getQ4EntityId]);
  addRefetchHook(REFETCH_SCOPE.FUND_TRANSACTIONS, "manager.fetchFundsData", fetchFundsData);

  const addManager = async (managerForm: ManagerForm, q4StockId: string): Promise<boolean> => {
    const result = await managerService.putManager({ manager: managerForm, q4StockId });
    const editMode = !!managerForm.q4EntityId;
    if (result.success) {
      notificationService.current.info(`The manager was ${editMode ? "saved" : "added"}.`);
      const q4EntityId = result.data;
      searchRef.current.set(NavigationContextUrlSearchParams.Q4_ENTITY_ID, q4EntityId);
      if (editMode) await fetchManagerMetadata();
      goto(RoutePath.Manager, searchRef.current, { state: { Q4_ENTITY_ID: q4EntityId } } as NavigateOptions);
      return true;
    } else {
      notificationService.current.error(`Error ${editMode ? "saving" : "adding"} manager. ${result.message}`);
      return false;
    }
  };

  const addTransaction = async (managerTransaction: AddManagerTransaction) => {
    const result = await managerService.putTransactionsData(managerTransaction);
    if (result.success) {
      notificationService.current.info("The transaction was added.");

      await Promise.all(refetch(REFETCH_SCOPE.MANAGER_TRANSACTIONS, getQ4EntityId()));
    } else {
      notificationService.current.error(`Error adding transaction. ${result.message}`);
    }
  };

  const deleteTransactionsPromise = async (data: Array<ManagerTransaction>): Promise<void> => {
    const transactions = data.map((transaction) => ({
      DATE: transaction.DATE,
      QUANTITY: transaction.SHARES,
    }));

    const result = await managerService.deleteTransactionData(transactions, ticker.Q4_SEC_ID, getQ4EntityId());
    if (result.success) {
      return;
    } else {
      throw new Error("There was an error calling delete transaction");
    }
  };

  const deleteTransactions = async () => {
    setTransactionsDataLoading(true);
    try {
      await deleteTransactionsPromise(selectedTransactions);
      notificationService.current.info("Deleting transaction(s) complete");

      await Promise.all(refetch(REFETCH_SCOPE.MANAGER_TRANSACTIONS, getQ4EntityId()));
    } catch (error) {
      if (error instanceof Error) {
        notificationService.current.error(error.message);
        setTransactionsDataLoading(false);
      }
    }
  };

  useEffect(() => {
    if (ticker && manager) {
      const tickerRefDoesNotMatch = !tickerRef.current || tickerRef.current != ticker.Q4_SEC_ID;
      const managerRefDoesNotMatch = !managerRef.current || managerRef.current != manager.Q4_ENTITY_ID;
      if (viewMounted && (tickerRefDoesNotMatch || managerRefDoesNotMatch)) {
        managerRef.current = manager.Q4_ENTITY_ID;
        tickerRef.current = ticker.Q4_SEC_ID;
        fetchManagerMetadata();
        fetchFundsData();
        fetchTransactionsData();
        fetchManagerCustodians();
      }
    }
  }, [
    viewMounted,
    ticker,
    manager,
    fetchManagerMetadata,
    fetchFundsData,
    fetchTransactionsData,
    fetchManagerCustodians,
    setManagerLoading,
  ]);

  const value = {
    manager,
    managerMetadata,
    managerMetadataLoading,
    managerCustodians,
    managerCustodiansLoading,
    fundsData,
    fundsDataLoading,
    transactionsData,
    transactionsDataLoading,
    selectedTransactions,
    viewMounted,
    managerLoading,
    setManager,
    addManager,
    addTransaction,
    deleteTransactions,
    setSelectedTransactions,
    setFundsDataLoading,
    setTransactionsDataLoading,
    setViewMounted,
    fetchTransactionsData,
  };
  return <ManagerContext.Provider value={value}>{props.children}</ManagerContext.Provider>;
};
