import { useAuth0 } from "@auth0/auth0-react";
import { Layout, LayoutPadding, LayoutTheme } from "@q4/nimbus-ui";
import { GetContextMenuItemsParams, RowNode, SelectionChangedEvent } from "ag-grid-community";
import { FilingData } from "q4-platform-common/src/models/filingData/filingData";
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import config from "../../config";
import { FilingContext } from "../../contexts";
import { useFetchData } from "../../hooks/filing/useFetchData";
import AreYouSureModal from "../root/components/Modal/AreYouSureModal/AreYouSureModal";
import SurveillanceGrid from "../root/components/SurveillanceGrid/SurveillanceGrid";
import DevLoader from "../root/components/devLoader/DevLoader";
import FilingBanner from "./components/FilingBanner/FilingBanner";
import FilingToolbar from "./components/FilingToolbar/FilingToolbar";
import { DefaultColDef, ColumnDefs } from "./data/AgGridDefs";
import { FilingClassName, ViewIdModel } from "./filing.definition";
import "./filing.view.scss";

const Filing = (): JSX.Element => {
  const {
    displayData,
    filingGridBusy,
    filingData,
    filingDataLoading,
    includeIgnored,
    putFilingData,
    selectedFilings,
    setSelectedFilings,
    fetchFilingData,
  } = useContext(FilingContext);
  const { isAuthenticated: authenticated, isLoading: loading } = useAuth0();
  const [filingMessage, setFilingMessage] = useState("");
  const [showDeleteFilingModal, setShowDeleteFilingModal] = useState(false);
  const gridRef = useRef(null);
  const noFilingData = displayData.length === 0;
  const ignoredFilings = filingData.filter((filing: FilingData) => filing.ACTION_STATE === "ignore");
  const allOtherFilings = filingData.filter(
    (filing: FilingData) => filing.ACTION_STATE !== "ignore" && filing.ACTION_STATE !== null
  );
  const allFilingsIgnored = ignoredFilings.length === filingData.length;
  const allOtherFilingsProcessed = allOtherFilings.length === filingData.length;

  useFetchData(filingDataLoading, fetchFilingData);

  useEffect(() => {
    return () => {
      setSelectedFilings([]);
    };
  }, [setSelectedFilings]);

  useEffect(() => {
    if (noFilingData) {
      setFilingMessage("There are currently no filing records to display.");
    } else if (allOtherFilingsProcessed) {
      setFilingMessage("All filings are accepted, reconciled or declined for this ticker, there are no filings to show.");
    } else if (!includeIgnored && allFilingsIgnored) {
      setFilingMessage('All filings are ignored, toggle "include ignored" to see them.');
    }
  }, [noFilingData, allOtherFilingsProcessed, allFilingsIgnored, includeIgnored]);

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const nodes: Array<RowNode> = [];
    event.api.forEachNode((node: RowNode) => {
      if (node.isSelected()) {
        nodes.push(node);
      }
    });
    setSelectedFilings(nodes);
  };

  const getContextMenuItems = (params: GetContextMenuItemsParams) => {
    const { node } = params;
    const { data } = node;
    return [
      { name: "Accept", action: () => putFilingData("accept", data), disabled: filingGridBusy },
      { name: "Reconcile", action: () => putFilingData("reconcile", data), disabled: filingGridBusy },
      { name: "Ignore", action: () => putFilingData("ignore", data), disabled: filingGridBusy },
      { name: "Delete", action: () => setShowDeleteFilingModal(true), disabled: filingGridBusy },
    ];
  };

  const getFilingGridMarkup = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const viewingOnProd = config.env === "main" && queryParams.get("demo") == null;
    //  Show an empty div on prod
    if (viewingOnProd) {
      return <div></div>;
    }
    // If the filing grid is not making a network call and there is either no filing data or the existing filing data are processed / ignored and hidden
    if (noFilingData || allOtherFilingsProcessed || (allFilingsIgnored && !includeIgnored)) {
      return (
        <div>
          <h3 className={FilingClassName.NoDataMessage}>{filingMessage}</h3>
        </div>
      );
    }
    // Show the filing grid
    return (
      <SurveillanceGrid
        id={"filing-surveillance-grid"}
        gridRef={gridRef}
        DefaultColDef={DefaultColDef}
        ColumnDefs={ColumnDefs}
        data={displayData}
        onSelectionChanged={onSelectionChanged}
        getContextMenuItems={getContextMenuItems}
        isRowSelectable={() => true}
      />
    );
  };

  const showDevLoader = !authenticated || loading || filingDataLoading || filingGridBusy;
  return (
    <Layout className={FilingClassName.Base} id={ViewIdModel.id} padding={LayoutPadding.None} flex={false}>
      <FilingBanner />
      <AreYouSureModal
        customClassName={"delete-filing-modal"}
        showModal={showDeleteFilingModal}
        setShowModal={setShowDeleteFilingModal}
        callback={() => putFilingData("delete", null)}
      >
        <>
          {`Deleting these filing(s) cannot be reversed.${
            selectedFilings && selectedFilings.length > 1
              ? " If deleting more than one filing the changes will take affect next business day."
              : ""
          }`}
        </>
      </AreYouSureModal>
      {showDevLoader ? (
        <DevLoader interval={2500} />
      ) : (
        <>
          <FilingToolbar
            gridRef={gridRef}
            allOtherFilingsProcessed={allOtherFilingsProcessed}
            setShowDeleteModal={setShowDeleteFilingModal}
          />
          <Layout className={FilingClassName.Base} id={ViewIdModel.id} theme={LayoutTheme.PaleGrey} direction="column">
            {getFilingGridMarkup()}
          </Layout>
        </>
      )}
    </Layout>
  );
};

export default memo(Filing);
