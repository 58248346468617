import { Button, ButtonTheme } from "@q4/nimbus-ui";
import React from "react";
import { ContactButtonClassName, ContactButtonProps } from "./ContactButton.definition";
import "./ContactButton.scss";

const ContactButton = (props: ContactButtonProps): JSX.Element => {
  const { id, disabled, onClick } = props;
  return (
    <Button
      id={id}
      className={ContactButtonClassName.Base}
      icon={"q4i-contact-2pt"}
      theme={ButtonTheme.Q4Blue}
      label={""}
      disabled={disabled}
      onClick={() => {
        (document.activeElement as HTMLElement).blur();
        onClick();
      }}
    />
  );
};

export default ContactButton;
