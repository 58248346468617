import type { BaseComponentProps } from "@q4/nimbus-ui";
import { Moment } from "moment";

export interface ChipDatePickerProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  datePickerDisabled?: boolean;
  datePickerValue?: Moment;
  chipValue?: string;
  chipData?: Record<string, unknown> | string;
  disabledDays?: (date: Date) => boolean;
  datePickerOnChange?: (moment: Moment) => void;
  chipOnRemove?: (value: string, data: unknown) => void;
}

export enum ChipDatePickerClassName {
  Base = "chip-date-picker",
  DatePicker = "chip-date-picker__date-picker",
}
