export const Turnovers = ["High", "Low", "Medium", "Very High", "Very Low"];

export const Styles = [
  "Aggressive Growth",
  "Alternative",
  "Deep Value",
  "GARP",
  "Growth",
  "Hedge Fund",
  "Index",
  "Private Equity",
  "Value",
  "Yield",
];

export const ManagerStyles = [
  "Aggressive Growth",
  "Alternative",
  "Broker/Dealer",
  "Deep Value",
  "GARP",
  "Generalist",
  "Growth",
  "Hedge Fund",
  "Income",
  "Index",
  "Private Equity",
  "Specialty",
  "Value",
];

export const InstitutionTypes = [
  "Arbitrage",
  "Bank Investment Division",
  "Broker",
  "Corporate",
  "Family Office",
  "Foundation/Endowment Manager",
  "Fund Distributor",
  "Fund of Funds Manager",
  "Fund of Hedge Funds Manager",
  "Fund",
  "Govt (Fed/Local/Agency)",
  "Hedge Fund Manager",
  "Insurance Company",
  "Investment Adviser",
  "Investment Company",
  "Market Maker",
  "Master Ltd Part",
  "Mutual Fund Manager",
  "Pension Fund Manager",
  "Private Banking/Wealth Mgmt",
  "Real Estate Manager",
  "Research Firm",
  "Sovereign Wealth Manager",
  "Stock Borrowing/Lending",
  "Subsidiary Branch",
  "Venture Capital/Pvt Equity",
];
