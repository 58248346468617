import { NotificationService } from "@q4/nimbus-ui";
import moment, { Moment } from "moment";
import { CustodianWeekly, CustodianVariance } from "q4-platform-common/src/models/custodian/custodian";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import CustodianService from "../../services/custodian/CustodianService";
import StockDataService from "../../services/stockData/StockDataService";
import { getStockPriceActivityDisplayData } from "../../utils/stockData/stock-data.utils";
import { TickerContext } from "../ticker/ticker.context";
import { DTCCContextState, DTCCProviderProps, VIEW_MODES } from "./dtcc.definition";

export const DTCCContext = createContext<Partial<DTCCContextState>>({});

export const DTCCProvider = (props: DTCCProviderProps): JSX.Element => {
  const [viewMounted, setViewMounted] = useState(false);
  const { ticker, setTickerSelectorDisabled } = useContext(TickerContext);
  const notificationService = useRef(new NotificationService());
  const custodianService = useMemo(() => new CustodianService(), []);
  const stockDataService = useMemo(() => new StockDataService(), []);
  const [DTCCStockPriceActivityData, setDTCCStockPriceActivityData] = useState([]);
  const [DTCCStockPriceActivityDisplayData, setDTCCStockPriceActivityDisplayData] = useState([]);
  const [DTCCStockPriceActivityDataLoading, setDTCCStockPriceActivityDataLoading] = useState(true);
  const [DTCCustodiansLoading, setDTCCustodiansLoading] = useState<boolean>(true);
  const [uploadDTCCLoading, setUploadDTCCLoading] = useState<boolean>(false);
  const [DTCCustodiansVarianceLoading, setDTCCustodiansVarianceLoading] = useState<boolean>(false);
  const [DTCCustodians, setDTCCustodians] = useState<Array<CustodianWeekly>>(null);
  const [DTCCustodiansOversized, setDTCCustodiansOversized] = useState<Array<string>>(null);
  const [DTCCustodiansVariance, setDTCCustodiansVariance] = useState<Array<CustodianVariance>>(null);
  const [baseDate, setBaseDate] = useState<Moment>(null);
  const [varianceDate, setVarianceDate] = useState<Moment>(moment());
  const [latestDate, setLatestDate] = useState<Moment>(null);
  const [dtccViewMode, setDtccViewMode] = useState<VIEW_MODES>(VIEW_MODES.BASE);
  const [overviewDays, setOverviewDays] = useState<number>(5);

  useEffect(() => {
    if (window.location.pathname === "/dtcc") {
      setTickerSelectorDisabled(!ticker || DTCCustodiansLoading || uploadDTCCLoading || DTCCustodiansVarianceLoading);
    }
  }, [ticker, DTCCustodiansLoading, uploadDTCCLoading, DTCCustodiansVarianceLoading, setTickerSelectorDisabled]);

  /**
   * Get all DTCC Custodians with one week of data
   */
  const fetchDTCCustodians = useCallback(async () => {
    setDTCCustodiansLoading(true);
    setDTCCustodians(null);
    setDTCCustodiansOversized([]);
    const overview = await custodianService.getCustodiansWeekly(ticker.Q4_SEC_ID, baseDate, overviewDays);
    if (overview.success && overview.data && overview.data[0]?.length > 0) {
      setDTCCustodians(overview.data[0]);
      setDTCCustodiansOversized(overview.data[1]);
    } else {
      setDTCCustodians([]);
      overview.success
        ? notificationService.current.warn("No DTCC was found")
        : notificationService.current.error(`DTCC failed ${overview.message}`);
      console.warn(overview.message ? overview.message : "No DTCC Data returned");
    }
    setDTCCustodiansLoading(false);
  }, [custodianService, ticker, baseDate, overviewDays]);

  /**
   * Get DTCC Custodians Variance data
   */
  const fetchDTCCustodiansVariance = useCallback(async () => {
    if (ticker?.Q4_SEC_ID) {
      setDTCCustodiansVarianceLoading(true);
      setDTCCustodiansVariance(null);
      const q4StockId = ticker.Q4_SEC_ID;
      const result = await custodianService.getCustodiansVariance(q4StockId, varianceDate, baseDate);
      if (result.success && result.data && result.data.length > 0) {
        const data = result.data.map((i: CustodianVariance) => {
          return {
            Q4_CUSTODIAN_ID: i.Q4_CUSTODIAN_ID,
            DTC_ID: i.DTC_ID,
            NAME: i.NAME,
            TYPE: i.TYPE,
            VARIANCE_QUANTITY: i.VARIANCE_QUANTITY,
            BASE_QUANTITY: i.BASE_QUANTITY,
            START_DATE: `${varianceDate.year()}-${varianceDate.month() + 1}-${varianceDate.date()}`,
            END_DATE: `${baseDate.year()}-${baseDate.month() + 1}-${baseDate.date()}`,
            DIFF: i.DIFF,
            DIFFPER: i.DIFFPER,
          };
        });
        setDTCCustodiansVariance(data);
      } else {
        setDTCCustodiansVariance([]);
        result.success
          ? notificationService.current.warn("No DTCC Variance was found")
          : notificationService.current.error(`DTCC Variance failed ${result.message}`);
        console.warn(result.message ? result.message : "No DTCC Variance Data returned");
      }
      setDTCCustodiansVarianceLoading(false);
    } else {
      console.warn("Unable to get Q4_STOCK_ID from custodians");
    }
  }, [ticker, custodianService, varianceDate, baseDate]);

  const fetchLatestDate = useCallback(
    async (secId) => {
      const latestDateRaw = await custodianService.getCustodianLatestDate(secId);
      if (latestDateRaw.data && latestDateRaw.data.length > 0) {
        const latestDateM = moment(latestDateRaw.data[0].DATE);
        setLatestDate(latestDateM);
        if (!baseDate || baseDate > latestDateM) {
          setBaseDate(latestDateM);
          setVarianceDate(moment(latestDateM).subtract(1, "week"));
        }
      } else {
        console.warn("No data available for this Ticker");
      }
    },
    [baseDate, custodianService]
  );

  /**
   * Put DTCC into the db
   */
  const putDTCC = useCallback(
    async (file: string) => {
      setUploadDTCCLoading(true);
      const res = await custodianService.putCustodianDTCFile(file);
      if (res.success) {
        // Due to how quick it is to get the data we simply refetch the data
        notificationService.current.info("DTCC Uploaded");
        setUploadDTCCLoading(false);
        return true;
      } else {
        notificationService.current.error(`DTCC Upload Error: ${res.message}`);
        setUploadDTCCLoading(false);
        return false;
      }
    },
    [custodianService]
  );

  const shouldFectch = useMemo(() => {
    return viewMounted && ticker && ticker.Q4_SEC_ID && baseDate;
  }, [viewMounted, ticker, baseDate]);

  const fetchDTCCStockPriceActivity = useCallback(async () => {
    if (shouldFectch) {
      const { Q4_TICKER_ID } = ticker;
      setDTCCStockPriceActivityDataLoading(true);
      const result = await stockDataService.getStockSummary(
        Q4_TICKER_ID,
        `${baseDate.year()}-${baseDate.month() + 1}-${baseDate.date()}`.toString(),
        16,
        "ASC"
      );
      setDTCCStockPriceActivityDataLoading(false);
      if (result.success && result.data && result.data.length > 0) {
        setDTCCStockPriceActivityData(result.data);
      } else {
        setDTCCStockPriceActivityData([]);
        result.success
          ? notificationService.current.warn("No DTCC Stock Summary was found")
          : notificationService.current.error(`DTCC Stock Summary failed ${result.message}`);
        console.warn(result.message ? result.message : "No DTCC Stock Summary Data returned");
      }
    }
  }, [shouldFectch, baseDate, ticker, stockDataService]);

  /**
   * Execute all data fetches for current contextual view when upstream contexts change
   */

  useEffect(() => {
    if (viewMounted && ticker && ticker.Q4_SEC_ID) {
      if (!latestDate) {
        fetchLatestDate(ticker.Q4_SEC_ID);
      }
    }
  }, [viewMounted, ticker, latestDate, fetchLatestDate]);

  useEffect(() => {
    if (shouldFectch && dtccViewMode == VIEW_MODES.BASE) {
      fetchDTCCustodians();
    }
  }, [shouldFectch, dtccViewMode, fetchDTCCustodians]);

  useEffect(() => {
    if (shouldFectch && dtccViewMode == VIEW_MODES.VARIANCE) {
      fetchDTCCustodiansVariance();
    }
  }, [shouldFectch, dtccViewMode, fetchDTCCustodiansVariance]);

  useEffect(() => {
    fetchDTCCStockPriceActivity();
  }, [fetchDTCCStockPriceActivity]);

  useEffect(() => {
    if (viewMounted && DTCCStockPriceActivityData) {
      setDTCCStockPriceActivityDisplayData(getStockPriceActivityDisplayData(DTCCStockPriceActivityData));
    }
  }, [viewMounted, DTCCStockPriceActivityData, setDTCCStockPriceActivityDisplayData]);

  const value = {
    latestDate,
    baseDate,
    varianceDate,
    DTCCustodiansLoading,
    uploadDTCCLoading,
    DTCCustodiansVarianceLoading,
    DTCCustodians,
    DTCCustodiansOversized,
    DTCCustodiansVariance,
    viewMounted,
    dtccViewMode,
    overviewDays,
    DTCCStockPriceActivityData,
    DTCCStockPriceActivityDisplayData,
    DTCCStockPriceActivityDataLoading,
    setDtccViewMode,
    setBaseDate,
    setVarianceDate,
    setViewMounted,
    setOverviewDays,
    putDTCC,
  };
  return <DTCCContext.Provider value={value}>{props.children}</DTCCContext.Provider>;
};
