import "./home.view.scss";
import {
  Button,
  Grid,
  GridColumn,
  Layout,
  LayoutPadding,
  LayoutTheme,
  NotificationService,
  Origin,
  Popover,
  Select,
  SelectTheme,
} from "@q4/nimbus-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment from "moment";
import { Shareholder } from "q4-platform-common/src/models/shareholder/shareholder";
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { FeatureFlag } from "../../config";
import { HomeContext, TickerContext } from "../../contexts";
import AddButton from "../root/components/Button/AddButton/AddButton";
import FilterButton from "../root/components/Button/FilterButton/FilterButton";
import LeftArrowButton from "../root/components/Button/LeftArrowButton/LeftArrowButton";
import ToggleButton from "../root/components/Button/ToggleButton/ToggleButton";
import GridToolbar from "../root/components/GridToolbar/GridToolbar";
import EditManagerModal from "../root/components/Modal/EditManagerModal/EditManagerModal";
import SurveillanceGrid from "../root/components/SurveillanceGrid/SurveillanceGrid";
import DevLoader from "../root/components/devLoader/DevLoader";
import HomeBanner from "./components/HomeBanner/HomeBanner";
import {
  DefaultColDef,
  ColumnDefs,
  ColumnDefsReportType,
  DTCIncreasesColumnDefs,
  DTCDecreasesColumnDefs,
  StockPriceColumnDefs,
  getInstutionalHolderClassRules,
  TopNetBuyersColumnDefs,
  TopNetSellersColumnDefs,
} from "./data/AgGridDefs";
import {
  filterMap,
  FilterOptionType,
  FilterState,
  HomeClassName,
  DEF_TOP_OPTIONS,
  DEF_WEEKS,
  TOP_OPTIONS,
  WEEK_OPTIONS,
} from "./home.definition";

window.moment = moment;

const Home = (): JSX.Element => {
  const features = useFlags();
  const {
    shareholderData,
    shareholderDataLoading,
    shareholderMovementData,
    shareholderMovementDataLoading,
    custodianMovementData,
    homeStockPriceActivityData,
    custodianMovementDataLoading,
    homeStockPriceActivityDataLoading,
    fetchCustodianMovementData,
    fetchShareholderMovementData,
    setViewMounted,
    showStockPriceGrid,
    showTopDTCActivityGrid,
    showTopNetActivityGrid,
    showInstitutionalHolderGrid,
    setShowStockPriceGrid,
    setShowTopDTCActivityGrid,
    setShowTopNetActivityGrid,
    setShowInstitutionalHolderGrid,
  } = useContext(HomeContext);
  const { ticker } = useContext(TickerContext);
  const [filterState, setFilterState] = useState<FilterState>({});
  const [filteredSharedholderData, setFilteredShareholderData] = useState<Shareholder[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [showAddManagerModal, setShowAddManagerModal] = useState(false);

  const transactionsGridRef = useRef(null);
  const stockPriceActivityGridRef = useRef(null);

  const topNetActivityGridRef = useRef(null);

  const topNetDTCActivityGridRef = useRef(null);
  const [topFilterOption, setTopFilterOption] = useState(DEF_TOP_OPTIONS);
  const [topNetActivityWeeks, setTopNetActivityWeeks] = useState(DEF_WEEKS);
  const [topDTCActivityWeeks, setTopDTCActivityWeeks] = useState(DEF_WEEKS);
  const hasLeftColumn = showStockPriceGrid || showTopDTCActivityGrid || showTopNetActivityGrid;
  const showSummaryEnhancements =
    features[FeatureFlag.SummaryEnhancements] &&
    (features[FeatureFlag.SummaryStockPriceActivity] || features[FeatureFlag.SummaryTopDTCActivity]);

  useEffect(() => {
    setViewMounted(true);
    return () => {
      setViewMounted(false);
    };
  }, [setViewMounted]);

  const renderStockPriceActivity = () => {
    return (
      <GridColumn className={"top-stock-activity-grid"} width="1-of-1">
        <GridToolbar
          leftContent="Stock Price Activity (28 Trading Days)"
          rightContent={null}
          gridRef={stockPriceActivityGridRef}
        />
        <SurveillanceGrid
          id={"stock-price-activity-surveillance-grid"}
          gridRef={stockPriceActivityGridRef}
          DefaultColDef={DefaultColDef}
          ColumnDefs={StockPriceColumnDefs}
          data={homeStockPriceActivityData}
        />
      </GridColumn>
    );
  };

  const renderBannerDashboardButton = (
    id: string,
    label: string,
    checked: boolean,
    disabled: boolean,
    onClick: (checked: boolean) => void
  ) => {
    return <ToggleButton id={id} label={label} checked={checked} onChange={() => onClick(checked)} disabled={disabled} />;
  };

  const renderTopFilter = (id: string, top: number, onChange: (top: number) => void, loading: boolean) => {
    return (
      <div id={id} className="nui-select_filter">
        <span>Top: </span>
        <Select
          theme={SelectTheme.Steel}
          placeholder={top}
          onChange={(e: number) => onChange(e)}
          options={TOP_OPTIONS}
          disabled={loading}
        />
      </div>
    );
  };

  const renderWeeksFilter = (id: string, weeks: number, onChange: (weeks: number) => void, loading: boolean) => {
    return (
      <div id={id} className="nui-select_filter">
        <span>Weeks: </span>
        <Select
          theme={SelectTheme.Steel}
          placeholder={weeks}
          onChange={(e: number) => onChange(e)}
          options={WEEK_OPTIONS}
          disabled={loading}
        />
      </div>
    );
  };

  const renderActivityFilters = () => {
    return (
      <>
        {renderTopFilter(
          "top-net-activity",
          topFilterOption,
          (top: number) => {
            setTopFilterOption(top);
            fetchShareholderMovementData(topNetActivityWeeks, top);
          },
          shareholderMovementDataLoading
        )}
        {renderWeeksFilter(
          "top-net-activity",
          topNetActivityWeeks,
          (weeks: number) => {
            setTopNetActivityWeeks(weeks);
            fetchShareholderMovementData(weeks, topFilterOption);
          },
          shareholderMovementDataLoading
        )}
      </>
    );
  };

  const topNetActivityData = useMemo(() => {
    return {
      topBuyers: shareholderMovementData?.filter((value) => value.CHANGE > 0).sort((a, b) => b.CHANGE - a.CHANGE) || [],
      topSellers: shareholderMovementData?.filter((value) => value.CHANGE < 0).sort((a, b) => a.CHANGE - b.CHANGE) || [],
    };
  }, [shareholderMovementData]);

  const renderTopNetActivity = () => {
    return (
      <GridColumn className="top-net-activity-grid" width="1-of-1">
        <GridToolbar leftContent="Top Net Activity" rightContent={renderActivityFilters()} gridRef={topNetActivityGridRef} />
        <SurveillanceGrid
          id={"top-net-increase-activity-surveillance-grid"}
          className="top-net-surveillance-grid-buyers"
          gridRef={topNetActivityGridRef}
          DefaultColDef={DefaultColDef}
          ColumnDefs={TopNetBuyersColumnDefs}
          disableLoaderText={true}
          data={topNetActivityData.topBuyers}
          overlayNoRowsTemplate={"No Data Available"}
        />
        <SurveillanceGrid
          id={"top-net-decrease-activity-surveillance-grid"}
          className="top-net-surveillance-grid-sellers"
          gridRef={topNetActivityGridRef}
          DefaultColDef={DefaultColDef}
          ColumnDefs={TopNetSellersColumnDefs}
          disableLoaderText={true}
          data={topNetActivityData.topSellers}
          overlayNoRowsTemplate={"No Data Available"}
        />
      </GridColumn>
    );
  };
  const renderTopDTCActivity = () => {
    const increasingDtcBase =
      custodianMovementData && !custodianMovementDataLoading
        ? custodianMovementData.filter((value) => value.NET_CHG > 0)
        : null;
    const decreasingDtcBase =
      custodianMovementData && !custodianMovementDataLoading
        ? custodianMovementData.filter((value) => value.NET_CHG < 0).sort((a, b) => a.NET_CHG - b.NET_CHG)
        : null;
    const increasingDtc = increasingDtcBase && increasingDtcBase.length > 0 ? increasingDtcBase : [];
    const decreasingDtc = decreasingDtcBase && decreasingDtcBase.length > 0 ? decreasingDtcBase : [];
    return (
      <GridColumn className="top-dtc-activity-grid" width="1-of-1">
        <GridToolbar
          leftContent="Top DTC Activity"
          rightContent={renderWeeksFilter(
            "top-dtc-activity",
            topDTCActivityWeeks,
            (weeks: number) => {
              setTopDTCActivityWeeks(weeks);
              fetchCustodianMovementData(weeks);
            },
            custodianMovementDataLoading
          )}
          gridRef={topNetDTCActivityGridRef}
        />
        <SurveillanceGrid
          id={"top-dtc-increase-activity-surveillance-grid"}
          className="top-dtc-increase-surveillance-grid"
          gridRef={topNetDTCActivityGridRef}
          DefaultColDef={DefaultColDef}
          ColumnDefs={DTCIncreasesColumnDefs}
          disableLoaderText={true}
          data={increasingDtc}
          overlayNoRowsTemplate={"No Data Available"}
        />
        <SurveillanceGrid
          id={"top-dtc-decrease-activity-surveillance-grid"}
          className="top-dtc-decrease-surveillance-grid"
          gridRef={topNetDTCActivityGridRef}
          DefaultColDef={DefaultColDef}
          ColumnDefs={DTCDecreasesColumnDefs}
          disableLoaderText={true}
          data={decreasingDtc}
          overlayNoRowsTemplate={"No Data Available"}
        />
      </GridColumn>
    );
  };
  const renderLeftColumn = () => {
    const columnSize = !showInstitutionalHolderGrid ? "5" : hasLeftColumn ? "2" : "0";
    return (
      <GridColumn width={`${columnSize}-of-5`}>
        <Grid className={HomeClassName.LeftGrid}>
          {features[FeatureFlag.SummaryStockPriceActivity] && showStockPriceGrid && renderStockPriceActivity()}
          {showTopNetActivityGrid && renderTopNetActivity()}
          {features[FeatureFlag.SummaryTopDTCActivity] && showTopDTCActivityGrid && renderTopDTCActivity()}
        </Grid>
      </GridColumn>
    );
  };

  /**
   * Filtering Logic is implemented as part of this file
   */

  const renderFilterSelector = (name: string, options: FilterOptionType[]) => {
    return (
      <Select
        theme={SelectTheme.Steel}
        valueKey="label"
        placeholder={name}
        onChange={(e: FilterOptionType) => e.action()}
        options={options}
        disabled={shareholderDataLoading}
      />
    );
  };

  const renderAddManagerModal = () => {
    return (
      <EditManagerModal q4StockId={ticker.Q4_SEC_ID} showModal={showAddManagerModal} setShowModal={setShowAddManagerModal} />
    );
  };

  const handleAddButtonClick = useCallback(() => {
    setShowAddManagerModal(true);
  }, []);

  const selectors = Object.keys(filterMap).map((k) => {
    const fK = k as keyof typeof filterMap;
    return renderFilterSelector(
      fK,
      Object.keys(filterMap[fK]).map((dK) => filterMap[fK][dK])
    );
  });

  selectors.push(<Button label="Clear" onClick={() => setFilterState({})} />);
  const renderInstitutionalHolderTitle = () => {
    return (
      <div>
        <div>
          <LeftArrowButton
            direction={hasLeftColumn ? "left" : "right"}
            onClick={() => {
              setShowStockPriceGrid(!hasLeftColumn);
              setShowTopDTCActivityGrid(!hasLeftColumn);
              setShowTopNetActivityGrid(!hasLeftColumn);
            }}
          />
          {"Institutional Holder"}
        </div>
      </div>
    );
  };
  const renderInstitutionalHolderToolbarContent = () => {
    return (
      <div>
        <AddButton onClick={handleAddButtonClick} />
        <div id="home-inst-holders-filter-button">
          <FilterButton disabled={shareholderDataLoading} onClick={() => setShowFilters(!showFilters)} />
          <Popover
            className="home-institution-filters"
            visible={showFilters}
            onCloseRequest={() => setShowFilters(false)}
            anchorTargetElementId={"home-inst-holders-filter-button"}
            targetOrigin={Origin.BottomLeft}
            popoverOrigin={Origin.TopLeft}
          >
            {selectors}
          </Popover>
        </div>
      </div>
    );
  };
  useEffect(() => {
    /**
     * This effect maps the shareholder data to the filterMap as well as filters the data using the map
     * This is done by filtering the data based on the keys provided in the filterMap which should correspond
     * with the columns in the data. It finds all possible values of these fields automatically and creates
     * selector options for them. This is used to populate the above selector rendering system to show the various
     * filterable columns.
     */
    Object.keys(filterMap).forEach((k) => {
      // We clear any outdated filterMap first
      const fK = k as keyof typeof filterMap;
      filterMap[fK] = {};
    });
    shareholderData && // Then we get the valid options for our filterMap from the base data
      shareholderData.map((datum) => {
        Object.keys(filterMap).forEach((k) => {
          const fK = k as keyof typeof filterMap; // Filter Map Key
          const dK = k as keyof Shareholder; // Shareholder Data Key
          if (Object.keys(datum).includes(dK) && datum[dK] != null && datum[dK] != undefined) {
            filterMap[fK][datum[dK].toString()] = {
              label: datum[dK].toString(),
              action: () => {
                setFilterState((prevState) => ({
                  ...prevState,
                  [fK]: datum[dK].toString(),
                }));
              },
            };
          }
        });
      });
    /**
     * The filter step uses the filters loaded into the filterMap from the above loop to only display data
     * that is selected by the filterState. The filterState is set whenever an option is selected from the options
     * generated by the above mapping.
     */
    const filteredData = shareholderData
      ? shareholderData.filter((datum) => {
          return Object.keys(filterState)
            .map((filterKey) => {
              const dK = filterKey as keyof Shareholder;
              const filter = filterState[filterKey];
              return (
                Object.keys(datum).includes(dK) &&
                datum[dK] != null &&
                datum[dK] != undefined &&
                datum[dK].toString() == filter
              );
            })
            .reduce((prev, curr) => prev && curr, true);
        })
      : [];
    if (shareholderData && filteredData && filteredData.length == 0 && shareholderData.length != 0) {
      new NotificationService().warn("Filter has removed all institutions, please clear it");
    }
    setFilteredShareholderData(filteredData);
  }, [filterState, shareholderData]);

  const renderRightColumn = () => {
    const columnSize = hasLeftColumn ? "3" : "5";
    const rightColumnWidth = showSummaryEnhancements ? `${columnSize}-of-5` : "1-of-1";
    if (ticker && !shareholderDataLoading && shareholderData && shareholderData.length === 0) {
      const message = "There is no shareholder data available for this particular ticker.";
      return (
        <GridColumn className={`${HomeClassName.InstitutionalHolder} no-data`} width={rightColumnWidth}>
          {message}
        </GridColumn>
      );
    }
    return (
      <GridColumn className={HomeClassName.InstitutionalHolder} width={rightColumnWidth}>
        <GridToolbar
          leftContent={renderInstitutionalHolderTitle()}
          rightContent={renderInstitutionalHolderToolbarContent()}
          gridRef={transactionsGridRef}
          showDownloadDataButton={true}
        />
        <Layout theme={LayoutTheme.PaleGrey} direction="column">
          <SurveillanceGrid
            id={"home-surveillance-grid"}
            gridRef={transactionsGridRef}
            DefaultColDef={DefaultColDef}
            ColumnDefs={features[FeatureFlag.ReportPositionTypeColumn] ? ColumnDefsReportType : ColumnDefs}
            data={filteredSharedholderData}
            rowClassRules={getInstutionalHolderClassRules}
            disableLoaderText={true}
          />
        </Layout>
      </GridColumn>
    );
  };
  let customClassName = HomeClassName.Base as string;
  if (showSummaryEnhancements) {
    customClassName += ` ${HomeClassName.SummaryEnhancements}`;
  }
  const renderAllModals = () => {
    return <>{showAddManagerModal ? renderAddManagerModal() : false}</>;
  };

  return (
    <Layout className={customClassName} padding={LayoutPadding.None} flex={false}>
      <HomeBanner>
        <Grid className="home-banner-buttons-container">
          {renderBannerDashboardButton(
            "stockPriceDashboardButton",
            "Stock Price Activity",
            showStockPriceGrid,
            false,
            () => {
              setShowStockPriceGrid(!showStockPriceGrid);
            }
          )}
          {renderBannerDashboardButton(
            "topNetActivityDashboardButton",
            "Top Net Activity ",
            showTopNetActivityGrid,
            false,
            () => {
              setShowTopNetActivityGrid(!showTopNetActivityGrid);
            }
          )}
          {renderBannerDashboardButton(
            "topDTCActivityDashboardButton",
            "Top DTC Activity",
            showTopDTCActivityGrid,
            false,
            () => {
              setShowTopDTCActivityGrid(!showTopDTCActivityGrid);
            }
          )}
          {renderBannerDashboardButton(
            "institutionalHolderDashboardButton",
            "Institutional Holder",
            showInstitutionalHolderGrid,
            false,
            () => {
              setShowInstitutionalHolderGrid(!showInstitutionalHolderGrid);
            }
          )}
        </Grid>
      </HomeBanner>
      {!ticker ? (
        <DevLoader interval={2500} />
      ) : (
        <Grid className={HomeClassName.Grid}>
          {showSummaryEnhancements && !homeStockPriceActivityDataLoading && renderLeftColumn()}
          {!homeStockPriceActivityDataLoading && showInstitutionalHolderGrid && renderRightColumn()}
        </Grid>
      )}
      {renderAllModals()}
    </Layout>
  );
};

export default memo(Home);
