import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface HomeBannerProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
}

export enum HomeBannerClassName {
  Base = "home-banner",
  Options = "home-banner_options",
}
