import { NotificationService } from "@q4/nimbus-ui";
import React, { SyntheticEvent, useContext, useState, useRef, useMemo } from "react";
import { NavigationContext, TickerContext, NavigationContextUrlSearchParams } from "../../../../../contexts";
import ManagerService from "../../../../../services/manager/ManagerService";
import { TransactionsColumnDefs, TransactionsDefaultColDef } from "../../../../manager/data/AgGridDefs";
import SurveillanceGridModal from "../../Modal/SurveillanceGridModal/SurveillanceGridModal";
import { NameCellProps } from "./NameCell.definition";
import "./NameCell.scss";

export interface Params<T> {
  params: T;
}

const NameCell = (props: NameCellProps): React.ReactFragment => {
  const { searchRef, goto } = useContext(NavigationContext);
  const { ticker } = useContext(TickerContext);
  const managerService = useMemo(() => new ManagerService(), []);
  const notificationService = useRef(new NotificationService());
  const [showModal, setShowModal] = useState(false);
  const { value, colDef, data } = props;
  const { cellRendererParams } = colDef;
  const { path, urlSearchParamsFormatter = null, preview = false } = cellRendererParams;
  let onClick = (event: SyntheticEvent) => {
    event.preventDefault();
    if (path) {
      if (urlSearchParamsFormatter) {
        urlSearchParamsFormatter(searchRef, data); // mutates searchRef
      }
      goto(
        path,
        searchRef.current,
        {
          state: data, // # data could be a manager or custodian for e.g.
        },
        true
      );
    }
  };

  const q4EntityId = data.Q4_ENTITY_ID as string;
  const name = (data.MANAGER as string) || (data.NAME as string) || (data.INST_NAME as string);

  if (preview) {
    onClick = () => setShowModal(true);
  }
  return value ? (
    <>
      <a onClick={onClick}>{value}</a>
      <SurveillanceGridModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={"Manager Preview"}
        subtitle={name}
        getData={function (): [] | Promise<[]> | Promise<unknown[]> {
          const fetchData = async () => {
            const transactionData = await managerService.getTransactionsData(ticker.Q4_SEC_ID, q4EntityId);
            if (transactionData.success && transactionData.data) {
              return transactionData.data;
            } else {
              notificationService.current.info("There were no transactions available.");
              setShowModal(false);
            }
          };

          if (showModal) {
            return fetchData();
          } else {
            return [];
          }
        }}
        defaultColDef={TransactionsDefaultColDef}
        colDefs={TransactionsColumnDefs}
        nextButtonCallback={() => {
          setShowModal(false);
          searchRef.current.set(NavigationContextUrlSearchParams.Q4_ENTITY_ID, q4EntityId);
          goto("/manager", searchRef.current, { state: { Q4_ENTITY_ID: q4EntityId } }, true);
        }}
      />
    </>
  ) : (
    false
  );
};

export default NameCell;
