import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface FilingLegendProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  label: string;
  type: string;
}

export enum FilingLegendClassName {
  Base = "filing-legend",
}
