import { isEmpty, MessageProps } from "@q4/nimbus-ui";
import React, { createContext, useState, useRef } from "react";
import { PromiseResolve } from "../../types";
import {
  GlobalMessageContextState,
  ConfirmationModalProviderProps,
  ConfirmationModalProviderState,
  ConfirmationActionResponse,
} from "./globalMessage.definition";

export const GlobalMessageContext = createContext<Partial<GlobalMessageContextState>>({});

export const GlobalMessageProvider = (props: ConfirmationModalProviderProps): JSX.Element => {
  const [state, setState] = useState<ConfirmationModalProviderState>();
  const promise = useRef<PromiseResolve<ConfirmationActionResponse>>();

  function handleOpen(
    title: string,
    message: string,
    primaryActionProps: MessageProps["primaryActionProps"]
  ): Promise<ConfirmationActionResponse> {
    return new Promise((resolve): void => {
      promise.current = resolve;

      setState({
        visible: true,
        title,
        message,
        primaryActionProps,
      });
    });
  }

  function handleClose(response?: ConfirmationActionResponse): void {
    setState({
      ...state,
      visible: false,
    });

    if (promise.current && !isEmpty(response)) {
      promise.current(response);
      promise.current = null;
    }
  }

  function handleClear(): void {
    setState(null);
  }

  return (
    <GlobalMessageContext.Provider
      value={{
        ...state,
        open: handleOpen,
        close: handleClose,
        clear: handleClear,
      }}
    >
      {props.children}
    </GlobalMessageContext.Provider>
  );
};
