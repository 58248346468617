import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface ExportButtonProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  disabled?: boolean;
  onClick: () => void;
}

export enum ExportButtonClassName {
  Base = "export-button",
}
