import { NotificationService } from "@q4/nimbus-ui";
import moment from "moment";
import { SuggestedPosition } from "q4-platform-common/src/models/suggestedposition/suggestedposition";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { TickerContext } from "..";
import SuggestedPositionService from "../../services/suggestedposition/SuggestedPositionService";
import { MutualFundContextState, MutualFundProviderProps } from "./mutual-fund.definition";

export const MutualFundContext = createContext<Partial<MutualFundContextState>>({});

export const MutualFundProvider = (props: MutualFundProviderProps): JSX.Element => {
  const [mutualFundData, setMutualFundData] = useStateIfMounted<Array<SuggestedPosition>>([]);
  const [mutualFundDataLoading, setMutualFundDataLoading] = useState(true);
  const [viewMounted, setViewMounted] = useState(false);
  const { ticker, setTickerSelectorDisabled } = useContext(TickerContext);
  const suggestedPositionService = useMemo(() => new SuggestedPositionService(), []);
  const prevTicker = useRef(null);
  const notificationService = useRef(new NotificationService());

  useEffect(() => {
    if (window.location.pathname === "/mutual-fund") {
      setTickerSelectorDisabled(!ticker || mutualFundDataLoading);
    }
  }, [ticker, mutualFundDataLoading, setTickerSelectorDisabled]);

  const fetchMutualFundData = useCallback(
    async (q4SecurityId = ticker.Q4_SEC_ID) => {
      setMutualFundDataLoading(true);
      const result = await suggestedPositionService.getSuggestedPositionData(q4SecurityId);
      setMutualFundDataLoading(false);
      if (result.success && result.data) {
        setMutualFundData(result.data);
      } else if (!result.data) {
        setMutualFundData([]);
        console.warn(result.message);
        notificationService.current.warn("No MutualFund data was found");
      } else {
        notificationService.current.error(`MutualFund failed ${result.message}`);
        console.warn(result.message ? result.message : "No MutualFund Data returned");
      }
    },
    [ticker, suggestedPositionService, setMutualFundData]
  );

  const setMutualFundReviewed = useCallback(
    async (data: SuggestedPosition) => {
      setMutualFundDataLoading(true);
      await suggestedPositionService.setSuggestedPositionReviewToggle(
        data.Q4_STOCK_ID,
        data.Q4_ENTITY_ID,
        data.SUGGESTED_POSITION,
        moment(data.SUGGESTED_DATE)
      );
      await fetchMutualFundData();
    },
    [fetchMutualFundData, suggestedPositionService]
  );

  useEffect(() => {
    // Only load data when mounted and we are updating the view (starts null on first load)
    if (viewMounted && ticker != prevTicker.current) {
      prevTicker.current = ticker;
      fetchMutualFundData(ticker.Q4_SEC_ID);
    }
  }, [viewMounted, ticker, fetchMutualFundData]);

  const value = {
    mutualFundData,
    mutualFundDataLoading,
    setViewMounted,
    setMutualFundReviewed,
  };

  return <MutualFundContext.Provider value={value}>{props.children}</MutualFundContext.Provider>;
};
