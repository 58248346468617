import type { BaseComponentProps } from "@q4/nimbus-ui";
import { ManagerForm, CountryForm } from "q4-platform-common/src/models/manager/managerForm";
import { Dispatch, SetStateAction } from "react";

export interface EditManagerFormProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  form: ManagerForm;
  editMode: boolean;
  showModal: boolean;
  valid: boolean;
  updateState: (form: Partial<ManagerForm>) => void;
  countries: CountryForm[];
}

export interface EditManagerFormField {
  label: string;
  placeholder: string;
  error: string;
  value: string;
  onChange: Dispatch<SetStateAction<string>>;
}

export enum EditManagerFormClassName {
  Base = "edit-managers-form",
  Input = "edit-managers-form__input",
  Label = "edit-managers-form__label",
  Textbox = "edit-managers-form__textbox",
  Error = "edit-managers-form__error",
}
