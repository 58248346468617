import { Button, ButtonSize, ButtonTheme } from "@q4/nimbus-ui";
import React from "react";
import { AddButtonClassName, AddButtonProps } from "./AddButton.definition";
import "./AddButton.scss";

const AddButton = (props: AddButtonProps): JSX.Element => {
  const { disabled, onClick, size = ButtonSize.Default } = props;
  return (
    <Button
      className={AddButtonClassName.Base}
      icon={"q4i-add-4pt"}
      theme={ButtonTheme.Rain}
      label={""}
      disabled={disabled}
      onClick={() => {
        (document.activeElement as HTMLElement).blur();
        onClick();
      }}
      size={size}
    />
  );
};

export default AddButton;
