import { NavigationContextUrlSearchParams } from "../../../contexts";
import { colorToChangeCellStyleFormatter } from "../../../utils/ag-grid/ag-grid.cellstyle-formatters";
import {
  isFirstColumn,
  getDateColumnDef,
  getNameColumnDef,
  getNumericColumnDef,
  DefaultColDef as colDef,
} from "../../../utils/ag-grid/ag-grid.column-defs";

const urlSearchParamsFormatter = (search: React.MutableRefObject<URLSearchParams>, data: Record<string, string>) =>
  search.current.set(NavigationContextUrlSearchParams.Q4_ENTITY_ID, data.Q4_ENTITY_ID);

export const DefaultColDef = {
  ...colDef,
  headerCheckboxSelection: isFirstColumn,
  checkboxSelection: isFirstColumn,
};

export const ColumnDefs = [
  {
    ...getNameColumnDef("NAME", "NAME", { path: "/manager", urlSearchParamsFormatter, preview: true }),
    sort: "asc",
    sortingOrder: ["asc", "desc"],
  },
  { ...getDateColumnDef("DATE", "FILING_DATE") },
  { ...getNumericColumnDef("13F SHARES", "13F_SHARES") },
  { ...getNumericColumnDef("PREV 13F SHARES", "PREV_13F_SHARES") },
  { ...getDateColumnDef("ANALYST ESTIMATE DATE", "ANALYST_ESTIMATE_DATE") },
  { ...getNumericColumnDef("ANALYST ESTIMATE", "ANALYST_ESTIMATE") },
  { ...getNumericColumnDef("VARIANCE", "VARIANCE", null, colorToChangeCellStyleFormatter) },
  { ...getNumericColumnDef("ACTUAL QTR CHG", "ACTUAL_QTR_CHG", null, colorToChangeCellStyleFormatter) },
  { ...getNumericColumnDef("ANALYST QTR CHG", "ANALYST_QTR_CHG", null, colorToChangeCellStyleFormatter) },
  { ...getNumericColumnDef("NET CHG POST 13F", "NET_CHG_POST_13F", null, colorToChangeCellStyleFormatter) },
];
