import { Search } from "q4-platform-common/src/models/search/search";
import { ApiResponse } from "../api/api.definition";
import ApiService from "../api/api.service";

export default class SearchService {
  private readonly apiService: ApiService;
  private readonly ApiPath = "/search";
  constructor() {
    this.apiService = new ApiService();
  }

  async search(query: string, secId: string, entityType?: string): Promise<ApiResponse<Array<Search>>> {
    const entityTypeQueryString = entityType ? `&entityType=${entityType}` : "";
    const apiPath = `${this.ApiPath}?query=${encodeURIComponent(query)}&secId=${secId}${entityTypeQueryString}`;
    const response = await this.apiService.get<Array<Search>>(apiPath);
    return this.mapGenerateReportResponse(response);
  }

  private mapGenerateReportResponse(response: ApiResponse<Array<Search>>): ApiResponse<Array<Search>> {
    return new ApiResponse({ ...response });
  }
}
