import React, { createContext, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { LSAuthenticatedContextState, LSAuthenticatedProviderProps } from "./lsAuthenticated.definition";

export const LSAuthenticatedContext = createContext<Partial<LSAuthenticatedContextState>>({});

export const LSAuthenticatedProvider = (props: LSAuthenticatedProviderProps): JSX.Element => {
  const [lsAuthenticated, setLSAuthenticated] = useState(null);
  const [cookie, setCookie, removeCookie] = useCookies(["pathname", "url_search_params"]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.debug("LSAuthenticatedProvider - useEffect - window.location.search: ", window.location.search);
    if (
      // There are url search parameters
      window.location.search.length > 0 &&
      // They aren't the oAuth url search parameters
      !params.has("code") &&
      !params.has("state") &&
      // The cookie hasn't been set to this value
      cookie.url_search_params !== window.location.search
    ) {
      // ! We're accessing location through window, because LSAuthenticatedProvider wraps router, and only things inside router can use useLocation
      setCookie("pathname", window.location.pathname);
      setCookie("url_search_params", params.toString()); // example: "?Q4_TICKER_ID=5062a30a04dd810834e47f850245a20e&test=abcdef"
    }
  }, [lsAuthenticated, cookie, setCookie]);
  const logoutCleanup = useCallback(() => {
    removeCookie("pathname");
  }, [removeCookie]);
  const value = { lsAuthenticated, setLSAuthenticated, logoutCleanup };
  return <LSAuthenticatedContext.Provider value={value}>{props.children}</LSAuthenticatedContext.Provider>;
};
