import { NotificationService } from "@q4/nimbus-ui";
import {
  Custodian,
  CustodianManager,
  CustodianMutualFund,
  CustodianTransaction,
} from "q4-platform-common/src/models/custodian/custodian";
import { Search } from "q4-platform-common/src/models/search/search";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  NavigationContext,
  TickerContext,
  NavigationContextUrlSearchParams,
  RefetchContext,
  REFETCH_SCOPE,
} from "../../contexts";
import CustodianService from "../../services/custodian/CustodianService";
import { getEmptyMetadata } from "../../utils/entity/entityUtils";
import { CustodianContextState, CustodianProviderProps } from "./custodian.definition";

export const CustodianContext = createContext<Partial<CustodianContextState>>({});

export const CustodianProvider = (props: CustodianProviderProps): JSX.Element => {
  const location = useLocation();
  const [viewMounted, setViewMounted] = useState<boolean>(false);
  const { searchRef, searchRefHooks, setSearchRefHooks } = useContext(NavigationContext);
  const { refetch, addRefetchHook } = useContext(RefetchContext);
  const { ticker, setTickerSelectorDisabled } = useContext(TickerContext);
  const tickerRef = useRef(null);
  const notificationService = useRef(new NotificationService());
  const custodianService = useMemo(() => new CustodianService(), []);
  const [custodian, setCustodian] = useState(null);
  const custodianRef = useRef(null);
  const [custodianMetadata, setCustodianMetadata] = useState<Custodian>(null);
  const [custodianMetadataLoading, setCustodianMetadataLoading] = useState<boolean>(true);
  const [custodianTransactions, setCustodianTransactions] = useState<Array<CustodianTransaction>>(null);
  const [custodianTransactionsLoading, setCustodianTransactionsLoading] = useState<boolean>(true);
  const [custodianManagers, setCustodianManagers] = useState<Array<CustodianManager>>(null);
  const [custodianManagersLoading, setCustodianManagersLoading] = useState<boolean>(true);
  const [custodianMutualFunds, setCustodianMutualFunds] = useState<Array<CustodianMutualFund>>(null);
  const [custodianMutualFundsLoading, setCustodianMutualFundsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!custodian && location.state) {
      setCustodian(location.state);
    }
  }, [custodian, location.state, setCustodian]);

  useEffect(() => {
    const searchRefHook = (search: React.MutableRefObject<URLSearchParams>) =>
      setCustodian({
        ...custodian,
        ...{ Q4_CUSTODIAN_ID: search.current.get(NavigationContextUrlSearchParams.Q4_CUSTODIAN_ID) },
      });
    searchRefHooks["CUSTODIAN"] = searchRefHook;
    setSearchRefHooks(searchRefHooks);
  }, [custodian, searchRefHooks, setSearchRefHooks]);

  useEffect(() => {
    if (window.location.pathname === "/custodian") {
      setTickerSelectorDisabled(
        !ticker ||
          custodianMetadataLoading ||
          custodianTransactionsLoading ||
          custodianManagersLoading ||
          custodianMutualFundsLoading
      );
    }
  }, [
    ticker,
    custodian,
    custodianMetadataLoading,
    custodianTransactionsLoading,
    custodianManagersLoading,
    custodianMutualFundsLoading,
    setTickerSelectorDisabled,
  ]);

  const getQ4CustodianId = useCallback(() => {
    const urlSearchParamsExist =
      searchRef && searchRef.current && searchRef.current?.get(NavigationContextUrlSearchParams.Q4_CUSTODIAN_ID);
    if (urlSearchParamsExist) {
      return searchRef.current?.get(NavigationContextUrlSearchParams.Q4_CUSTODIAN_ID);
    }
    return custodian.Q4_CUSTODIAN_ID ? custodian.Q4_CUSTODIAN_ID : custodian.Q4_ENTITY_ID;
  }, [searchRef, custodian]);

  /**
   * Get Custodian Metadata
   */
  const fetchCustodianMetadata = useCallback(
    async (custodianId: string) => {
      setCustodianMetadataLoading(true);
      const result = await custodianService.getCustodianMetadata(custodianId);
      setCustodianMetadataLoading(false);
      if (result.success && result.data) {
        setCustodianMetadata(result.data[0]);
      } else {
        setCustodianMetadata(getEmptyMetadata());
        notificationService.current.warn("There was no manager metadata available.");
      }
    },
    [custodianService]
  );

  /**
   * Get all Custodian Transactions
   */
  const fetchCustodianTransactions = useCallback(async () => {
    if (ticker && custodian && tickerRef.current == ticker.Q4_SEC_ID) {
      setCustodianTransactions(null);
      setCustodianTransactionsLoading(true);
      const transactions = await custodianService.getCustodianTransactions(ticker.Q4_SEC_ID, getQ4CustodianId());
      if (transactions.success && transactions.data && transactions.data.length > 0) {
        setCustodianTransactions(transactions.data);
      } else {
        setCustodianTransactions([]);
        transactions.success
          ? notificationService.current.warn("No Custodian Transactions Found")
          : notificationService.current.error(`Custodian Transactions Failed to Load: ${transactions.message}`);
        console.warn(transactions.message ? transactions.message : "No Custodian Transactions Returned");
      }
      setCustodianTransactionsLoading(false);
    }
  }, [custodian, custodianService, getQ4CustodianId, ticker]);
  addRefetchHook(REFETCH_SCOPE.ALL, "custodian.fetchCustodianTransactions", fetchCustodianTransactions);

  /**
   * Get all Custodian Manager Data
   */
  const fetchCustodianManagers = useCallback(async () => {
    if (ticker && custodian && tickerRef.current == ticker.Q4_SEC_ID) {
      setCustodianManagers(null);
      setCustodianManagersLoading(true);
      const managers = await custodianService.getCustodianManagers(ticker.Q4_SEC_ID, getQ4CustodianId());
      if (managers.success && managers.data && managers.data.length > 0) {
        setCustodianManagers(managers.data);
      } else {
        setCustodianManagers([]);
        managers.success || managers.status == 204
          ? notificationService.current.warn("No Custodian Managers Found")
          : notificationService.current.error(`Custodian Managers Failed to Load: ${managers.message}`);
        console.error(managers.message ? managers.message : "No Custodian Managers Returned");
      }
      setCustodianManagersLoading(false);
    }
  }, [custodian, custodianService, getQ4CustodianId, ticker]);
  addRefetchHook(REFETCH_SCOPE.MANAGER_TRANSACTIONS, "custodian.fetchCustodianManagers", fetchCustodianManagers, true);

  /**
   * Get all Custodian Mutual Fund Data
   */
  const fetchCustodianMutualFunds = useCallback(async () => {
    if (ticker && custodian && tickerRef.current == ticker.Q4_SEC_ID) {
      setCustodianMutualFunds(null);
      setCustodianMutualFundsLoading(true);
      const mutualFunds = await custodianService.getCustodianMutualFunds(ticker.Q4_SEC_ID, getQ4CustodianId());
      if (mutualFunds.success && mutualFunds.data && mutualFunds.data.length > 0) {
        setCustodianMutualFunds(mutualFunds.data);
      } else {
        setCustodianMutualFunds([]);
        mutualFunds.success || mutualFunds.status == 204
          ? notificationService.current.warn("No Custodian Mutual Funds Found")
          : notificationService.current.error(`Custodian Mutual Funds Failed to Load: ${mutualFunds.message}`);
      }
      setCustodianMutualFundsLoading(false);
    }
  }, [custodian, custodianService, getQ4CustodianId, ticker]);
  addRefetchHook(REFETCH_SCOPE.FUND_TRANSACTIONS, "custodian.fetchCustodianManagers", fetchCustodianManagers, true);

  /**
   * Put a custodian mapping into the db
   */
  const putCustodianMapping = useCallback(
    async (search: Search) => {
      setCustodianMetadataLoading(true);
      const res = await custodianService.putCustodianMapping(search.Q4_ENTITY_ID, custodianMetadata.Q4_CUSTODIAN_ID);
      if (res.success) {
        await Promise.all([fetchCustodianManagers(), fetchCustodianMutualFunds()]);
        // Due to how quick it is to get the data we simply refetch the data
        notificationService.current.info("Custodian Mapping Added");
        setCustodianMetadataLoading(false);
        return true;
      } else {
        notificationService.current.error("Custodian Mapping Failed");
        setCustodianMetadataLoading(false);
        return false;
      }
    },
    [custodianService, custodianMetadata, fetchCustodianManagers, fetchCustodianMutualFunds]
  );

  /**
   * Execute all data fetches for current contextual view when upstream contexts change
   */
  useEffect(() => {
    if (ticker && custodian) {
      const tickerRefDoesNotMatch = !tickerRef.current || tickerRef.current != ticker.Q4_SEC_ID;
      const custodianRefDoesNotMatch = !custodianRef.current || custodianRef.current != custodian.Q4_CUSTODIAN_ID;
      if (viewMounted && (tickerRefDoesNotMatch || custodianRefDoesNotMatch)) {
        const newCustodianId = getQ4CustodianId();
        tickerRef.current = ticker.Q4_SEC_ID;
        custodianRef.current = newCustodianId;
        fetchCustodianManagers();
        fetchCustodianMutualFunds();
        fetchCustodianTransactions();
        fetchCustodianMetadata(newCustodianId);
      }
    }
  }, [
    custodian,
    viewMounted,
    ticker,
    tickerRef,
    custodianRef,
    fetchCustodianTransactions,
    fetchCustodianManagers,
    fetchCustodianMutualFunds,
    fetchCustodianMetadata,
    getQ4CustodianId,
    refetch,
  ]);

  const value = {
    custodian,
    custodianMetadata,
    custodianMetadataLoading,
    custodianTransactions,
    custodianTransactionsLoading,
    custodianManagers,
    custodianManagersLoading,
    custodianMutualFunds,
    custodianMutualFundsLoading,
    setCustodian,
    setCustodianManagers,
    setViewMounted,
    putCustodianMapping,
    fetchCustodianManagers,
  };
  return <CustodianContext.Provider value={value}>{props.children}</CustodianContext.Provider>;
};
