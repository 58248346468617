import type { BaseComponentProps } from "@q4/nimbus-ui";
import { Moment } from "moment";

export interface AddTransactionsModalProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  showModal: boolean;
  q4SecurityId: string;
  q4EntityId: string;
  initialQuantity?: number;
  initialDifference?: number;
  initialDate?: Moment;
  initialNote?: string;
  // ! A transaction can have many different types (e.g. FundTransaction, ManagerTransaction etc.) so we'll need to find a way to cast this that doesn't anger the typescript gods
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addTransaction: (transaction: any) => void;
  setShowModal: (showModal: boolean) => void;
}

export enum AddTransactionsModalClassName {
  Base = "add-transactions-modal",
}
