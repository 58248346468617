import {
  ButtonListId,
  AnchorTarget,
  BaseComponentProps,
  ButtonProps,
  AutomationListId,
  IdModelBase,
  isNullOrWhiteSpace,
} from "@q4/nimbus-ui";
import type { Key, ReactNode } from "react";
import type { RouteComponentProps } from "react-router";

// #region Definitions
export interface NavigationRoute extends BaseComponentProps {
  path: string;
  children?: string[];
  label: ReactNode;
  icon: string;
  target?: AnchorTarget;
}

export interface NavigationProps extends BaseComponentProps, RouteComponentProps {
  logo: ReactNode;
  collapsedLogo?: ReactNode;
  routes: NavigationRoute[];
  collapseToggleIcon?: string;
  footerActions?: ButtonProps[];
}

export enum NavigationClassName {
  Base = "nui-navigation",
  BaseWithCollapsedModifier = "nui-navigation--collapsed",
  Logo = "nui-navigation_logo",
  List = "nui-navigation_list",
  Item = "nui-navigation_item",
  Route = "nui-navigation_route",
  RouteWithActiveModifier = "nui-navigation_route--active",
  Icon = "nui-navigation_icon",
  Label = "nui-navigation_label",
  Footer = "nui-navigation_footer",
  CollapseToggle = "nui-navigation_collapse-toggle",
}
// #endregion

// #region Automation
export class NavigationListId extends AutomationListId<NavigationIdModel> {
  constructor(parentId: string, prefix = "Navigation-", postfix?: string) {
    super(parentId, prefix, NavigationIdModel, postfix);
  }
}

export class NavigationIdModel extends IdModelBase {
  logo: string;
  routeList: NavigationRouteListId;
  footerActionListId: ButtonListId;
  toggle: string;

  constructor(id: string, index?: Key, postfix?: string) {
    super(id, index, postfix);

    if (isNullOrWhiteSpace(this.id)) {
      this.routeList = new NavigationRouteListId(null);
      this.footerActionListId = new ButtonListId(null);

      return;
    }

    this.logo = `${this.id}Logo`;
    this.routeList = new NavigationRouteListId(this.id);
    this.footerActionListId = new ButtonListId(`${this.id}Footer`);
    this.toggle = `${this.id}Toggle`;
  }
}

export class NavigationRouteListId extends AutomationListId<NavigationRouteIdModel> {
  constructor(parentId: string, prefix = "", postfix = "Route") {
    super(parentId, prefix, NavigationRouteIdModel, postfix);
  }
}

export class NavigationRouteIdModel extends IdModelBase {
  link: string;
  icon: string;
  label: string;

  constructor(id: string, index?: Key, postfix?: string) {
    super(id, index, postfix);

    if (isNullOrWhiteSpace(this.id)) return;

    this.link = `${this.id}Link`;
    this.icon = `${this.id}Icon`;
    this.label = `${this.id}Label`;
  }
}
// #endregion
