import type { BaseComponentProps } from "@q4/nimbus-ui";
import { Moment } from "moment";

export interface ChipDatePickerListProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  minDates?: number;
  maxDates?: number;
  datesPerRow?: number;
  disabled?: boolean;
  dates: Array<Moment>;
  maxDate?: Moment;
  setDates: (dates: Array<Moment>) => void;
}

export enum ChipDatePickerListClassName {
  Base = "chip-date-picker-list",
  GridColumn = "chip-date-picker-list__grid-column",
  ChipDatePicker = "chip-date-picker-list__chip-date-picker",
}
