import { Moment } from "moment";
import { EntityMetadata } from "q4-platform-common/src/models/entity/entity";
import {
  AddManagerTransaction,
  DeleteManagerTransaction,
  InstitutionTicker,
  ManagerFund,
  ManagerTransaction,
  SectorSummary,
} from "q4-platform-common/src/models/manager/manager";
import { CountryForm, PutManagerForm } from "q4-platform-common/src/models/manager/managerForm";
import { ApiResponse } from "../api/api.definition";
import ApiService from "../api/api.service";

export default class ManagerService {
  private readonly apiService: ApiService;
  private readonly ApiPath = "/manager";
  constructor() {
    this.apiService = new ApiService();
  }

  async putManager(data: PutManagerForm): Promise<ApiResponse<string>> {
    const response = await this.apiService.put<PutManagerForm, string>(`${this.ApiPath}`, data);
    return new ApiResponse({ ...response });
  }

  async getFundsData(q4StockId: string, q4EntityId: string): Promise<ApiResponse<Array<ManagerFund>>> {
    const response = await this.apiService.get<Array<ManagerFund>>(
      `${this.ApiPath}/fund?q4StockId=${q4StockId}&q4EntityId=${q4EntityId}`
    );
    return new ApiResponse({ ...response });
  }

  async getTransactionsData(q4StockId: string, q4EntityId: string): Promise<ApiResponse<ManagerTransaction[]>> {
    const response = await this.apiService.get<ManagerTransaction[]>(
      `${this.ApiPath}/transaction?q4StockId=${q4StockId}&q4EntityId=${q4EntityId}`
    );
    return new ApiResponse({ ...response });
  }

  async getManagerMetadata(q4StockId: string, q4EntityId: string): Promise<ApiResponse<EntityMetadata>> {
    const response = await this.apiService.get<EntityMetadata>(
      `${this.ApiPath}/metadata?q4StockId=${q4StockId}&q4EntityId=${q4EntityId}`
    );
    return new ApiResponse({ ...response });
  }

  async getManagerSectorSummary(q4EntityId: string): Promise<ApiResponse<Array<SectorSummary>>> {
    const response = await this.apiService.get<Array<SectorSummary>>(
      `${this.ApiPath}/sector/summary?&q4EntityId=${q4EntityId}`
    );
    return new ApiResponse({ ...response });
  }

  async getCountries(): Promise<ApiResponse<Array<CountryForm>>> {
    const response = await this.apiService.get<Array<CountryForm>>(`${this.ApiPath}/country`);
    return new ApiResponse({ ...response });
  }

  async getManagerHoldings(q4EntityId: string): Promise<ApiResponse<Array<InstitutionTicker>>> {
    const response = await this.apiService.get<Array<InstitutionTicker>>(
      `${this.ApiPath}/holdings?&q4EntityId=${q4EntityId}`
    );

    return new ApiResponse({ ...response });
  }

  async putTransactionsData(data: AddManagerTransaction): Promise<ApiResponse<AddManagerTransaction>> {
    const response = await this.apiService.put<AddManagerTransaction>(`${this.ApiPath}/addTransaction`, data);
    return new ApiResponse({ ...response });
  }

  async deleteTransactionData(
    data: DeleteManagerTransaction[],
    q4StockId: string,
    q4EntityId: string
  ): Promise<ApiResponse<Record<string, unknown>>> {
    return this.apiService.delete(`${this.ApiPath}/deleteTransaction`, { data, q4StockId, q4EntityId });
  }

  async batchDeleteTransaction(
    startDate: Moment,
    endDate: Moment,
    q4StockId: string,
    managers: string[]
  ): Promise<ApiResponse<Record<string, unknown>>> {
    return this.apiService.delete(`${this.ApiPath}/batchDeleteTransaction`, { startDate, endDate, q4StockId, managers });
  }
}
