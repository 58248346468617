import type { BaseComponentWithChildrenProps, ErrorLogContextState } from "@q4/nimbus-ui";

export interface DTCCProps extends BaseComponentWithChildrenProps {
  errorLog?: ErrorLogContextState;
}

export enum DTCCClassName {
  Base = "dtcc",
  Grid = "dtcc__grid",
  TopGrid = "dtcc__top-grid",
  BottomGrid = "dtcc__bottom-grid",
  Modal = "dtcc__modal",
  DatePicker = "dtcc__date-picker",
  GridToolbarTitle = "dtcc__grid-toolbar-title",
  OverViewGridToolBarDays = "dtcc__overview-grid-toolbar-days",
  TopPerformersGrid = "dtcc__top-performers-grid",
  TwoDayVarianceGrid = "dtcc__two-day-variance-grid",
  DTCCStockDataGrid = "dtcc__stock-data-grid",
  // Modifier Classes
  DTCCStockDataFeature = "dtcc__stock-data-feature",
}
