import type { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";

export type RefetchProviderProps = BaseComponentWithChildrenProps;

export enum REFETCH_SCOPE {
  ALL,
  MANAGER_TRANSACTIONS,
  FUND_TRANSACTIONS,
  TICKERS,
}

export interface Fetcher {
  fn: (q4EntityId?: string) => Promise<void>;
  async: boolean;
}

export interface NamedFetchers {
  [key: string]: Fetcher;
}

export interface RefetchMap {
  [REFETCH_SCOPE.ALL]: NamedFetchers;
  [REFETCH_SCOPE.MANAGER_TRANSACTIONS]: NamedFetchers;
  [REFETCH_SCOPE.FUND_TRANSACTIONS]: NamedFetchers;
  [REFETCH_SCOPE.TICKERS]: NamedFetchers;
}

export interface RefetchContextState {
  refetchMap: RefetchMap;
  addRefetchHook: (scope: REFETCH_SCOPE, name: string, fn: (q4EntityId?: string) => Promise<void>, async?: boolean) => void;
  refetch: (scope: REFETCH_SCOPE, q4EntityId?: string) => Array<Promise<void>>;
}
