import { NavigationRoute } from "@q4/nimbus-ui";

export const RoutePath = {
  Banklist: "/banklist",
  Callback: "/callback",
  Custodian: "/custodian",
  DTCC: "/dtcc",
  Filing: "/filing",
  Fund: "/fund",
  Home: "/",
  LoginRoute: "/login",
  Manager: "/manager",
  MutualFund: "/mutual-fund",
  Report: "/report",
};

export const NavigationRoutes: NavigationRoute[] = [
  {
    path: RoutePath.Home,
    id: "Home",
    label: "Home",
    icon: "",
  },
  {
    path: RoutePath.Banklist,
    id: "Banklist",
    label: "Bank List",
    icon: "",
  },
  {
    path: RoutePath.DTCC,
    id: "DTCC",
    label: "DTCC",
    icon: "",
  },
  {
    path: RoutePath.Filing,
    id: "Filing",
    label: "Filing",
    icon: "",
  },
  {
    path: RoutePath.MutualFund,
    id: "Mutual-Fund",
    label: "Mutual Fund",
    icon: "",
  },
  {
    path: RoutePath.Report,
    id: "Report",
    label: "Report",
    icon: "",
  },
];
