import { Custodian } from "q4-platform-common/src/models/custodian/custodian";
import React, { useMemo } from "react";
import CustodianList from "../CustodianList/CustodianList";
import { CustodianButtonSize } from "../CustodianList/CustodianList.definition";
import { CustodianListCellProps } from "./CustodianListCell.definition";

const CustodianListCell = (props: CustodianListCellProps): React.ReactFragment => {
  const getCustodians = useMemo((): Custodian[] => {
    return props.value?.map((c) => {
      const [Q4_CUSTODIAN_ID, DTC_ID] = c?.split("|") || [];
      return { DTC_ID: Number(DTC_ID), Q4_CUSTODIAN_ID } as Custodian;
    });
  }, [props.value]);

  return <CustodianList custodians={getCustodians} buttonSize={CustodianButtonSize.Thin} showListOnly></CustodianList>;
};

export default CustodianListCell;
