import {
  Button,
  ButtonSize,
  ButtonTheme,
  DatePicker,
  DatePickerTheme,
  Grid,
  GridColumn,
  ToggleButtons,
  Toolbar,
  ToolbarTheme,
} from "@q4/nimbus-ui";
import moment from "moment";
import React, { useContext, useState } from "react";
import { ReportContext, TickerContext } from "../../../../../contexts";
import { ReportToolbarLegacyClassName, ReportToolbarLegacyProps } from "./ReportToolbarLegacy.definition";
import "./ReportToolbarLegacy.scss";

const ReportToolbarLegacy = (props: ReportToolbarLegacyProps): JSX.Element => {
  const { modalVisible, setShowModal } = props;
  const { ticker } = useContext(TickerContext);
  const { csvLoading, downloadError, generateReport, csvDownloaded } = useContext(ReportContext);
  const [reportToggle, setReportToggle] = useState(0);
  const [baseDate, setBaseDate] = useState(moment().subtract(1, "weeks").startOf("isoWeek"));
  const toggleButtonItems = [
    { key: `${ReportToolbarLegacyClassName.Base}-toggle-button-item-${0}`, label: "Weekly", value: "weekly" },
    { key: `${ReportToolbarLegacyClassName.Base}-toggle-button-item-${1}`, label: "Monthly", value: "monthly" },
    { key: `${ReportToolbarLegacyClassName.Base}-toggle-button-item-${2}`, label: "Quarterly", value: "quarterly" },
  ];
  const setReportType = (toggle: number): void => {
    if (toggleButtonItems[toggle].value == "weekly") {
      setBaseDate(moment().subtract(1, "weeks").startOf("isoWeek"));
    } else if (toggleButtonItems[toggle].value == "monthly") {
      setBaseDate(moment().subtract(1, "months").endOf("month"));
    } else if (toggleButtonItems[toggle].value == "quarterly") {
      setBaseDate(moment().subtract(1, "quarters").endOf("quarter"));
    }
    setReportToggle(toggle);
  };
  const getValidDates = (date: Date): boolean => {
    const dateToCheck = moment(date);
    const endOfLastWeek = moment().subtract(1, "weeks").endOf("isoWeek");
    if (toggleButtonItems[reportToggle].value == "weekly") {
      return dateToCheck > endOfLastWeek || !moment(dateToCheck).startOf("isoWeek").isSame(dateToCheck, "day");
    } else if (toggleButtonItems[reportToggle].value == "monthly") {
      return dateToCheck > endOfLastWeek || !moment(dateToCheck).endOf("month").isSame(dateToCheck, "day");
    } else if (toggleButtonItems[reportToggle].value == "quarterly") {
      return dateToCheck > endOfLastWeek || !moment(dateToCheck).endOf("quarter").isSame(dateToCheck, "day");
    }
    return true;
  };
  const renderDatePicker = () => {
    return (
      <DatePicker
        id="ReportToolbarDatePicker"
        className={ReportToolbarLegacyClassName.DatePicker}
        theme={DatePickerTheme.Rain}
        disabled={csvLoading || !csvDownloaded}
        disabledDays={getValidDates}
        onChange={(e) => setBaseDate(e)}
        value={baseDate}
      />
    );
  };
  const renderToggleButtons = () => {
    return (
      <ToggleButtons
        id="ReportToolbarToggleButtons"
        dataId="ReportToolbarToggleButtons"
        className={ReportToolbarLegacyClassName.Buttons}
        size={ButtonSize.Default}
        items={toggleButtonItems}
        disabled={csvLoading || !csvDownloaded}
        selected={reportToggle}
        onChange={(e) => setReportType(e)}
      />
    );
  };
  const generateReportOnClick = () => {
    const granularity = toggleButtonItems[reportToggle].value;
    generateReport(granularity, baseDate);
    setShowModal(true);
  };
  const renderGenerateReportButton = () => {
    if ((!csvLoading && csvDownloaded) || (csvLoading && modalVisible)) {
      const generateReportLabel = "Generate Report";
      return (
        <Button
          className={ReportToolbarLegacyClassName.GenerateReportButton}
          label={generateReportLabel}
          icon=""
          loading={csvLoading}
          disabled={!ticker || !baseDate}
          theme={ButtonTheme.Citrus}
          onClick={generateReportOnClick}
        />
      );
    } else {
      let generateReportLabel = "See Report Progress";
      if (!csvLoading && downloadError) {
        generateReportLabel = "See Report Error";
      } else if (!csvLoading && !downloadError) {
        generateReportLabel = "Report Ready";
      }

      return (
        <Button
          className={ReportToolbarLegacyClassName.GenerateReportButton}
          label={generateReportLabel}
          icon=""
          theme={ButtonTheme.Citrus}
          onClick={() => setShowModal(true)}
        />
      );
    }
  };
  const renderClientVariable = () => (
    <>
      {renderDatePicker()}
      {renderToggleButtons()}
      {renderGenerateReportButton()}
    </>
  );
  return (
    <Toolbar className={ReportToolbarLegacyClassName.Base} theme={ToolbarTheme.Q4Blue}>
      <Grid className={ReportToolbarLegacyClassName.Grid}>
        <GridColumn width="1-of-1">{renderClientVariable()}</GridColumn>
      </Grid>
    </Toolbar>
  );
};

export default ReportToolbarLegacy;
