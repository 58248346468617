export interface AddClientModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

export enum FormClassName {
  AlertMsgContainer = "alert-msg-container",
  AlertMsgIcon = "icon",
  CheckOption = "check-option",
  RequiredError = "error",
}
