import { ValueFormatterParams } from "ag-grid-community";

export const formatNumberWithCommas = (params: ValueFormatterParams, field: string): string => {
  const { data } = params;
  const nf = new Intl.NumberFormat("en-US");
  return data && (data[field] || data[field] === 0) ? nf.format(data[field]) : "";
};

export const formatValueWithCommas = (params: ValueFormatterParams): string => {
  const nf = new Intl.NumberFormat("en-US");
  return !params.value ? null : nf.format(Number(params.value));
};

export const formatNumberAsPercentage = (params: ValueFormatterParams, field: string): string => {
  const { data } = params;
  const nf = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return data && (data[field] || data[field] === 0) ? `${nf.format(data[field])}%` : "";
};

export const formatNumberAsCurrency = (params: ValueFormatterParams): string => {
  const nf = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
  return !params.value ? null : nf.format(Number(params.value));
};

export const formatBooleanAsText = ({ value }: ValueFormatterParams, suppress: "true" | "false" | undefined): string => {
  return value ? (suppress !== "true" ? "Yes" : "") : suppress !== "false" ? "No" : "";
};
