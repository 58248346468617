import { Button, ButtonTheme } from "@q4/nimbus-ui";
import React from "react";
import { CloseButtonClassName, CloseButtonProps } from "./CloseButton.definition";
import "./CloseButton.scss";

const CloseButton = (props: CloseButtonProps): JSX.Element => {
  const { disabled, onClick } = props;
  return (
    <Button
      className={CloseButtonClassName.Base}
      icon={"q4i-close-4pt"}
      theme={ButtonTheme.Rain}
      label={""}
      disabled={disabled}
      onClick={() => {
        (document.activeElement as HTMLElement).blur();
        onClick();
      }}
    />
  );
};

export default CloseButton;
