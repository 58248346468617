import { Button, ButtonTheme, Checkbox, Grid, GridColumn, Select, SelectTheme, Toolbar, ToolbarTheme } from "@q4/nimbus-ui";
import React, { useContext, useEffect, useState } from "react";
import { FilingContext, TickerContext } from "../../../../contexts";
import { breakpoints } from "../../../../utils/ui/ui.utils";
import DownloadButton from "../../../root/components/Button/DownloadButton/DownloadButton";
import FilingLegend from "../FilingLegend/FilingLegend";
import { FilingToolbarClassName, FilingToolbarProps } from "./FilingToolbar.definition";
import "./FilingToolbar.scss";

const FilingToolbar = (props: FilingToolbarProps): JSX.Element => {
  const { allOtherFilingsProcessed, gridRef, setShowDeleteModal } = props;
  const [width, setWindowWidth] = useState(0);
  const { ticker, tickers } = useContext(TickerContext);
  const { filingGridBusy, putFilingData, selectedFilings, includeIgnored, setIncludeIgnored, filingDataLoading } =
    useContext(FilingContext);

  const filingActionsDisabled = selectedFilings.length === 0 || filingGridBusy || allOtherFilingsProcessed;
  const filingCheckboxDisabled = filingDataLoading || filingGridBusy || allOtherFilingsProcessed;
  const exportButtonDisabled = filingCheckboxDisabled || !ticker || tickers.length === 0;
  const leftColumnButtons = [
    { label: "Accept", value: "accept" },
    { label: "Reconcile", value: "reconcile" },
    { label: "Ignore", value: "ignore" },
    { label: "Delete", value: "delete" },
  ];
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const renderFilingActionSelect = () => {
    return (
      <Select
        theme={SelectTheme.Steel}
        labelKey="label"
        valueKey="value"
        placeholder={"Filing actions"}
        onChange={(e: Record<string, string>) => putFilingData(e.value, null)}
        options={leftColumnButtons}
        disabled={filingActionsDisabled}
      />
    );
  };

  const renderFilingActionButton = (button: Record<string, string>, buttonIndex: number) => {
    const key = `${FilingToolbarClassName.Base}-left-${buttonIndex}`;
    return (
      <Button
        key={key}
        theme={ButtonTheme.Rain}
        label={button.label}
        disabled={filingActionsDisabled}
        onClick={() => {
          if (button.value == "delete") {
            setShowDeleteModal(true);
          } else {
            putFilingData(button.value, null);
          }
        }}
      />
    );
  };
  const renderIncludeIgnoredCheckbox = () => {
    const checkboxLabel = "Include Ignored";

    return (
      <Checkbox
        id={FilingToolbarClassName.Checkbox}
        checked={includeIgnored}
        label={checkboxLabel}
        onChange={() => setIncludeIgnored(!includeIgnored)}
        disabled={filingCheckboxDisabled}
      />
    );
  };
  const renderFilingActionsAndCheckbox = () => {
    return (
      <GridColumn width="1-of-2" smallWidth="1-of-2" xSmallWidth="1-of-2">
        {width <= breakpoints.desktop ? (
          renderFilingActionSelect()
        ) : (
          <div>{leftColumnButtons.map((button, buttonIndex) => renderFilingActionButton(button, buttonIndex))}</div>
        )}
        {renderIncludeIgnoredCheckbox()}
      </GridColumn>
    );
  };
  const renderExportButton = () => (
    <DownloadButton
      disabled={exportButtonDisabled}
      onClick={() => {
        if (gridRef && gridRef.current && gridRef.current.api && gridRef.current.api.exportDataAsCsv) {
          gridRef.current.api.exportDataAsCsv();
        } else {
          console.warn("Cannot call exportDataAsCsv because gridApi was unavailable.");
        }
      }}
    />
  );
  const renderFilingExportToolbar = () => {
    const label13g = "13G";
    const label13d = "13D";
    return (
      <GridColumn width="1-of-2" smallWidth="1-of-2" xSmallWidth="1-of-2">
        <FilingLegend label={label13g} type={"yellow"} />
        <FilingLegend label={label13d} type={"orange"} />
        {renderExportButton()}
      </GridColumn>
    );
  };
  return (
    <Toolbar className={FilingToolbarClassName.Base} theme={ToolbarTheme.Q4Blue}>
      <Grid className={FilingToolbarClassName.Grid}>
        {renderFilingActionsAndCheckbox()}
        {renderFilingExportToolbar()}
      </Grid>
    </Toolbar>
  );
};

export default FilingToolbar;
