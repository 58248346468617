import { DatePicker, DatePickerTheme, Grid, GridColumn, Textarea } from "@q4/nimbus-ui";
import React, { useEffect, SyntheticEvent } from "react";
import SVLNumericInput from "../../../SVLNumericInput/SVLNumericInput";
import { AddTransactionsFormClassName, AddTransactionsFormProps } from "./AddTransactionsForm.definition";
import "./AddTransactionsForm.scss";

const AddTransactionsForm = (props: AddTransactionsFormProps): JSX.Element => {
  const {
    transactionDate,
    transactionQuantity,
    transactionChange,
    transactionNote,
    valid,
    setTransactionDate,
    setTransactionQuantity,
    setTransactionChange,
    setTransactionNote,
  } = props;

  useEffect(() => {
    // Removes the focus off any inputs in this form (focus was defaulting to the calendar)
    (document.activeElement as HTMLElement).blur();
  }, []);
  const renderDatePicker = () => {
    const today = new Date();
    return (
      <DatePicker
        className={AddTransactionsFormClassName.DatePicker}
        theme={DatePickerTheme.LightGrey}
        onChange={(e) => setTransactionDate(e)}
        value={transactionDate}
        disabledDays={(date: Date) => {
          const dateTruncated = new Date(date.getFullYear(), date.getMonth(), date.getDate());
          const todayTruncated = new Date(today.getFullYear(), today.getMonth(), today.getDate());
          return dateTruncated > todayTruncated;
        }}
      />
    );
  };
  const renderNumericInput = (
    placeholder: string,
    className: string,
    min: number,
    value: number,
    onChange: (e: number) => void
  ) => {
    return (
      <SVLNumericInput
        className={className}
        placeholder={placeholder}
        inputMode="numeric"
        value={value}
        max={Infinity}
        min={min}
        onChange={onChange}
      />
    );
  };
  const renderQuantity = () => {
    return renderNumericInput(
      "[quantity] shares",
      AddTransactionsFormClassName.NumericInput,
      0,
      transactionQuantity,
      setTransactionQuantity
    );
  };
  const renderChange = () => {
    return renderNumericInput(
      "[change] shares",
      AddTransactionsFormClassName.NumericInput,
      -Infinity,
      transactionChange,
      setTransactionChange
    );
  };
  const renderTextarea = () => {
    const placeholder = "Leave a note";
    return (
      <Textarea
        className={AddTransactionsFormClassName.Textarea}
        placeholder={placeholder}
        value={transactionNote}
        onChange={setTransactionNote}
      />
    );
  };
  const renderTransactionDateLabel = () => {
    const transactionDateLabel = "Transaction Date";
    return (
      <label className={AddTransactionsFormClassName.Input}>
        <span className={AddTransactionsFormClassName.Label}>{transactionDateLabel}</span>
        {renderDatePicker()}
      </label>
    );
  };
  const renderLabel = (quantityLabel: string, quantityError: string, renderText: () => JSX.Element) => {
    return (
      <label className={AddTransactionsFormClassName.Input}>
        <span className={AddTransactionsFormClassName.Label}>{quantityLabel}</span>
        {renderText()}
        {!valid && (
          <span className={AddTransactionsFormClassName.Error} aria-live="polite">
            {quantityError}
          </span>
        )}
      </label>
    );
  };
  const renderQuantityLabel = () => {
    return renderLabel("Quantity", "Quantity is required", renderQuantity);
  };
  const renderChangeLabel = () => {
    return renderLabel("Change", "Change Error", renderChange);
  };
  const renderNoteLabel = () => {
    const noteLabel = "Note";
    return (
      <label className={AddTransactionsFormClassName.Input}>
        <span className={AddTransactionsFormClassName.Label}>{noteLabel}</span>
        {renderTextarea()}
      </label>
    );
  };
  const onSubmit = (e: SyntheticEvent) => e.preventDefault();
  return (
    <form className={AddTransactionsFormClassName.Base} onSubmit={onSubmit} autoComplete="off">
      <Grid>
        <GridColumn width="1-of-3">{renderTransactionDateLabel()}</GridColumn>
        <GridColumn width="1-of-3">{renderQuantityLabel()}</GridColumn>
        <GridColumn width="1-of-3">{renderChangeLabel()}</GridColumn>
        <GridColumn width="1-of-3"></GridColumn>
      </Grid>
      <Grid>
        <GridColumn width="1-of-1">{renderNoteLabel()}</GridColumn>
      </Grid>
    </form>
  );
};

export default AddTransactionsForm;
