import "./banklist.view.scss";
import {
  BannerSize,
  DatePicker,
  DatePickerTheme,
  Grid,
  GridColumn,
  Layout,
  LayoutPadding,
  LayoutTheme,
  Select,
  SelectTheme,
} from "@q4/nimbus-ui";
import { Moment } from "moment";
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { DayModifiers, Modifier } from "react-day-picker";
import { BanklistContext } from "../../contexts";
import EntityBanner from "../root/components/EntityBanner/EntityBanner";
import GridToolbar from "../root/components/GridToolbar/GridToolbar";
import FileUploadModal from "../root/components/Modal/FileUploadModal/FileUploadModal";
import MapXToYModal from "../root/components/Modal/MapXToYModal/MapXToYModal";
import SurveillanceGrid from "../root/components/SurveillanceGrid/SurveillanceGrid";
import DevLoader from "../root/components/devLoader/DevLoader";
import { BanklistClassName } from "./banklist.definition";
import { DefaultColDef, TwoDayVarianceReportColumnDef } from "./data/AgGridDefs";

const Banklist = (): JSX.Element => {
  const transactionsGridRef = useRef(null);
  const { showMapBanklistToManagerModal, setShowMapBanklistToManagerModal, putBanklistMapping } =
    useContext(BanklistContext);
  const {
    baseVarDate,
    validDates,
    banklistId,
    banklistVariance,
    banklistMetadataLoading,
    setBaseVarDate,
    setBanklistId,
    putBanklist,
    setViewMounted,
  } = useContext(BanklistContext);
  const [baseDateDisabled, setBaseDateDisabled] = useState(true);
  const [varianceDateDisabled, setVarianceDateDisabled] = useState(true);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const currentlyLoading = banklistMetadataLoading;

  useEffect(() => {
    setViewMounted(true);
    return () => {
      setViewMounted(false);
    };
  }, [setViewMounted]);

  useEffect(() => {
    if (validDates.length > 0 && !banklistMetadataLoading) {
      setBaseDateDisabled(false);
      setVarianceDateDisabled(false);
    } else {
      setBaseDateDisabled(true);
      setVarianceDateDisabled(true);
    }
  }, [banklistMetadataLoading, validDates]);

  const banklistIdDictionary: Record<string, number> = {
    "Northern Trust": 4,
    "State Street": 5,
  };

  const renderDatePicker = (
    value: Moment,
    onChange: (newValue: Moment, modifiers?: DayModifiers) => void,
    disabled: boolean,
    getDisabledDays: Modifier
  ) => (
    <DatePicker
      className={BanklistClassName.DatePicker}
      theme={DatePickerTheme.Rain}
      onChange={onChange}
      value={value}
      disabled={disabled}
      disabledDays={getDisabledDays}
    />
  );
  const isValidDate = (date: Date): boolean => {
    for (let i = 0; i < validDates.length; i++) {
      if (validDates[i].isSame(date, "day")) {
        return false;
      }
    }
    return true;
  };
  const renderFileUploadModal = () => (
    <FileUploadModal
      title="Upload Banklist"
      fileLabel={Object.keys(banklistIdDictionary).find((key) => banklistIdDictionary[key] === banklistId)}
      showModal={showFileUploadModal}
      setShowModal={setShowFileUploadModal}
      confirmCallback={async (value: string) => await putBanklist(banklistId, value)}
    />
  );
  const renderMapManagerToBanklist = () => {
    return (
      <MapXToYModal
        title="Map Manager to Banklist"
        showModal={showMapBanklistToManagerModal}
        setShowModal={setShowMapBanklistToManagerModal}
        searchEntityType="MANAGER"
        confirmCallback={putBanklistMapping}
      />
    );
  };
  const renderAllModals = () => {
    return (
      <>
        {renderFileUploadModal()}
        {renderMapManagerToBanklist()}
      </>
    );
  };
  const renderGridToolbarLeftContent = () => {
    return (
      <div>
        <div className={BanklistClassName.GridToolbarTitle}>{"Two-Day Variance"}</div>
        {renderDatePicker(
          baseVarDate.base,
          (date) => setBaseVarDate({ base: date, variance: baseVarDate.variance }),
          baseDateDisabled,
          (date) => isValidDate(date)
        )}
        {renderDatePicker(
          baseVarDate.variance,
          (date) => setBaseVarDate({ base: baseVarDate.base, variance: date }),
          varianceDateDisabled,
          (date) => isValidDate(date)
        )}
        {/* <CheckmarkButton disabled={false} onClick={() => console.log("You clicked confirm")} />
        <CloseButton disabled={false} onClick={() => console.log("You clicked close")} /> */}
      </div>
    );
  };
  const renderGridToolbarRightContent = () => (
    <Select
      value={Object.keys(banklistIdDictionary).find((key) => banklistIdDictionary[key] === banklistId)}
      options={Object.keys(banklistIdDictionary)}
      onChange={(value: string) => {
        setBanklistId(banklistIdDictionary[value]);
      }}
      theme={SelectTheme.Steel}
      placeholder={"Choose a Banklist"}
    />
  );
  return (
    <Layout className={BanklistClassName.Base} padding={LayoutPadding.None} flex={false}>
      <EntityBanner
        loading={false}
        entityMetadata={{
          NAME: "Banklist",
        }}
        size={BannerSize.Thin}
      />
      {currentlyLoading ? (
        <DevLoader />
      ) : (
        <Grid className={BanklistClassName.Grid}>
          <GridColumn width="1-of-1">
            <GridToolbar
              leftContent={renderGridToolbarLeftContent()}
              rightContent={renderGridToolbarRightContent()}
              gridRef={transactionsGridRef}
              showDownloadDataButton={true}
              showUploadDataButton={true}
              uploadData={() => setShowFileUploadModal(true)}
              disabled={currentlyLoading}
            />
            <Layout theme={LayoutTheme.PaleGrey} direction="column">
              <SurveillanceGrid
                id={"banklist-surveillance-grid"}
                gridRef={transactionsGridRef}
                DefaultColDef={DefaultColDef}
                ColumnDefs={TwoDayVarianceReportColumnDef}
                data={banklistVariance}
                overlayNoRowsTemplate={"No Data Available"}
                disableLoaderText={true}
              />
            </Layout>
          </GridColumn>
        </Grid>
      )}
      {renderAllModals()}
    </Layout>
  );
};

export default memo(Banklist);
