import { Shareholder } from "q4-platform-common/src/models/shareholder/shareholder";
import { ShareholderMovement } from "q4-platform-common/src/models/shareholder/shareholderMovement";
import { ApiResponse } from "../api/api.definition";
import ApiService from "../api/api.service";

export default class ShareholderService {
  private readonly apiService: ApiService;
  private readonly ApiPath = "/shareholder";
  constructor() {
    this.apiService = new ApiService();
  }

  async getTopShareholderData(q4SecurityId: string): Promise<ApiResponse<Array<Shareholder>>> {
    const response = await this.apiService.get<Array<Shareholder>>(`${this.ApiPath}/top?secId=${q4SecurityId}`);
    return new ApiResponse({ ...response });
  }

  async getShareholderMovement(
    q4SecurityId: string,
    weeks: string,
    top: number
  ): Promise<ApiResponse<Array<ShareholderMovement>>> {
    const response = await this.apiService.get<Array<ShareholderMovement>>(
      `${this.ApiPath}/top5Movers?secId=${q4SecurityId}&weeks=${weeks}&top=${top}`
    );
    return new ApiResponse({ ...response });
  }
}
