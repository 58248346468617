import { RowClassParams, ValueFormatterParams } from "ag-grid-community";
import { Custodian } from "q4-platform-common/src/models/custodian/custodian";
import { Shareholder } from "q4-platform-common/src/models/shareholder/shareholder";
import React from "react";
import { RoutePath } from "../../../configurations/navigation.configuration";
import { NavigationContextUrlSearchParams } from "../../../contexts";
import { colorToChangeCellStyleFormatter } from "../../../utils/ag-grid/ag-grid.cellstyle-formatters";
import {
  getNameColumnDef,
  getTextColumnDef,
  getCurrencyColumnDef,
  getNumericColumnDef,
  getDateColumnDef,
  getPercentageColumnDef,
} from "../../../utils/ag-grid/ag-grid.column-defs";
import { formatBooleanAsText } from "../../../utils/ag-grid/ag-grid.value-formatters";
import { NameCellClassName } from "../../root/components/Cell/NameCell/NameCell.definition";

const urlSearchParamsFormatter = (search: React.MutableRefObject<URLSearchParams>, data: Shareholder) =>
  search.current.set(NavigationContextUrlSearchParams.Q4_ENTITY_ID, data.Q4_ENTITY_ID);

const cellClassParamsFormatter = (): string => NameCellClassName.Base;

export { DefaultColDef } from "../../../utils/ag-grid/ag-grid.column-defs";

export const ColumnDefs = [
  { ...getNumericColumnDef("RANK", "RANK"), sort: "asc", pinned: "left", width: 90, suppressSizeToFit: true },
  {
    ...getNameColumnDef(
      "NAME",
      "NAME",
      { path: RoutePath.Manager, urlSearchParamsFormatter },
      null,
      cellClassParamsFormatter
    ),
    pinned: "left",
    width: 350,
    suppressSizeToFit: true,
  },
  { ...getNumericColumnDef("POSITION", "CURRENT") },
  { ...getNumericColumnDef("QTD CHANGE", "CHANGE", null, colorToChangeCellStyleFormatter) },
  { ...getNumericColumnDef("CURRENT CHANGE", "CURRENT_CHANGE", null, colorToChangeCellStyleFormatter) },
  { ...getNumericColumnDef("PUBLIC POSITION", "PUBLIC_POSITION") },
  { ...getDateColumnDef("PUBLIC DATE", "PUBLIC_DATE") },
  { ...getNumericColumnDef("PUBLIC CHANGE", "PUBLIC_CHANGE", null, colorToChangeCellStyleFormatter) },
  { ...getPercentageColumnDef("TSO (%)", "TSO") },
  { ...getCurrencyColumnDef("AVG PRICE ($)", "AVERAGE_PRICE") },
  { ...getTextColumnDef("STYLE", "STYLE") },
  { ...getTextColumnDef("TURNOVER", "TURNOVER") },
  { ...getTextColumnDef("ORIENTATION", "ORIENTATION") },
  { ...getTextColumnDef("CITY", "CITY") },
  { ...getTextColumnDef("METRO AREA", "METRO_AREA") },
  { ...getTextColumnDef("STATE", "STATE") },
  { ...getTextColumnDef("COUNTRY", "COUNTRY") },
  { ...getNumericColumnDef("EQUITY ASSETS ($MM)", "EQUITY_ASSETS") },
  { ...getNumericColumnDef("QUALITY SCORE", "QUALITY_RATING") },
  { ...getPercentageColumnDef("PORTFOLIO (%)", "PORTFOLIO") },
  { ...getDateColumnDef("POSTITION DATE", "PUB_DATE") },
  { ...getTextColumnDef("TYPE", "TYPE") },
  {
    ...getTextColumnDef("ACTIVIST", "ACTIVIST"),
    valueFormatter: (params: ValueFormatterParams): string => formatBooleanAsText(params, "false"),
  },
  {
    ...getTextColumnDef("IS STAKEHOLDER", "IS_STAKEHOLDER"),
    valueFormatter: (params: ValueFormatterParams): string => formatBooleanAsText(params, "false"),
  },
  { ...getNumericColumnDef("13GD QUANTITY", "13GD_QUANTITY") },
  { ...getTextColumnDef("POSITION TYPE", "POSITION_TYPE") },
  { ...getTextColumnDef("PUBLIC FILING TYPE", "PUBLIC_FILING_TYPE") },
];

export const ColumnDefsReportType = [
  // part of the array before the specified index
  ...ColumnDefs.slice(0, 4),
  // inserted item
  { ...getTextColumnDef("REPORT POSITION TYPE", "REPORT_POSITION_TYPE") },
  // part of the array after the specified index
  ...ColumnDefs.slice(4),
];

export const StockPriceColumnDefs = [
  { ...getDateColumnDef("DATE", "date") },
  { ...getCurrencyColumnDef("CLOSE", "close") },
  { ...getCurrencyColumnDef("$ CHANGE", "change", null, colorToChangeCellStyleFormatter) },
  { ...getPercentageColumnDef("% CHANGE", "percentChange", null, colorToChangeCellStyleFormatter) },
  { ...getNumericColumnDef("VOL", "volume") },
  { ...getNumericColumnDef("SHT VOL", "shortVolume") },
  { ...getPercentageColumnDef("PCT AVG VOL 30 Days", "percentAverageVolume") },
];

export const TopNetColumnDefs = [
  { ...getDateColumnDef("DATE", "DATE") },
  { ...getCurrencyColumnDef("CLOSE", "CLOSE") },
  { ...getCurrencyColumnDef("$ CHANGE", "CHANGE") },
  { ...getPercentageColumnDef("% CHANGE", "PERCENTAGE_CHANGE") },
  { ...getNumericColumnDef("VOL", "VOL") },
  { ...getNumericColumnDef("SHT VOL", "SHT_VOL") },
  { ...getPercentageColumnDef("PEER", "PEER") },
  { ...getPercentageColumnDef("MTK", "MTK") },
];

export const TopNetBuyersColumnDefs = [
  {
    ...getNameColumnDef(
      "BUYERS",
      "NAME",
      { path: RoutePath.Manager, urlSearchParamsFormatter },
      null,
      cellClassParamsFormatter
    ),
  },
  { ...getNumericColumnDef("CHANGE", "CHANGE", null, colorToChangeCellStyleFormatter) },
];

export const TopNetSellersColumnDefs = [
  {
    ...getNameColumnDef(
      "SELLERS",
      "NAME",
      { path: RoutePath.Manager, urlSearchParamsFormatter },
      null,
      cellClassParamsFormatter
    ),
  },
  { ...getNumericColumnDef("CHANGE", "CHANGE", null, colorToChangeCellStyleFormatter) },
];

export const BaseDTCColumnDefs = [
  {
    ...getNameColumnDef(
      "DTC ID",
      "DTC_ID",
      {
        path: RoutePath.Custodian,
        urlSearchParamsFormatter: (search: React.MutableRefObject<URLSearchParams>, data: Custodian) =>
          search.current.set(NavigationContextUrlSearchParams.Q4_CUSTODIAN_ID, data.Q4_CUSTODIAN_ID),
      },
      null,
      cellClassParamsFormatter
    ),
  },
];

export const DTCIncreasesColumnDefs = [
  ...BaseDTCColumnDefs,
  { ...getNumericColumnDef("NET INCREASES", "NET_CHG", null, colorToChangeCellStyleFormatter) },
];

export const DTCDecreasesColumnDefs = [
  ...BaseDTCColumnDefs,
  { ...getNumericColumnDef("NET DECREASES", "NET_CHG", null, colorToChangeCellStyleFormatter) },
];

export const getInstutionalHolderClassRules = {
  "row-citrus": ({ data }: RowClassParams): boolean => data["ACTIVIST"],
  "row-green": ({ data }: RowClassParams): boolean => data["TSO"] >= 5,
};
