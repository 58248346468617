import { BaseComponentProps } from "@q4/nimbus-ui";

export enum ProgressBarClassNames {
  Base = "nui-progress-bar",
  Progress = "nui-progress",
  Loading = "loading",
}

export interface ProgressBarProps extends BaseComponentProps {
  percentComplete: number;
  loading: boolean;
  theme?: string;
}
