import { RowClassParams } from "ag-grid-community";
import { Fund } from "q4-platform-common/src/models/fund/fund";
import { RoutePath } from "../../../configurations/navigation.configuration";
import { NavigationContextUrlSearchParams } from "../../../contexts";
import {
  boldCellStyleFormatter,
  colorAndBoldCellStyleFormatter,
  colorToChangeCellStyleFormatter,
} from "../../../utils/ag-grid/ag-grid.cellstyle-formatters";
import {
  getNameColumnDef,
  getTextColumnDef,
  getNumericColumnDef,
  isFirstColumn,
  DefaultColDef,
  getDateColumnDef,
  getCurrencyColumnDef,
  getCustodiansColumnDef,
} from "../../../utils/ag-grid/ag-grid.column-defs";
import { NameCellClassName } from "../../root/components/Cell/NameCell/NameCell.definition";

const urlSearchParamsFormatter = (search: React.MutableRefObject<URLSearchParams>, data: Fund) =>
  search.current.set(NavigationContextUrlSearchParams.Q4_FUND_ID, data.Q4_FUND_ID ? data.Q4_FUND_ID : data.Q4_ENTITY_ID);

const cellClassParamsFormatter = (): string => NameCellClassName.Base;

export const TransactionsDefaultColDef = {
  ...DefaultColDef,
  headerCheckboxSelection: isFirstColumn,
  checkboxSelection: isFirstColumn,
};

export const FundsDefaultColDef = DefaultColDef;
export const SectorDefaultColDef = DefaultColDef;
export const InstutionTockersColDef = DefaultColDef;

// * BaseTransactionsColumnDefs is a subset of TransactionColumnDefs, however the order is important, which is why we have two definitions
export const BaseTransactionsColumnDefs = [
  { ...getDateColumnDef("DATE", "DATE", null, boldCellStyleFormatter) },
  { ...getNumericColumnDef("SHARES", "SHARES", null, boldCellStyleFormatter) },
  { ...getTextColumnDef("NOTE", "NOTE", null, boldCellStyleFormatter) },
];

export const TransactionsColumnDefs = [
  { ...getDateColumnDef("DATE", "DATE", null, boldCellStyleFormatter) },
  { ...getNumericColumnDef("SHARES", "SHARES", null, boldCellStyleFormatter) },
  { ...getNumericColumnDef("CHANGE", "CHANGE", null, colorAndBoldCellStyleFormatter) },
  { ...getNumericColumnDef("QTR CHANGE", "QTR_CHANGE", null, colorAndBoldCellStyleFormatter) },
  { ...getCurrencyColumnDef("PRICE ($)", "PRICE", null, boldCellStyleFormatter) },
  { ...getTextColumnDef("NOTE", "NOTE", null, boldCellStyleFormatter) },
  { ...getTextColumnDef("SOURCE", "SOURCE", null, boldCellStyleFormatter) },
];

export const FundsColumnDefs = [
  {
    ...getNameColumnDef("NAME", "NAME", { path: RoutePath.Fund, urlSearchParamsFormatter }, null, cellClassParamsFormatter),
  },
  { ...getTextColumnDef("SOURCE", "SOURCE", null, boldCellStyleFormatter) },
  { ...getNumericColumnDef("SHARES", "SHARES", { sort: "asc", sortingOrder: ["asc", "desc"] }) },
  { ...getNumericColumnDef("CHANGE", "CHANGE", null, colorToChangeCellStyleFormatter) },
  { ...getDateColumnDef("DATE", "DATE") },
  { ...getDateColumnDef("PREV DATE", "PREV_DATE") },
  { ...getNumericColumnDef("QTR CHANGE", "QTR_CHANGE", null, colorToChangeCellStyleFormatter) },
  { ...getNumericColumnDef("PRICE ($)", "PRICE") },
  { ...getTextColumnDef("TYPE", "TYPE") },
  { ...getCustodiansColumnDef("CUSTODIANS", "CUSTODIANS") },
];

export const SectorSummaryColumnDefs = [
  { ...getTextColumnDef("SECTOR", "SECTOR", null, boldCellStyleFormatter) },
  { ...getNumericColumnDef("MV", "CURRENT_MV", null, boldCellStyleFormatter) },
  { ...getNumericColumnDef("SHARE CHANGE MV", "SHARE_CHG_MV", null, colorAndBoldCellStyleFormatter) },
  { ...getNumericColumnDef("QTR PRICE CHG MV", "PRICE_CHG_MV", null, colorAndBoldCellStyleFormatter) },
  { ...getNumericColumnDef("PREV MV", "PREV_MV", null, boldCellStyleFormatter) },
  { ...getNumericColumnDef("PORTFOLIO (%)", "PERCENT_PORTFOLIO", null, boldCellStyleFormatter) },
  { ...getNumericColumnDef("PORTFOLIO CHG (%)", "PERCENT_PORTFOLIO_CHANGE", null, colorAndBoldCellStyleFormatter) },
  { ...getNumericColumnDef("PREV PORTFOLIO (%)", "PREV_PERCENT_PORTFOLIO", null, boldCellStyleFormatter) },
  { ...getNumericColumnDef("HOLDINGS", "CURRENT_HOLDING", null, boldCellStyleFormatter) },
  { ...getNumericColumnDef("HOLDINGS CHG", "HOLDINGS_CHANGE", null, colorAndBoldCellStyleFormatter) },
  { ...getNumericColumnDef("PREV HOLDINGS", "PREVIOUS_HOLDING", null, boldCellStyleFormatter) },
];

export const InstitutionTickersColumnDefs = [
  { ...getNumericColumnDef("RANK", "RANK") },
  { ...getTextColumnDef("SECURITY", "SECURITY_NAME", null, boldCellStyleFormatter), width: 400 },
  { ...getTextColumnDef("SYMBOL", "TICKER_EXCHANGE", null, boldCellStyleFormatter) },
  { ...getTextColumnDef("SECTOR", "SECTOR", null, boldCellStyleFormatter) },
  { ...getTextColumnDef("MARKET CAP", "CAP_GROUP", null, boldCellStyleFormatter) },
  { ...getTextColumnDef("REGION", "REGION", null, boldCellStyleFormatter) },
  { ...getTextColumnDef("DATE", "DATE") },
  { ...getCurrencyColumnDef("CURRENT PRICE ($)", "PRICE", null, boldCellStyleFormatter) },
  { ...getNumericColumnDef("SHARES", "CURRENT", null, boldCellStyleFormatter) },
  { ...getNumericColumnDef("SHARE CHANGE", "CHANGE", null, colorAndBoldCellStyleFormatter) },
  { ...getNumericColumnDef("PREVIOUS SHARES", "PREV_CURRENT", null, boldCellStyleFormatter) },
  { ...getCurrencyColumnDef("PREVIOUS PRICE ($)", "PREV_PRICE", null, boldCellStyleFormatter) },
  { ...getTextColumnDef("PREVIOUS DATE", "PREV_DATE") },
  { ...getNumericColumnDef("MARKET VALUE", "MARKET_VALUE", null, boldCellStyleFormatter) },
  { ...getNumericColumnDef("TOTAL MV CHANGE", "MARKET_VALUE_CHANGE", null, colorAndBoldCellStyleFormatter) },
  { ...getNumericColumnDef("SHARE CHANGE MV", "SHARE_CHG_MV", null, colorAndBoldCellStyleFormatter) },
  { ...getNumericColumnDef("PRICE CHANGE MV", "PRICE_CHG_MV", null, colorAndBoldCellStyleFormatter) },
  { ...getNumericColumnDef("PREVIOUS MV", "PREV_MV", null, boldCellStyleFormatter) },
];

export const getTransactionClassRules = {
  "row-bold": ({ data }: RowClassParams): boolean => data["SOURCE"].includes("13F"),
};
