import type { BaseComponentProps, ButtonSize } from "@q4/nimbus-ui";

export interface AddButtonProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  disabled?: boolean;
  onClick: () => void;
  size?: ButtonSize;
}

export enum AddButtonClassName {
  Base = "add-button",
}
