import type { BaseComponentWithChildrenProps } from "@q4/nimbus-ui";
import { Moment } from "moment";
import { CustodianWeekly, CustodianVariance } from "q4-platform-common/src/models/custodian/custodian";

export type DTCCProviderProps = BaseComponentWithChildrenProps;

export enum VIEW_MODES {
  BASE = "Base",
  VARIANCE = "Variance",
}

export interface DTCCContextState {
  latestDate: Moment;
  baseDate: Moment;
  varianceDate: Moment;
  overviewDays: number;
  DTCCustodiansLoading: boolean;
  uploadDTCCLoading: boolean;
  DTCCustodiansVarianceLoading: boolean;
  DTCCustodians: Array<CustodianWeekly>;
  DTCCustodiansOversized: Array<string>;
  DTCCustodiansVariance: Array<CustodianVariance>;
  viewMounted: boolean;
  dtccViewMode: VIEW_MODES;
  DTCCStockPriceActivityData: Array<Record<string, unknown>>;
  DTCCStockPriceActivityDisplayData: Array<Record<string, unknown>>;
  DTCCStockPriceActivityDataLoading: boolean;
  setDtccViewMode: (view: VIEW_MODES) => void;
  setBaseDate: (baseDate: Moment) => void;
  setVarianceDate: (baseDate: Moment) => void;
  setViewMounted: (mounted: boolean) => void;
  putDTCC: (file: string) => Promise<boolean>;
  setOverviewDays: (days: number) => void;
}
