import "./manager.view.scss";
import {
  ButtonTheme,
  Grid,
  GridColumn,
  Layout,
  LayoutPadding,
  LayoutTheme,
  Modal,
  NotificationService,
} from "@q4/nimbus-ui";
import { RowNode, SelectionChangedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment, { Moment } from "moment";
import { Fund } from "q4-platform-common/src/models/fund/fund";
import { ManagerTransaction } from "q4-platform-common/src/models/manager/manager";
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import config, { FeatureFlag } from "../../config";
import { ManagerContext, TickerContext } from "../../contexts";
import { getIsFeatureEnabled } from "../../utils/feature/feature.utils";
import AddButton from "../root/components/Button/AddButton/AddButton";
import TrashButton from "../root/components/Button/TrashButton/TrashButton";
import EntityBanner from "../root/components/EntityBanner/EntityBanner";
import GridToolbar from "../root/components/GridToolbar/GridToolbar";
import AddTransactionsModal from "../root/components/Modal/AddTransactionsModal/AddTransactionsModal";
import EditManagerModal from "../root/components/Modal/EditManagerModal/EditManagerModal";
import SurveillanceGrid from "../root/components/SurveillanceGrid/SurveillanceGrid";
import DevLoader from "../root/components/devLoader/DevLoader";
import AddFundsForm from "./components/AddFundsForm/AddFundsForm";
import AnalysisModal from "./components/AnalysisModal/AnalysisModal";
import DeleteCustomTransactionsModal from "./components/DeleteCustomTransactionsModal/DeleteCustomTransactionsModal";
import {
  TransactionsDefaultColDef,
  FundsDefaultColDef,
  FundsColumnDefs,
  TransactionsColumnDefs,
  getTransactionClassRules,
} from "./data/AgGridDefs";
import { ManagerClassName } from "./manager.definition";

const Manager = (): JSX.Element => {
  const features = useFlags();
  const {
    fundsData,
    fundsDataLoading,
    transactionsData,
    transactionsDataLoading,
    manager,
    managerLoading,
    managerMetadata,
    managerMetadataLoading,
    managerCustodians,
    managerCustodiansLoading,
    selectedTransactions,
    addTransaction,
    setViewMounted,
    setSelectedTransactions,
  } = useContext(ManagerContext);
  const { ticker } = useContext(TickerContext);
  const [showEditManagerModal, setShowEditManagerModal] = useState(false);
  const [showAddTransactionsModal, setShowAddTransactionsModal] = useState(false);
  const [showAddFundsModal, setShowAddFundsModal] = useState(false);
  const [showDeleteCustomTransactionsModal, setShowDeleteCustomTransactionsModal] = useState(false);
  const [showPortfolioModal, setShowPortfolioModal] = useState(false);
  const transactionsGridRef = useRef<AgGridReact>(null);
  const fundsGridRef = useRef<AgGridReact>(null);

  const currentlyLoading =
    !ticker ||
    managerLoading ||
    transactionsDataLoading ||
    fundsDataLoading ||
    managerMetadataLoading ||
    managerCustodiansLoading;

  useEffect(() => {
    setViewMounted(true);
    return () => {
      setViewMounted(false);
    };
  }, [setViewMounted]);

  useEffect(() => {
    return () => {
      setSelectedTransactions([]);
    };
  }, [setSelectedTransactions]);

  const getFundsModalFooterActions = () => [
    {
      label: "Cancel",
      theme: ButtonTheme.DarkSlate,
      onClick: () => console.log("Cancel add funds"),
    },
    {
      label: "Add",
      theme: ButtonTheme.Citrus,
      onClick: () => console.log("Confirm add funds"),
    },
  ];
  /**
   * Gets the transaction before the specified date
   * @param data - The sorted desc transaction array to search
   * @param date - The date to look before
   * @returns - The transaction before (if it exists) otherwise a zero transaction for the date
   */
  const getTransactionBefore = (data: ManagerTransaction[], date: Moment): ManagerTransaction => {
    let transactionBefore = null as ManagerTransaction;
    if (data) {
      for (let index = 0; index < data.length; index++) {
        transactionBefore = transactionsData[index];
        if (moment(transactionBefore.DATE).isSameOrBefore(date, "day")) {
          break;
        }
      }
    }
    if (!transactionBefore) {
      transactionBefore = {
        DATE: date.toDate(),
        SHARES: 0,
        CHANGE: 0,
        QTR_CHANGE: 0,
        PRICE: 0,
        SOURCE: "Custom",
        NOTE: "",
      };
    }
    return transactionBefore;
  };
  const renderAddTransactionsModal = () => {
    let initialDate = moment().day(-2);
    if (selectedTransactions && selectedTransactions.length > 0) {
      initialDate = moment(
        selectedTransactions.reduce((previous, current) =>
          moment(current.DATE).isAfter(moment(previous.DATE)) ? current : previous
        ).DATE
      );
    }
    return (
      <AddTransactionsModal
        q4EntityId={manager.Q4_ENTITY_ID}
        q4SecurityId={ticker.Q4_SEC_ID}
        showModal={showAddTransactionsModal}
        initialDate={initialDate}
        initialQuantity={getTransactionBefore(transactionsData, initialDate).SHARES} // Align to prev Friday (-2)
        setShowModal={setShowAddTransactionsModal}
        addTransaction={addTransaction}
      />
    );
  };
  const renderAddFundsModal = () => {
    const title = "Add Fund";
    const subtitle = "";
    return (
      <Modal
        className={ManagerClassName.Modal}
        visible={showAddFundsModal}
        title={title}
        subtitle={subtitle}
        onCloseRequest={() => setShowAddFundsModal(false)}
        footerActions={getFundsModalFooterActions()}
      >
        <AddFundsForm showModal={showAddTransactionsModal} />
      </Modal>
    );
  };
  const renderDeleteCustomTransactionsModal = () => (
    <DeleteCustomTransactionsModal
      showModal={showDeleteCustomTransactionsModal}
      setShowModal={setShowDeleteCustomTransactionsModal}
    />
  );

  const renderSectorSummaryModal = () => {
    return (
      <AnalysisModal
        setShowModal={setShowPortfolioModal}
        showModal={showPortfolioModal}
        currentEntityId={manager.Q4_ENTITY_ID}
        institutionName={managerMetadata.NAME}
      />
    );
  };
  const renderTransactionsToolbarContent = () => {
    return (
      <>
        <AddButton disabled={false} onClick={() => setShowAddTransactionsModal(true)} />
        <TrashButton
          disabled={!selectedTransactions.length}
          onClick={() => {
            // Form logic: only continguous blocks of transactions can be deleted
            if (selectedTransactions.length > 1) {
              const orderedSelection = selectedTransactions
                .map((transaction) => moment(transaction.DATE))
                .sort((a, b) => a.valueOf() - b.valueOf());
              const baseTransactions = transactionsData
                .map((transaction) => moment(transaction.DATE))
                .sort((a, b) => a.valueOf() - b.valueOf());
              for (
                let selectionCursor = 1, // Note we are iterating forwards after the first match, if the match is missed that is handled as a base case in the loop
                  baseCursor = baseTransactions.findIndex((t) => moment(t).isSame(orderedSelection[0], "day")) + 1;
                baseCursor < baseTransactions.length && selectionCursor < orderedSelection.length;
                baseCursor++, selectionCursor++
              ) {
                if (
                  baseCursor < 0 ||
                  !moment(baseTransactions[baseCursor]).isSame(orderedSelection[selectionCursor], "day")
                ) {
                  // non contiguous or data mismatch
                  new NotificationService().error("Cannot delete non contiguous blocks of transactions");
                  return;
                }
              }
            }
            setShowDeleteCustomTransactionsModal(true);
          }}
        />
      </>
    );
  };
  const renderFundsToolbarContent = () => {
    const addFundButtonDisabled = !getIsFeatureEnabled(features[FeatureFlag.MapFundToManager]);
    return <AddButton disabled={addFundButtonDisabled} onClick={() => setShowAddFundsModal(true)} />;
  };
  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const nodes: Array<ManagerTransaction> = [];
    event.api.forEachNode((node: RowNode) => {
      if (node.isSelected()) {
        nodes.push(node.data);
      }
    });
    setSelectedTransactions(nodes);
  };

  const renderTransactionsToolbarAndGrid = () => (
    <>
      <GridToolbar
        leftContent="Transactions"
        rightContent={renderTransactionsToolbarContent()}
        gridRef={transactionsGridRef}
        showDownloadDataButton={true}
      />
      <Layout theme={LayoutTheme.PaleGrey} direction="column">
        <SurveillanceGrid
          id={"manager-transactions-surveillance-grid"}
          gridRef={transactionsGridRef}
          DefaultColDef={TransactionsDefaultColDef}
          ColumnDefs={TransactionsColumnDefs}
          data={transactionsData}
          onSelectionChanged={onSelectionChanged}
          overlayLoadingTemplate={""}
          overlayNoRowsTemplate={""}
          rowClassRules={getTransactionClassRules}
        />
      </Layout>
    </>
  );
  // ! This is for passing the Q4_ENTITY_ID inside the funds view, but it's possible we won't need this later
  const getMappedFundsData = () =>
    fundsData && manager ? fundsData.map((fund: Fund) => ({ ...fund, ...{ Q4_ENTITY_ID: manager.Q4_ENTITY_ID } })) : [];
  const renderFundsToolbarAndGrid = () => (
    <>
      <GridToolbar
        leftContent={"Funds"}
        rightContent={renderFundsToolbarContent()}
        gridRef={fundsGridRef}
        showDownloadDataButton={true}
      />
      <Layout theme={LayoutTheme.PaleGrey} direction="column">
        <SurveillanceGrid
          id={"manager-funds-surveillance-grid"}
          gridRef={fundsGridRef}
          DefaultColDef={FundsDefaultColDef}
          ColumnDefs={FundsColumnDefs}
          data={getMappedFundsData()}
          overlayLoadingTemplate={""}
          overlayNoRowsTemplate={""}
        />
      </Layout>
    </>
  );
  const renderAllGrids = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const viewingOnProd = config.env === "main" && queryParams.get("demo") == null;
    if (viewingOnProd) {
      return <div></div>;
    }
    if (currentlyLoading) {
      return <DevLoader interval={2500} />;
    }
    return (
      <Grid className={ManagerClassName.Grid}>
        <GridColumn className={ManagerClassName.Transactions} width="1-of-3">
          {renderTransactionsToolbarAndGrid()}
        </GridColumn>
        <GridColumn className={ManagerClassName.Funds} width="2-of-3">
          {renderFundsToolbarAndGrid()}
        </GridColumn>
      </Grid>
    );
  };
  const renderEditManagerModal = () => {
    return (
      <EditManagerModal
        editMode={true}
        q4EntityId={manager.Q4_ENTITY_ID}
        q4StockId={ticker.Q4_SEC_ID}
        showModal={showEditManagerModal}
        setShowModal={setShowEditManagerModal}
      />
    );
  };
  const handleManagerEditButtonClick = useCallback(() => {
    setShowEditManagerModal(true);
  }, [setShowEditManagerModal]);
  const renderAllModals = () => {
    return (
      <>
        {showAddTransactionsModal ? renderAddTransactionsModal() : false}
        {showAddFundsModal ? renderAddFundsModal() : false}
        {renderDeleteCustomTransactionsModal()}
        {showPortfolioModal ? renderSectorSummaryModal() : false}
        {showEditManagerModal ? renderEditManagerModal() : false}
      </>
    );
  };
  return (
    <Layout className={ManagerClassName.Base} padding={LayoutPadding.None} flex={false}>
      <EntityBanner
        loading={currentlyLoading}
        entityMetadata={managerMetadata}
        onEditEntity={handleManagerEditButtonClick}
        rows={6}
        custodians={managerCustodians}
        setShowSectorModal={setShowPortfolioModal}
      />
      {renderAllGrids()}
      {renderAllModals()}
    </Layout>
  );
};

export default memo(Manager);
