import type { BaseComponentProps } from "@q4/nimbus-ui";

export interface DownloadButtonProps extends BaseComponentProps {
  userContext?: Record<string, unknown>;
  disabled?: boolean;
  onClick: () => void;
}

export enum DownloadButtonClassName {
  Base = "download-button",
}
