import { ButtonTheme, Modal } from "@q4/nimbus-ui";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import React, { useEffect, useRef, useState } from "react";
import GridToolbar from "../../GridToolbar/GridToolbar";
import SurveillanceGrid from "../../SurveillanceGrid/SurveillanceGrid";
import {
  SurveillanceGridModalClassName,
  SurveillanceGridModalProps,
  SurveillanceGridModalId,
  SurveillanceGridModalLiteralId,
} from "./SurveillanceGridModal.definition";
import "./SurveillanceGridModal.scss";

function SurveillanceGridModal<DataType>(props: SurveillanceGridModalProps<DataType>): JSX.Element {
  const {
    customClassName = "",
    showModal,
    title,
    subtitle,
    defaultColDef,
    colDefs,
    setShowModal,
    getData,
    nextButtonCallback,
  } = props;
  const [data, setData] = useState<Array<DataType>>([]);
  const gridRef = useRef<AgGridReact>(null);
  const onClickClose = () => {
    setShowModal(false);
  };

  const onClickGoto = () => {
    setShowModal(false);
    if (nextButtonCallback) {
      nextButtonCallback();
    }
  };
  const getModalFooterActions = () => {
    const actions = [{ label: "Close", theme: ButtonTheme.DarkSlate, onClick: onClickClose }];
    if (nextButtonCallback) {
      actions.push({ label: "Details", theme: ButtonTheme.Citrus, onClick: onClickGoto });
    }
    return actions;
  };

  useEffect(() => {
    const fetchData = async () => {
      setData(await getData());
    };

    if (showModal) {
      fetchData();
    } else {
      setData([]);
    }
  }, [getData, showModal]);

  const headerComp = (
    <>
      <span>
        <h2>
          <b>{title}</b> - {subtitle}
        </h2>
      </span>
    </>
  );

  return (
    <Modal
      className={
        customClassName ? `${SurveillanceGridModalClassName.Base} ${customClassName}` : SurveillanceGridModalClassName.Base
      }
      title={<GridToolbar leftContent={headerComp} rightContent={null} gridRef={gridRef} showDownloadDataButton={true} />}
      id={SurveillanceGridModalId}
      visible={showModal}
      onCloseRequest={() => setShowModal(false)}
      footerActions={getModalFooterActions()}
    >
      <SurveillanceGrid
        data={data}
        gridRef={gridRef}
        id={SurveillanceGridModalLiteralId}
        DefaultColDef={defaultColDef}
        ColumnDefs={colDefs}
      />
    </Modal>
  );
}

export default SurveillanceGridModal;
