import "./fund.view.scss";
import { ButtonTheme, Grid, GridColumn, Layout, LayoutPadding, LayoutTheme, Modal } from "@q4/nimbus-ui";
import { RowNode, SelectionChangedEvent } from "ag-grid-community";
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import config from "../../config";
import { FundContext, TickerContext } from "../../contexts";
import AddFundsForm from "../manager/components/AddFundsForm/AddFundsForm";
import AddButton from "../root/components/Button/AddButton/AddButton";
import TrashButton from "../root/components/Button/TrashButton/TrashButton";
import EntityBanner from "../root/components/EntityBanner/EntityBanner";
import GridToolbar from "../root/components/GridToolbar/GridToolbar";
import AddTransactionsModal from "../root/components/Modal/AddTransactionsModal/AddTransactionsModal";
import SurveillanceGrid from "../root/components/SurveillanceGrid/SurveillanceGrid";
import DevLoader from "../root/components/devLoader/DevLoader";
import { DefaultColDef, TransactionsColumnDefs } from "./data/AgGridDefs";
import { FundClassName } from "./fund.definition";

const Fund = (): JSX.Element => {
  const { ticker } = useContext(TickerContext);
  const {
    fund,
    fundMetadata,
    fundMetadataLoading,
    fundTransactions,
    fundTransactionsLoading,
    selectedTransactions,
    setViewMounted,
    setSelectedTransactions,
    addTransaction,
    deleteTransactions,
  } = useContext(FundContext);
  const [showAddTransactionsModal, setShowAddTransactionsModal] = useState(false);
  const [showAddFundsModal, setShowAddFundsModal] = useState(false);
  const transactionsGridRef = useRef(null);
  const currentlyLoading = !ticker || fundTransactionsLoading || fundMetadataLoading;

  useEffect(() => {
    setViewMounted(true);
    return () => {
      setViewMounted(false);
    };
  }, [setViewMounted]);

  const getFundsModalFooterActions = () => [
    {
      label: "Cancel",
      theme: ButtonTheme.DarkSlate,
      onClick: () => console.log("Cancel add funds"),
    },
    {
      label: "Add",
      theme: ButtonTheme.Citrus,
      onClick: () => console.log("Confirm add funds"),
    },
  ];
  const renderAddTransactionsModal = () => {
    // * For generic navigation we will only have the Q4_ENTITY_ID available, otherwise we will have to rely on the Q4_FUND_ID (Preferred)
    return (
      <AddTransactionsModal
        q4EntityId={fund.Q4_FUND_ID ? fund.Q4_FUND_ID : fund.Q4_ENTITY_ID}
        q4SecurityId={ticker.Q4_SEC_ID}
        initialQuantity={fundTransactions && fundTransactions.length > 0 ? fundTransactions[0].SHARES : 0}
        showModal={showAddTransactionsModal}
        setShowModal={setShowAddTransactionsModal}
        addTransaction={addTransaction}
      />
    );
  };
  const renderAddFundsModal = () => {
    const title = "Add Fund";
    const subtitle = "";
    return (
      <Modal
        className={FundClassName.Modal}
        visible={showAddFundsModal}
        title={title}
        subtitle={subtitle}
        onCloseRequest={() => setShowAddFundsModal(false)}
        footerActions={getFundsModalFooterActions()}
      >
        <AddFundsForm showModal={showAddTransactionsModal} />
      </Modal>
    );
  };
  const renderTransactionsToolbarContent = () => {
    return (
      <div className={FundClassName.TransactionsToolbarContent}>
        <AddButton disabled={false} onClick={() => setShowAddTransactionsModal(true)} />
        <TrashButton disabled={!selectedTransactions.length} onClick={() => deleteTransactions()} />
      </div>
    );
  };
  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const nodes: Array<RowNode> = [];
    event.api.forEachNode((node: RowNode) => {
      if (node.isSelected()) {
        nodes.push(node);
      }
    });
    setSelectedTransactions(nodes);
  };
  const renderTransactionsToolbarAndGrid = () => (
    <>
      <GridToolbar
        leftContent="Transactions"
        rightContent={renderTransactionsToolbarContent()}
        gridRef={transactionsGridRef}
        showDownloadDataButton={true}
      />
      <Layout theme={LayoutTheme.PaleGrey} direction="column">
        <SurveillanceGrid
          id={"fund-transactions-surveillance-grid"}
          gridRef={transactionsGridRef}
          DefaultColDef={DefaultColDef}
          ColumnDefs={TransactionsColumnDefs}
          data={fundTransactions}
          onSelectionChanged={onSelectionChanged}
          overlayLoadingTemplate={""}
          overlayNoRowsTemplate={""}
        />
      </Layout>
    </>
  );
  const renderAllGrids = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const viewingOnProd = config.env === "main" && queryParams.get("demo") == null;
    if (viewingOnProd) {
      return <div></div>;
    }
    if (currentlyLoading) {
      return <DevLoader interval={2500} />;
    }
    return (
      <Grid className={FundClassName.Grid}>
        <GridColumn className={FundClassName.Transactions} width="1-of-1">
          {renderTransactionsToolbarAndGrid()}
        </GridColumn>
      </Grid>
    );
  };
  const renderAllModals = () => {
    return (
      <>
        {showAddTransactionsModal ? renderAddTransactionsModal() : false}
        {showAddFundsModal ? renderAddFundsModal() : false}
      </>
    );
  };
  return (
    <Layout className={FundClassName.Base} padding={LayoutPadding.None} flex={false}>
      <EntityBanner loading={currentlyLoading} entityMetadata={fundMetadata} />
      {renderAllGrids()}
      {renderAllModals()}
    </Layout>
  );
};

export default memo(Fund);
